import { collection, getDocs } from "@firebase/firestore";
import { db } from "../../../config/firebase";
import {
  cityList,
  gymList,
  gymAlerts,
} from "../../../components/Databases";

export const fetchAlerts = async (basicGymData) => {
  try {
    // Create a reference to the alerts collection
    const gymRef = collection(
      db,
      cityList,
      basicGymData.city,
      gymList,
      basicGymData.gymName,
      gymAlerts
    );

    // Fetch the documents
    const querySnapshot = await getDocs(gymRef);

    // Extract the alerts data
    const alerts = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    return alerts;
  } catch (error) {
    console.error("Error fetching alerts:", error);
    throw new Error("Failed to fetch alerts");
  }
};
