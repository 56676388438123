import React, { useEffect, useMemo, useState } from 'react';
import {
    BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, CartesianGrid,
} from 'recharts';
import 'tailwindcss/tailwind.css';
import { themeColors } from '../../../theme';
import { getGenderData } from './FetchMembersData';
import LoadingSpinner from '../../../components/LoadingSpinner';


// Data used for displaying the number of males and females based on age group, no longer needed, graph replaced by members with/without personal trainers
// const data = [
//     { ageGroup: '13-25y', males: 25, females: 30 },
//     { ageGroup: '26-35y', males: 35, females: 33 },
//     { ageGroup: '36-45y', males: 40, females: 45 },
//     { ageGroup: '46-55y', males: 30, females: 35 },
//     { ageGroup: '56-75y', males: 20, females: 15 },
// ];

const data = [
    { gender: 'Males', without: 21, with: 15 },
    { gender: 'Females', without: 15, with: 16 },
    { gender: 'Others', without: 1, with: 10 },
]

const CustomLegend = () => (
    <div className="flex flex-col sm:flex-row justify-end items-center p-2">
        <div className="flex items-center mb-2 sm:mb-0 sm:mr-4">
            <div className="w-5 h-5 rounded-full" style={{ backgroundColor: themeColors.white, border: `2px solid ${themeColors.fg}` }}></div>
            <span className="ml-2 text-sm text-secondary font-bold">Without PT</span>
        </div>
        <div className="flex items-center">
            <div className="w-5 h-5 rounded-full" style={{ backgroundColor: themeColors.fg }}></div>
            <span className="ml-2 text-sm text-secondary font-bold">With PT</span>
        </div>
    </div>
);

export const MembersAndPTGraph = ({ gymData }) => {
    const [genderData, setGenderData] = useState(null);
    const [loading   , setLoading   ] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data = await getGenderData(gymData);
            setGenderData(data);
            setLoading(false);
        }
        fetchData();
    }, [gymData]);


    return (
        <div className="flex flex-col pt-2 w-full lg:w-1/2 py-4 rounded-3xl border-secondary border-2 px-2 h-full min-w-52">
            <div className="flex flex-row justify-between">
                <p className="text-lg text-secondary font-bold text-left px-2">Members & Personal Trainers</p>
            </div>
            <div className="flex flex-col">
                {loading ? (
                    <div className='flex justify-center lg:mt-28'>
                        <LoadingSpinner />
                    </div>
                ) : (
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                            data={data}
                            margin={{ top: 20, bottom: 5, left: -30 }}
                            barGap={5}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="gender"
                                stroke={themeColors.secondary} // Set X-axis color to secondary
                                tick={{ fill: themeColors.secondary }} // Set X-axis tick color to secondary
                            />
                            <YAxis
                                stroke={themeColors.secondary} // Set Y-axis color to secondary
                                tick={{ fill: themeColors.secondary }} // Set Y-axis tick color to secondary
                            />
                            <Tooltip
                                contentStyle={{
                                    borderRadius: '5', // Tooltip border radius
                                }}
                                labelStyle={{ color: themeColors.fg }} // Tooltip label color
                                itemStyle={{ color: themeColors.fg }}  // Tooltip item text color
                            />
                            <Legend content={<CustomLegend />} verticalAlign="top" align="right" />
                            <Bar
                                dataKey="without"
                                fill={themeColors.white}
                                stroke={themeColors.fg}
                                strokeWidth={2}
                                radius={[10, 10, 0, 0]} // Apply rounded edges to the top of the bars
                            />
                            <Bar
                                dataKey="with"
                                fill={themeColors.fg}
                                radius={[10, 10, 0, 0]} // Apply rounded edges to the top of the bars
                            />
                        </BarChart>
                    </ResponsiveContainer>
                )}
            </div>
        </div>
    );
};
