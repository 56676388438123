import React from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

// Set the app element for accessibility
if (typeof window !== 'undefined') {
    Modal.setAppElement('#root'); // Assuming the root element is with id 'root'
}

/*
 * Displays a generic Modal
 * @param   :   modalisOpen, setModalIsOpen states
 *          :   a function to use upon 'okay' click
 * @return  :   void
 */
const SuccessModal = ({ modalIsOpen, setModalIsOpen }) => {
    const navigate = useNavigate();
    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            contentLabel="Form Submitted Successfully"
            className="fixed inset-0 flex items-center justify-center z-50 text-tertiary"
            overlayClassName="fixed inset-0"
        >
            <div className="border border-primary bg-secondary p-10 rounded shadow-xl text-center">
                <h2 className="text-2xl mb-4">Form Submitted Successfully</h2>
                <button
                    onClick={() => onClickSuccessModal(setModalIsOpen, navigate)}
                    className="mt-4 px-4 py-2 bg-tertiary text-black rounded hover:bg-gray-500"
                >
                    Okay
                </button>
            </div>
        </Modal>
    );
};

export default SuccessModal;

const onClickSuccessModal = (setModalIsOpen, navigate) => {
    
    setModalIsOpen(false);

    navigate('/');
}
