import React, { useState } from 'react';
import UserAttendanceModal from '../userAttendanceModal';

/**
 * Converts Time string to local Time
 * @param {String} stringTime - Date and Time in String (UTC)
 * @returns {String} Local time in HH:MM format
 */
const convertTimestampToTime = (stringTime) => {
    const date = new Date(stringTime);

    // Convert time to local time zone
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
}

/**
 * AttendanceTable component
 *
 * This component displays a table with a list of users' attendance data for a specific day.
 * It includes functionality to manually add attendance via a modal. The attendance data is
 * passed as props and displayed in a table format. There is also a button to trigger the
 * manual attendance modal.
 *
 * @param {Object} props - The component props
 * @param {Array} props.attendedUsers - An array of objects containing attendance data
 * @param {string} props.selectedDate - The selected date for filtering attendance data
 * @param {Object} props.gymData - The gym data passed to the modal for attendance marking
 * @param {Function} props.setRefreshKey - Function to trigger reloading of parent component
 */
const AttendanceTable = ({ attendedUsers, selectedDate, gymData, setRefreshKey }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    /**
     * Toggles the visibility of the manual attendance modal.
     */
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        /* just added some styles for Responsiveness */
        <div className="flex flex-col pt-2">
            <div className="flex flex-row justify-between items-center mb-2">
                <p className="text-sm md:text-lg text-secondary font-bold text-left pt-2 px-2">
                    Today's Attendance:
                </p>
                <button
                    onClick={toggleModal}
                    className="bg-secondary text-tertiary font-bold p-1 rounded-xl mt-2 py-2 px-4 text-sm lg:text-base"
                >
                    Manual Attendance
                </button>
            </div>

            <div className="flex flex-col pb-4 rounded-3xl border-secondary border-2 py-2 px-2 overflow-x-auto">
                <table className="w-full bg-tertiary lg:min-h-16">
                    <thead>
                        <tr>
                            <th className="py-2 px-2 sm:px-4 border-b-2 border-secondary text-center text-secondary text-sm sm:text-xl">
                                Name
                            </th>
                            {/* <th className="py-2 px-2 sm:px-4 border-b-2 border-secondary text-center text-secondary text-sm sm:text-xl">
                                Membership
                            </th> */}
                            {/* <th className="py-2 px-2 sm:px-4 border-b-2 border-secondary text-center text-secondary text-sm sm:text-xl">
                                Trainer
                            </th> */}
                            <th className="py-2 px-2 sm:px-4 border-b-2 border-secondary text-center text-secondary text-sm sm:text-xl">
                                Workout
                            </th>
                            <th className="py-2 px-2 sm:px-4 border-b-2 border-secondary text-center text-secondary text-sm sm:text-xl">
                                Entry Time
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {attendedUsers
                            .filter(user => user.inTime.split('T')[0] === selectedDate)
                            .map((user, index) => (
                                <tr key={index}>
                                    <td className="py-2 px-2 sm:px-4 border-b text-center text-sm font-semibold sm:text-base">
                                        {user.userName}
                                    </td>
                                    {/* <td className="py-2 px-2 sm:px-4 border-b text-center text-sm font-semibold sm:text-base">
                                        {user.membership || '-'}
                                    </td> */}
                                    {/* <td className="py-2 px-2 sm:px-4 border-b text-center text-sm font-semibold sm:text-base">
                                        {user.trainer || '-'}
                                    </td> */}
                                    <td className="py-2 px-2 sm:px-4 border-b text-center text-sm font-semibold sm:text-base">
                                        {user.workout || '-'}
                                    </td>
                                    <td className="py-2 px-2 sm:px-4 border-b text-center text-sm font-semibold sm:text-base">
                                        {convertTimestampToTime(user.inTime)}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>

            {/* Manual Attendance Modal */}
            {isModalOpen && (
                <UserAttendanceModal
                    isOpen={isModalOpen}
                    onClose={toggleModal}
                    gymData={gymData}
                    setRefreshKey={setRefreshKey}
                />
            )}
        </div>
    );
};

export default AttendanceTable;
