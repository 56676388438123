import React, { useEffect, useState } from "react";
import { fetchAlerts } from "../AlertHelpers/GymAlertHelper";

// Utility function to format time ago : Not Being Used now
const timeAgo = (timestamp) => {
  const now = new Date();
  const then = new Date(timestamp);
  const difference = now - then;

  const seconds = Math.floor(difference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days}d ago`;
  }
  if (hours > 0) {
    return `${hours}h ago`;
  }
  if (minutes > 0) {
    return `${minutes}m ago`;
  }
  return `${seconds}s ago`;
};

const Alert = ({ selectedDate, gymData }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [message, setMessage] = useState("");
  const [alerts, setAlerts] = useState([]);

  const handleExpandClick = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
    setMessage("");
  };

  useEffect(() => {
    const fetchAlertsData = async () => {
      try {
        const res = await fetchAlerts(gymData);

        // Sort alerts based on timestamp
        const sortedAlerts = res.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        setAlerts(sortedAlerts); 
      } catch (error) {
        console.error("Error fetching alerts:", error);
      }
    };
    fetchAlertsData();
  }, [gymData]); // Add gymData as a dependency to refetch when it changes

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };


  return (
    <div className="flex flex-col h-[550px]">
      <p className="text-lg sm:text-xl text-secondary font-bold text-left p-2">
        Alerts {alerts.length ? `(${alerts.length})` : ""}
      </p>
      <div
        className="flex-grow flex flex-col rounded-2xl border-secondary border-2 py-2 px-2 overflow-y-auto"
        // style={{ maxHeight: "300px" }}
      >
        {alerts.length === 0 ? (
          <div className="bg-secondary p-2 cursor-pointer rounded-xl">
            <p className="text-left text-sm sm:text-base font-semibold text-white">
              🎉 Welcome to TrainRex
            </p>
          </div>
        ) : (
          <div>
            {alerts.map((alert, index) => (
              <div key={index} className="my-2">
                <div
                  className={`bg-secondary p-2 cursor-pointer ${
                    expandedIndex === index
                      ? "expanded rounded-t-xl"
                      : "rounded-xl"
                  }`}
                  onClick={() => handleExpandClick(index)}
                >
                  <p className="text-left text-sm sm:text-base font-semibold text-white">
                    {alert.message}{" "}
                    {/* Assuming alert has a message property */}
                    {/* <span className="text-gray-300 text-xs">
                      {timeAgo(alert.timestamp)}
                    </span> */}
                  </p>
                </div>
                {expandedIndex === index && (
                  <div className="bg-secondary rounded-b-xl px-2 pt-2">
                    <textarea
                      className="w-full p-2 border rounded-xl border-secondary focus:border-secondary"
                      placeholder="Type your message..."
                      value={message}
                      onChange={handleMessageChange}
                    />
                    <button className="mt-2 bg-primary text-white py-1 mb-2 px-4 rounded w-full sm:w-auto">
                      Send Message
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Alert;
