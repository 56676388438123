import React from "react";
import StaffCalendar from "./staffCalendar";

/**
 * StaffDetail Component
 * 
 * Displays detailed information about a selected staff member, including their profile picture, 
 * name, joining day, email, and shift details. The component also includes a calendar for 
 * tracking the attendance of the selected staff member.
 * 
 * @param {Object} selectedStaff - The currently selected staff member.
 * @param {Object} gymData - The gym data, including the gym logo.
 */
export const StaffDetail = ({ selectedStaff, gymData }) => {
    console.log('selectedStaff : ', selectedStaff);
    console.log("gym data ::::", gymData)

    return (
        selectedStaff ? (
            <div className="flex flex-col w-full gap-6 lg:h-auto ">
                
                {/* Upper Section */}
                <div className="flex justify-between w-full flex-col md:flex-row">
                    
                    {/* Staff Profile and Name */}
                    <div className="flex flex-col items-center text-tertiary -mb-3">
                        <div className="flex flex-col items-center p-4">
                            {/* Profile Picture */}
                            <img
                                src={selectedStaff.image}
                                alt="Profile"
                                className="w-50 h-50 md:w-64 md:h-64 rounded-full overflow-hidden mb-3 border-3 border-secondary"
                            />
                            {/* Staff Name */}
                            <div>
                                <h2 className="text-2xl font-semibold px-8 py-2 bg-secondary text-center rounded-xl">
                                    {selectedStaff.staffName}
                                </h2>
                            </div>
                        </div>
                    </div>

                    <StaffCalendar selectedStaff={selectedStaff} gymData={gymData} />
                </div>

                {/* More Staff Details */}
                <div className="flex flex-col md:flex-row gap-0 md:gap-2 lg:gap-10 mb-0 lg:-mb-24">
                    {/* Joining Day */}
                    <div className="text-base lg:text-xl text-tertiary font-semibold px-4 py-2 bg-secondary mt-3 text-center rounded-xl w-full">
                        Joining Day:<br />{selectedStaff.joiningDate}  {/* Corrected to joiningDay */}
                    </div>
                    {/* Email */}
                    <div className="text-base lg:text-xl text-tertiary font-semibold px-4 py-2 bg-secondary mt-3 text-center rounded-xl w-full">
                        Email:<br />{selectedStaff.email}
                    </div>
                    {/* Shift */}
                    <div className="text-base lg:text-xl text-tertiary font-semibold px-4 py-2 bg-secondary mt-3 text-center rounded-xl w-full">
                        Shift:
                        {selectedStaff.shifts.map((shift, index) => (
                            <div key={index}>
                                {`In: ${shift.inTime}, Out: ${shift.outTime}`}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        ) : (
            // Message shown when no staff member is selected
            <div className="text-2xl flex justify-center w-full h-full items-center">
                Select a staff member to view their details
            </div>
        )
    );
};
