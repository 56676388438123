import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';
import { themeColors } from '../theme';

/**
 * renderCustomizedLabel - Renders the percentage label inside the ring segments.
 *
 * @param {Object} props - Properties object containing necessary parameters for label positioning and text.
 * @param {number} props.cx - X-coordinate of the center of the ring.
 * @param {number} props.cy - Y-coordinate of the center of the ring.
 * @param {number} props.midAngle - Midpoint angle of the segment.
 * @param {number} props.innerRadius - Inner radius of the ring.
 * @param {number} props.outerRadius - Outer radius of the ring.
 * @param {number} props.percent - Percentage value of the segment.
 * @param {number} props.index - Index of the segment in the data array.
 * @returns {JSX.Element} - A text element showing the percentage with a rounded border.
 */
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius; // Position label at the border line of the graph
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
        <g>
            <rect 
                x={x - 30} 
                y={y - 15} 
                width={60} 
                height={30} 
                rx={10} 
                ry={10} 
                // fill="#f6faea" 
                fill={themeColors.lightAccent}
                stroke="none" 
            /> {/* Rounded rectangle background */}
            <text 
                x={x} 
                y={y} 
                fill="#262525" 
                textAnchor="middle" 
                dominantBaseline="central" 
                fontSize={18} 
                fontWeight="bold"
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        </g>
    );
};


/**
 * CustomLegend - Renders a customized legend for the PieChart.
 *
 * @param {Object} props - Properties object containing legend data and layout.
 * @param {Array} props.payload - Array of legend items with color and value properties.
 * @param {string} props.legendLayout - Layout of the legend, either 'horizontal' or 'vertical'.
 * @returns {JSX.Element} - A customized legend displayed as a list.
 */
const CustomLegend = ({ payload, legendLayout }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <ul 
            style={{ 
                padding: 0, 
                margin: 0, 
                marginTop: isMobile ? 20 : legendLayout === 'vertical' ? -150 : 10,
                display: 'flex', 
                flexDirection: isMobile ? 'row' : legendLayout === 'vertical' ? 'column' : 'row',
                justifyContent: legendLayout === 'horizontal' ? 'center' : 'flex-start',
                // flexDirection: legendLayout === 'vertical' ? 'column' : 'row',
                gap: legendLayout === 'horizontal' ? '20px' : '10px', // Add gaps for horizontal layout
            }}
            className="flex items-start"
        >
            {payload.map((entry, index) => (
                <li 
                    key={`item-${index}`} 
                    style={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        flexDirection: 'row',
                        marginBottom: legendLayout === 'vertical' ? 4 : 0, // Adjust margin for vertical layout
                    }}
                >
                    <span 
                        style={{ 
                            display: 'inline-block', 
                            marginRight: 10, 
                            backgroundColor: entry.color, 
                            width: 10, 
                            height: 10, 
                            borderRadius: '50%' 
                        }} 
                    />
                    {entry.value}
                </li>
            ))}
        </ul>
    );
};

/**
 * CustomLabel Component - Renders the custom labels with lines connecting to the legend.
 */
const CustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const lineEndX = cx - outerRadius - 20;  // Fixed x coordinate for the line end
    const textOffset = 10;  // Space between line end and rectangle
    const rectWidth = 120;  // Adjusted rectangle width to avoid overlap
    const rectHeight = 25;  // Fixed rectangle height
    const rectX = lineEndX - rectWidth - textOffset;  // Position rectangle after the line ends
    const text = `${(percent * 100).toFixed(0)}% | ₹${payload.value}`;
    const textAnchor = 'middle';  // Center-align text within the rectangle

    return (
        <g>
            {/* Line connecting to the legend */}
            <line x1={x} y1={y} x2={lineEndX} y2={y} stroke="#666" />

            {/* Background rectangle with border */}
            <rect 
                x={rectX} 
                y={y - rectHeight / 2} 
                width={rectWidth} 
                height={rectHeight} 
                fill="#F5F5F5" 
                stroke="#000"  // Black border
                strokeWidth="2"  // Border width
                rx="5"  // Rounded corners, optional
            />
            
            {/* Center-aligned text inside the rectangle */}
            <text 
                x={rectX + rectWidth / 2} 
                y={y} 
                textAnchor={textAnchor} 
                dominantBaseline="central" 
                fill="#333"  // Text color
            >
                {text}
            </text>
        </g>
    );
};

const CustomLegendWithLines = ({ payload, legendLayout }) => {
    // Calculate maximum width needed for labels
    const maxLabelWidth = Math.max(...payload.map(entry => entry.value.length));

    return (
        <ul 
            style={{ 
                padding: 0, 
                margin: 0, 
                position: 'relative',
                marginTop: legendLayout === 'vertical' ? -250 : 10,
                display: 'flex', 
                justifyContent: 'flex-start',
                flexDirection: 'column',
                gap: '10px',
                listStyleType: 'none',
            }}
            className="flex items-start"
        >
            {payload.map((entry, index) => {
                const linePosition = (index + 1) * 20;
                return (
                    <li 
                        key={`item-${index}`} 
                        style={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            marginBottom: legendLayout === 'vertical' ? 4 : 0, 
                            position: 'absolute',
                            top: `${linePosition}px`, // Adjust based on the position
                            left: `calc(50% - ${maxLabelWidth * 5}px)`, // Adjust label position
                            width: '100%',
                        }}
                    >
                        <span 
                            style={{ 
                                display: 'inline-block', 
                                backgroundColor: entry.color, 
                                width: 10, 
                                height: 10, 
                                borderRadius: '50%',
                                marginRight: 10,
                            }} 
                        />
                        {entry.value}
                        <div 
                            style={{
                                position: 'absolute',
                                left: '-150px', // Adjust this based on your chart position
                                width: '150px',
                                height: '1px',
                                backgroundColor: '#000',
                            }}
                        />
                    </li>
                );
            })}
        </ul>
    );
};




/**
 * RingGraph Component - Renders a customizable ring (doughnut) graph with a legend.
 *
 * @param {Array} data - Array of data objects for the graph. Each object should have a `name` and `value` property.
 * @param {number} innerRadius - The inner radius of the ring, controlling the size of the hole in the middle.
 * @param {number} outerRadius - The outer radius of the ring, controlling the overall size of the graph.
 * @param {Array} colors - Array of color codes for each segment of the ring.
 * @param {string} strokeColor - Color code for the stroke/border of each segment.
 * @param {string} legendLayout - Layout of the legend, either 'horizontal' or 'vertical'.
 * @param {boolean} wantCenterLabel - Bool to check if Center Text is Needed or Not (Default value = false)
 * @param {boolean} withLines - Bool to check if The Lines Legend is required (Default value = false)
 * @returns {JSX.Element} - A responsive ring graph component with a customized legend.
 */
const RingGraph = ({ data, innerRadius, outerRadius, colors, strokeColor, strokeWidth, legendLayout, width, height, wantCenterLabel = false, withLines = false }) => {
    // Calculate total revenue
    const totalRevenue = data.reduce((sum, entry) => sum + entry.value, 0);

    const layout = legendLayout === 'vertical' ? 'vertical' : 'horizontal';

    if (totalRevenue === 0) {
        return (
            <div className="flex justify-center items-center w-full h-full">
                <p className="text-2xl text-primary font-bold">Not Enough Data</p>
            </div>
        );
    }

    return (
        <ResponsiveContainer width={width} height={height}>
            <PieChart>
                {withLines ? (
                    // Displays a Graph With Lines Legend
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={props => <CustomLabel {...props} />}
                        outerRadius={outerRadius}
                        innerRadius={innerRadius}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} stroke={strokeColor} strokeWidth={strokeWidth}/>
                        ))}
                    </Pie>
                ) : (
                    // Displays a Graph Without Lines Legend
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={outerRadius}
                        innerRadius={innerRadius}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} stroke={strokeColor} strokeWidth={strokeWidth}/>
                        ))}
                    </Pie>
                )}
                <Legend 
                    content={(props) => <CustomLegend {...props} legendLayout={layout} />} 
                />

                {/* Center Text */}
                {wantCenterLabel && (
                    <text 
                    x="50%"
                    y="50%"
                    textAnchor="middle" 
                    dominantBaseline="central"
                    fontSize={30}
                    fontWeight="bold"
                    fill="#262525"
                    >
                        {`₹ ${totalRevenue}`}
                    </text>
                )}
            </PieChart>
        </ResponsiveContainer>
    );
};

export default RingGraph;
