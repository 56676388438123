import React, { useState, useEffect } from 'react';
import { fetchPayments } from '../../../helperFunctions/DashboardScenesHelpers/GymPaymentReceiptsHelper';
import PaymentReceipts from '../../../helperFunctions/DashboardScenesHelpers/FinanceComponents/PaymentReceipts';
import EarnSpendGraph from '../../../helperFunctions/DashboardScenesHelpers/FinanceComponents/EarnSpendGraph';
import AddRevenue from '../../../helperFunctions/DashboardScenesHelpers/FinanceComponents/AddRevenue';
import AddExpense from '../../../helperFunctions/DashboardScenesHelpers/FinanceComponents/AddExpense';
import RevenueGraph from '../../../helperFunctions/DashboardScenesHelpers/FinanceComponents/RevenueGraph';
import FourButtons from '../../../helperFunctions/DashboardScenesHelpers/FinanceComponents/FourButtons';
import ExpensesGraph from '../../../helperFunctions/DashboardScenesHelpers/FinanceComponents/ExpensesGraph';
import MembershipDistributionGraph from '../../../helperFunctions/DashboardScenesHelpers/FinanceComponents/MembershipDistribution';
import ConvertedLeadsChart from '../../../helperFunctions/DashboardScenesHelpers/SalesPageHelpers/ConvertedLeadsChart';
import ExpenseReceipts from '../../../helperFunctions/DashboardScenesHelpers/FinanceComponents/ExpenseReceipts';
import SidePanel from '../../../components/SidePanel';

/**
 * Function to filter receipts by type.
 * 
 * @param {Array} receipts - Array of receipt objects.
 * @param {String} type - The type to filter by ('All', 'Gym Memberships', 'Classes').
 * @returns {Array} - Filtered receipts array.
 */
export const filterReceiptsByType = (receipts, type) => {
    if (type === 'All') return receipts;

    if (type === 'Other') {
        return receipts.filter(receipt => 
            receipt.orderType !== 'Gym Membership' && receipt.orderType !== 'Classes'
        );
    }

    let formattedType = '';
    switch(type) {
        case 'Gym Memberships':
            formattedType = 'Gym Membership';
            break;
        case 'Classes':
            formattedType = 'Class';
            break;
        default:
            formattedType = type;
    }

    return receipts.filter(receipt => receipt.orderType === formattedType);
};

/**
 * Finance component to manage gym finances.
 * Includes buttons for various finance actions and displays earnings vs spendings graph.
 * 
 * @param {Object} gymData - An object containing gym information.
 */
const Finance = ({ gymData }) => {
    const [spendAmount, setSpendAmount] = useState(10000);
    const [earnAmount, setEarnAmount] = useState(25000);
    const [viewHistory, setViewHistory] = useState(false);
    const [viewExpenseHistory, setViewExpenseHistory] = useState(false);
    const [showAddRevenue, setShowAddRevenue] = useState(false); // State for Add Revenue screen
    const [showAddExpense, setShowAddExpense] = useState(false); // State for Add Expense Screen

    const [isPanelExpanded, setIsPanelExpanded] = useState(false);

    const togglePanel = () => {
        setIsPanelExpanded(!isPanelExpanded);
    };
    /**
     * Toggles the visibility of the AddRevenue component.
     */
    const toggleAddRevenue = () => {
        setShowAddRevenue((prev) => !prev);
    };

    /**
     * Toggles the visibility of the AddExpense component.
     */
    const toggleAddExpense = () => {
        setShowAddExpense((prev) => !prev);
    };

    return (
        <div className="flex flex-row w-full pl-8 pr-4">
            <div className="w-full relative">
                <p className="text-left text-3xl font-bold text-secondary">Finance</p>
                {/* Conditionally render PaymentReceipts or AddRevenue based on state */}
                {viewHistory ? (           // for revenue history
                    <PaymentReceipts onBackClick={() => setViewHistory(false)} gymData={gymData}/>
                ) : viewExpenseHistory ? ( // for expense history  
                    <ExpenseReceipts onBackClick={() => setViewExpenseHistory(false)} gymData={gymData}/>
                ) : showAddRevenue ? (
                    <AddRevenue onBackClick={toggleAddRevenue} gymData={gymData}/> 
                ) : showAddExpense ? (
                    <AddExpense onBackClick={toggleAddExpense} gymData={gymData} />
                ) : (
                    <div className='flex flex-col space-y-4 pt-8' >
                        <div className="flex flex-col lg:flex-row justify-between space-y-8 lg:space-y-0 lg:gap-x-4">
                            <ExpensesGraph gymData={gymData} />
                            <RevenueGraph gymData={gymData}/>
                            <FourButtons toggleAddRevenue={toggleAddRevenue} toggleAddExpense={toggleAddExpense} setViewHistory={setViewHistory} setViewExpenseHistory={setViewExpenseHistory} gymData={gymData} />
                        </div>
                        <div className="flex flex-col lg:flex-row justify-between lg:gap-x-8">
                            <MembershipDistributionGraph gymData={gymData} />
                            <ConvertedLeadsChart gymData={gymData} />
                        </div>
                    </div>
                )}
            </div>
            
            <SidePanel isExpanded={isPanelExpanded} togglePanel={togglePanel} />
        </div>
    );
};

export default Finance;