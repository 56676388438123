import React, { useEffect, useState } from "react";


export const ClientButtons = ({toggleAddMember, setShowPtModal}) => {
    const [earnings, setEarnings] = useState();
    // To fetch the aernings through TrainRex Marketplace
    useEffect(() => {


    },[])
    return (
        <div className="grid grid-cols-1 gap-4 md:grid-cols-1 md:grid-rows-2 lg:w-96 ">
            <button
                className="square-button bg-primary text-tertiary w-full p-12 my-auto h-40"
                role="button"
                onClick={toggleAddMember}
            >
                <span className="block text-left">Add<br/>Member</span><span> &#9650;</span>
            </button>
            <button
                className="square-button bg-secondary text-primary w-full p-12 my-auto h-40 border-primary"
                role="button"
                onClick={() => setShowPtModal(true)}
            >
                <span className="block text-left">Add<br/>Personal Training<br/>Session</span><span> &#9660;</span>
            </button>
            {/* <button
                className="w-full p-5 my-auto h-40 border-4 border-secondary rounded-3xl cursor-default text-secondary text-xl pt-5 font-semibold"
                role="button"
                // onClick={() => console.log('Earned through TrainRex MarketPlace')}
                onClick={() => setShowPtModal(true)}
            >
                <span className="block text-center hover:scale-125 transition-all duration-500">Earned this{earnings} through<br/> <span className="font-bold">TrainRex MarketPlace</span></span>
            </button> */}
        </div>
    )
}
