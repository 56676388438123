import React from 'react';

/**
 * FourButtons - Renders a set of four square buttons with specified actions and styles.
 *
 * @param {function} toggleAddRevenue - Function to toggle the Add Revenue screen.
 * @param {function} toggleAddExpense - Function to toggle the Add Expense screen.
 * @param {function} setViewHistory - Function to set the view history state.
 * @param {function} setViewExpenseHistory - Function to set the view Expense history state.
 * @returns {JSX.Element} - A component with four styled buttons.
 */
const FourButtons = ({ toggleAddRevenue, toggleAddExpense, setViewHistory, setViewExpenseHistory }) => {
    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6 w-full max-w-md mx-auto">
            <button 
                className="square-button bg-white w-full p-8 sm:p-12 h-32 sm:h-40 text-left flex flex-col justify-center items-start" 
                role="button" 
                onClick={toggleAddExpense}
            >
                <span className="block">Add<br />Expense</span>
                <span className="hidden sm:block"> &#9664; Add <br/>Expense </span>
            </button>
            <button
                className="square-button bg-secondary text-tertiary w-full p-8 sm:p-12 h-32 sm:h-40 text-left flex flex-col justify-center items-start"
                role="button"
                onClick={toggleAddRevenue}
            >
                <span className="block">Add<br />Revenue</span>
                <span className="hidden sm:block"> &#9650; Add <br/>Revenue </span>
            </button>
            <button
                className="square-button bg-primary text-tertiary w-full p-8 sm:p-12 h-32 sm:h-40 text-left flex flex-col justify-center items-start"
                role="button"
                onClick={() => setViewHistory(true)}
            >
                <span className="block">View<br />Revenue<br />History</span>
                <span className="hidden sm:block"> &#9660; View <br/>Revenue <br/>History </span>
            </button>
            <button 
                className="square-button bg-bg text-secondary w-full p-8 sm:p-12 h-32 sm:h-40 text-left flex flex-col justify-center items-start" 
                role="button" 
                onClick={() => setViewExpenseHistory(true)}
            >
                <span className="block">View<br />Expense<br/>History</span>
                <span className="hidden sm:block"> &#9654; View <br/>Expense <br/>History </span>
            </button>
        </div>
    );
};

export default FourButtons;
