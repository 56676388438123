import React, { useState, useEffect } from "react";
import { getGymProfile, updateGymPricing, updateGymFacilities, updateGymEquipments } from "../../../helperFunctions/DashboardScenesHelpers/ProfileHelpers";
import { Pencil } from "lucide-react";
import { Check, Edit3, X } from "lucide-react";


const DisplayProfile = ({ gymID }) => {
    const [gymData, setGymData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [pricingData, setPricingData] = useState(null);
    const [isEditingFacilities, setIsEditingFacilities] = useState(false);
    const [isEditingEquipment, setIsEditingEquipment] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    console.log({
        gymData,
    })

    useEffect(() => {
        // Fetch gym profile data
        const fetchProfile = async () => {
            try {
                const data = await getGymProfile(gymID);
                setGymData(data);
                setPricingData(data?.prices);
            } catch (error) {
                console.error("Error fetching gym profile:", error);
            }
        };

        fetchProfile();
    }, [gymID]);

    // Helper function to format facility/equipment labels
    const formatLabel = (text) => text.replace(/([A-Z])/g, ' $1').trim();

    // Helper function to check if a value is defined
    const isDefined = (value) => {
        return value !== undefined && value !== null && value !== "";
    }

    // Handle changes to facility availability
    const handleFacilityChange = (facility) => {
        setGymData((prevData) => ({
            ...prevData,
            facilities: {
                ...prevData.facilities,
                [facility]: !prevData.facilities[facility], // Toggle availability
            },
        }));
    };

    // Handle changes to equipment availability
    const handleEquipmentChange = (equipment) => {
        setGymData((prevData) => ({
            ...prevData,
            equipmentList: {
                ...prevData.equipmentList,
                [equipment]: !prevData.equipmentList[equipment], // Toggle availability
            },
        }));
    };


    // Handle changes to pricing data
    const handlePriceChange = (plan, priceType, value) => {
        // Convert empty string to null, otherwise parse as float
        const numericValue = value === "" ? null : parseFloat(value);

        setPricingData((prevData) => ({
            ...prevData,
            [plan]: {
                ...prevData[plan],
                [priceType]: numericValue,
            },
        }));
    };


    // Save changes
    const saveChanges = async () => {
        setIsSaving(true);
        const res = await updateGymPricing(gymID, pricingData);
        const res2 = await updateGymFacilities(gymID, gymData.facilities);
        const res3 = await updateGymEquipments(gymID, gymData.equipmentList);

        console.log('res2', res2)

        console.log(pricingData)
        setGymData((prevData) => ({
            ...prevData,
            prices: pricingData,
        }));
        setIsEditing(false);
        setIsEditingFacilities(false); 
        setIsEditingEquipment(false);
        setIsSaving(false);
    };

    return (
        <div className="p-6">
            {gymData ? (
                <>
                    <div className="flex justify-between items-center pb-4">
                        <div className="text-3xl font-bold mb-4 text-primary">{gymData.gymDisplayName}</div>
                        <div className="flex space-x-4 mt-4">
                            {isEditing ? (
                                <button
                                    onClick={saveChanges}
                                    className="bg-primary text-white px-4 py-2 rounded-md border-primary border-2"
                                >
                                    {
                                        isSaving ? "Saving..." : "Save Changes"
                                    }
                                </button>
                            ) : (
                                <button
                                    onClick={() => setIsEditing(true)}
                                    className="border-primary border-2 text-primary px-4 py-2 rounded-md"
                                >
                                    Edit Profile
                                </button>
                            )}
                        </div>
                    </div>

                    <div className="flex justify-between">
                        {/* Basic Information */}
                        <div className="mb-6">
                            <h3 className="text-xl font-semibold text-primary">Basic Information</h3>
                            <p className="text-primary">
                                <strong>Owner:</strong> {isDefined(gymData.owner) ? gymData.owner : "N/A"} <br />
                                <strong>Contact:</strong> {isDefined(gymData.phoneNumber) ? gymData.phoneNumber : "N/A"} <br />
                                <strong>Email:</strong> {isDefined(gymData.email) ? gymData.email : "N/A"} <br />
                                <strong>Location:</strong> {gymData.address}, {gymData.locality}, {gymData.cityDisplayName}, {gymData.country}, {gymData.pinCode}
                            </p>
                        </div>

                        {/* Timings and Ratings */}
                        <div className="mb-6">
                            <h3 className="text-xl font-semibold text-primary">Timings & Ratings</h3>
                            <p className="text-primary">
                                <strong>Opening Hours:</strong> {gymData.openingTime} - {gymData.closingTime} <br />
                                <strong>Rating:</strong> {gymData.rating ? `${gymData.rating}/5` : "No ratings"} <br />
                                <strong>Reviews:</strong> {isDefined(gymData.NoOfReviews) ? gymData.NoOfReviews : 0} <br />
                            </p>
                        </div>
                    </div>

                    {/* Pricing Information */}
                    <div className="my-6 flex flex-col gap-4">
                        <div className="flex justify-between items-center">
                            <div className="text-xl font-semibold text-primary">Pricing</div>
                        </div>
                        <div className="overflow-x-auto">
                            <table className="min-w-full bg-white border border-gray-300">
                                <thead>
                                    <tr>
                                        <th className="py-2 px-4 border-b text-left font-semibold text-primary">Plan</th>
                                        <th className="py-2 px-4 border-b text-left font-semibold text-primary">Daily Price</th>
                                        <th className="py-2 px-4 border-b text-left font-semibold text-primary">Exclusive Daily Price</th>
                                        <th className="py-2 px-4 border-b text-left font-semibold text-primary">Monthly Price</th>
                                        <th className="py-2 px-4 border-b text-left font-semibold text-primary">Exclusive Monthly Price</th>
                                        <th className="py-2 px-4 border-b text-left font-semibold text-primary">Yearly Price</th>
                                        <th className="py-2 px-4 border-b text-left font-semibold text-primary">Exclusive Yearly Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {["single", "couple", "family"].map((plan) => (
                                        <tr key={plan}>
                                            <td className="py-2 px-4 border-b text-primary font-semibold capitalize">
                                                {plan}
                                            </td>
                                            {["dailyPrice", "exclusiveDailyPrice", "monthlyPrice", "exclusiveMonthlyPrice", "yearlyPrice", "exclusiveYearlyPrice"].map((priceType) => (
                                                <td key={priceType} className="py-2 px-4 border-b text-primary">
                                                    {isEditing ? (
                                                        <input
                                                            type="number"
                                                            value={pricingData[plan][priceType] || ""}
                                                            onChange={(e) => handlePriceChange(plan, priceType, e.target.value)}
                                                            className="border p-1 rounded text-primary w-full"
                                                        />
                                                    ) : (
                                                        pricingData[plan][priceType] || "N/A"
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>


                    {/* Facilities and Equipment */}
                    <div className="max-w-7xl mx-auto px-4 py-8">
                        <h2 className="text-3xl font-bold text-gray-900 mb-8">
                            Facilities & Equipment
                        </h2>

                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                            {/* Facilities Section */}
                            <div className="bg-white rounded-2xl p-6 shadow-lg border border-gray-100">
                                <div className="flex justify-between items-center mb-6">
                                    <h3 className="text-xl font-semibold text-gray-900">Facilities</h3>
                                    {
                                        isEditing && (
                                            <button
                                                onClick={() => setIsEditingFacilities(!isEditingFacilities)}
                                                className="inline-flex items-center space-x-2 text-sm font-medium text-primary hover:text-primary/80 transition-colors"
                                            >
                                                {isEditingFacilities ? (
                                                    <>
                                                        <X className="w-4 h-4" />
                                                        <span>Save</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Edit3 className="w-4 h-4" />
                                                        <span>Edit</span>
                                                    </>
                                                )}
                                            </button>
                                        )
                                    }
                                </div>

                                {isEditingFacilities ? (
                                    <div className="mt-6 space-y-2">
                                        {Object.keys(gymData.facilities).map((facility) => (
                                            <label key={facility} className="flex items-center space-x-3 p-2 hover:bg-gray-50 rounded-lg cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    checked={gymData.facilities[facility]}
                                                    onChange={() => handleFacilityChange(facility)}
                                                    className="w-4 h-4 text-primary border-gray-300 rounded focus:ring-primary"
                                                />
                                                <span className="text-gray-700">{formatLabel(facility)}</span>
                                            </label>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="grid grid-cols-1 gap-3">
                                        {Object.entries(gymData.facilities)
                                            .filter(([_, available]) => available)
                                            .map(([facility]) => (
                                                <div key={facility} className="group relative bg-white hover:bg-primary/5 rounded-xl p-4 transition-all duration-200 shadow-sm hover:shadow-md">
                                                    <div className="flex items-center space-x-3">
                                                        <div className="bg-primary/10 p-2 rounded-lg">
                                                            <Check className="w-4 h-4 text-primary" />
                                                        </div>
                                                        <span className="text-gray-700 font-medium">{formatLabel(facility)}</span>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                )}
                            </div>

                            {/* Equipment Section */}
                            <div className="bg-white rounded-2xl p-6 shadow-lg border border-gray-100">
                                <div className="flex justify-between items-center mb-6">
                                    <h3 className="text-xl font-semibold text-gray-900">Equipment</h3>
                                    {
                                        isEditing && (
                                            <button
                                                onClick={() => setIsEditingEquipment(!isEditingEquipment)}
                                                className="inline-flex items-center space-x-2 text-sm font-medium text-primary hover:text-primary/80 transition-colors"
                                            >
                                                {isEditingEquipment ? (
                                                    <>
                                                        <X className="w-4 h-4" />
                                                        <span>Save</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Edit3 className="w-4 h-4" />
                                                        <span>Edit</span>
                                                    </>
                                                )}
                                            </button>
                                        )
                                    }
                                </div>

                                {isEditingEquipment ? (
                                    <div className="mt-6 space-y-2">
                                        {Object.keys(gymData.equipmentList).map((equipment) => (
                                            <label key={equipment} className="flex items-center space-x-3 p-2 hover:bg-gray-50 rounded-lg cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    checked={gymData.equipmentList[equipment]}
                                                    onChange={() => handleEquipmentChange(equipment)}
                                                    className="w-4 h-4 text-primary border-gray-300 rounded focus:ring-primary"
                                                />
                                                <span className="text-gray-700">{formatLabel(equipment)}</span>
                                            </label>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="grid grid-cols-1 gap-3">
                                        {Object.entries(gymData.equipmentList)
                                            .filter(([_, available]) => available)
                                            .map(([equipment]) => (
                                                <div key={equipment} className="group relative bg-white hover:bg-primary/5 rounded-xl p-4 transition-all duration-200 shadow-sm hover:shadow-md">
                                                    <div className="flex items-center space-x-3">
                                                        <div className="bg-primary/10 p-2 rounded-lg">
                                                            <Check className="w-4 h-4 text-primary" />
                                                        </div>
                                                        <span className="text-gray-700 font-medium">{formatLabel(equipment)}</span>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>



                    {/* Classes Offered */}
                    <div className="mb-8">
                        <h3 className="text-2xl font-semibold text-primary mb-4">Classes Offered</h3>
                        <div className="space-y-4">
                            {Object.entries(gymData.classes)
                                .filter(([_, available]) => available) // Only show available classes
                                .map(([className, _]) => (
                                    <div key={className} className="bg-primary p-4 rounded-lg shadow-md">
                                        <span className="text-white text-lg">
                                            {className.replace(/([A-Z])/g, ' $1')}
                                        </span>
                                    </div>
                                ))}
                        </div>
                    </div>
                </>
            ) : (
                <p className="text-primary">Loading gym profile...</p>
            )}
        </div>
    );
};

export default DisplayProfile;
