import React, { useState } from 'react';
import { checkIfStaffExists, saveExpense } from './AddExpenseHelper';

/**
 * AddExpense component for adding expense details.
 * @param {Function} onBackClick - handles back button
 * @param {Object} gymData - has gymDetails
 * @returns {JSX.Element} - The rendered AddExpense component.
 */
const AddExpense = ({ onBackClick, gymData }) => {
    const [expenseType, setExpenseType] = useState('');
    const [staffEmail, setStaffEmail] = useState('');
    const [staffExists, setStaffExists] = useState(false);
    const [staffInfo, setStaffInfo] = useState({ staffName: '', position: '' });
    const [amount, setAmount] = useState('');
    const [details, setDetails] = useState('');
    const [salaryMonth, setSalaryMonth] = useState('');

    // List of months for Salary dropdown
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June', 
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    // Function to Reset the Form
    const resetForm = () => {
        setExpenseType('');
        setStaffEmail('');
        setStaffExists(false);
        setStaffInfo({ staffName: '', position: '' });
        setAmount('');
        setDetails('');
        setSalaryMonth('');
    };

    // Calls the checkIfStaffExists function
    const handleCheckStaff = async () => {
        const staffResult = await checkIfStaffExists(staffEmail, gymData);
        setStaffExists(staffResult.staffExists);
        if (staffResult.staffExists) {
            setStaffInfo(staffResult);
        } else {
            alert(`Staff (${staffEmail}) does not exist!`);
        }
    };

    // Handles the Submit button
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDetails = {
            ...gymData,
            expenseType,
            staffEmail,
            staffName: staffInfo.staffName,
            position: staffInfo.position,
            amount: amount * 100, // Store amount in Paise
            details,
            salaryMonth,
        };

        const isSuccess = await saveExpense(expenseType, formDetails);

        if (isSuccess) {
            resetForm();
            onBackClick();
        }
    };

    return (
        <div className="p-8">
            <div className="w-full flex justify-start mb-6">
                <button
                    onClick={onBackClick}
                    className="bg-secondary text-white py-2 px-4 rounded hover:bg-primary"
                >
                    &#8592; Back {/* just added the back arrow */}
                </button>
            </div>
            <div className="flex justify-center">
                <div className="w-full max-w-md text-left">
                    <h2 className="text-2xl mb-6 text-center">Add Expense</h2>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <label className="block mb-2">Expense Type</label>
                            <select
                                className="p-2 border rounded w-full"
                                value={expenseType}
                                onChange={(e) => setExpenseType(e.target.value)}
                                required
                            >
                                <option value="">Select Expense Type</option>
                                <option value="Salary">Salary</option>
                                <option value="Maintenance">Maintenance</option>
                                <option value="Miscellaneous">Miscellaneous</option>
                            </select>
                        </div>

                        {/* Conditional Rendering based on expenseType */}
                        {expenseType && (
                            <>
                                <div>
                                    <label className="block mb-2">Staff Email</label>
                                    <div className="flex">
                                        <input
                                            type="email"
                                            className="p-2 border rounded w-full"
                                            value={staffEmail}
                                            onChange={(e) => setStaffEmail(e.target.value)}
                                            required
                                        />
                                        <button
                                            type="button"
                                            onClick={handleCheckStaff}
                                            className="bg-secondary text-white ml-2 py-2 px-4 rounded hover:bg-primary"
                                        >
                                            Check
                                        </button>
                                    </div>
                                </div>

                                {staffExists && (
                                    <>
                                        <div>
                                            <label className="block mb-2">Staff Name</label>
                                            <input
                                                type="text"
                                                className="p-2 border rounded w-full"
                                                value={staffInfo.staffName}
                                                readOnly
                                            />
                                        </div>
                                        <div>
                                            <label className="block mb-2">Position</label>
                                            <input
                                                type="text"
                                                className="p-2 border rounded w-full"
                                                value={staffInfo.position}
                                                readOnly
                                            />
                                        </div>
                                    </>
                                )}

                                {/* Conditional Rendering for Salary Month */}
                                {expenseType === 'Salary' && (
                                    <div>
                                        <label className="block mb-2">Month of Salary</label>
                                        <select
                                            className="p-2 border rounded w-full"
                                            value={salaryMonth}
                                            onChange={(e) => setSalaryMonth(e.target.value)}
                                            required
                                        >
                                            <option value="">Select Month</option>
                                            {months.map((month, index) => (
                                                <option key={index} value={month}>{month}</option>
                                            ))}
                                        </select>
                                    </div>
                                )}

                                {expenseType !== 'Salary' && (
                                    <div>
                                        <label className="block mb-2">Details</label>
                                        <textarea
                                            className="p-2 border rounded w-full"
                                            value={details}
                                            onChange={(e) => setDetails(e.target.value)}
                                            required
                                        />
                                    </div>
                                )}

                                <div>
                                    <label className="block mb-2">Amount</label>
                                    <input
                                        type="number"
                                        className="p-2 border rounded w-full"
                                        value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="flex justify-between">
                                    <button
                                        type="submit"
                                        className="bg-secondary text-white py-2 px-4 rounded hover:bg-primary"
                                    >
                                        Submit
                                    </button>
                                    <button
                                        type="reset"
                                        className="bg-secondary text-white py-2 px-4 rounded hover:bg-primary"
                                        onClick={resetForm}
                                    >
                                        Reset
                                    </button>
                                </div>
                            </>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddExpense;
