import { collection, getDocs, query, where } from "@firebase/firestore";
import { db } from "../../../config/firebase";
import { cityList, gymList } from "../../../components/Databases";

export const staffList2 = [
    { id: 1, name: 'Arnav Sinha', level: 'Silver', employmentStatus: 'Active', joiningDay: '2022-01-15', picture: 'url-to-image', attendance: ['2024-09-01', '2024-09-02','2024-09-03'], email:'Arnav@gamil.com', shift: 'All Day', position: 'Frontend Engineer', salary: '70000', phone: '+44 874567890' },
    { id: 2, name: 'Yash Jain', level: 'Gold', employmentStatus: 'Inactive', joiningDay: '2021-12-01', picture: 'url-to-image', attendance: ['2024-09-02', '2024-09-03'], email:'Yash@gmail.com', shift: 'All Day', position: 'Backend Engineer', salary: '70000', phone: '+44 874567891' },
    // Add more staff members here...
];

/**
 * Fetches the list of staff
 * @param {Object} gymData  -   Information about the gym
 * @returns {Object}        -   Complete list of staff and their data
 */
export const fetchStaffList = async (gymData) => {
    let staffList = [];
    try {
        const staffRef      =   collection(db, cityList, gymData.city, gymList, gymData.gymName, 'Staff');

        const staffQuery    =   query(staffRef, where('position', '!=', 'Test'));

        // Fetching the staff documents from the collection
        const staffSnapshot = await getDocs(staffQuery);

        if (!staffSnapshot.empty) {
            // Loop through each document in the snapshot
            staffSnapshot.forEach(staffDoc => {
                const staffData = staffDoc.data();
                staffList.push(staffData); // Push staff data into the staffList array
            });
        } else {
            alert('No staff found. Please add Staff!');
        }

    } catch (error) {
        console.error('Error in Fetching Staff : ', error);
    }
    return staffList;

}

/**
 * Function to fetch attendance dates for a staff member
 * This function returns a set of dates in 'DD-MM-YYYY' format
 * 
 * @param {string} staffID          - The ID of the staff member
 * @param {Object} gymData          - Information about the gym
 * @param {String} checkThrough     - The medium/field medium to compare
 * @returns {Array}         - Array of attendance dates in 'DD-MM-YYYY' format
 */
export const fetchAttendanceOfStaff = async (staffID, gymData, checkThrough = 'email') => {
    const dates = [];
    try {
        const staffRef      =   collection(db, cityList, gymData.city, gymList, gymData.gymName, 'Staff');
        
        // Query staff based on the checkThrough field (e.g., email, phone number)
        const staffQuery    = query(staffRef, where(checkThrough, '==', staffID));
        
        // Fetch staff documents
        const staffSnapshot = await getDocs(staffQuery);

        // Check if staff exists
        if (staffSnapshot.empty) {
            alert('First Day of the User? No attendance data found.');
            return [];
        }

        // Else
        // Loop through staff documents to fetch attendance data
        for (const staffDoc of staffSnapshot.docs) {
            const attendanceRef = collection(staffDoc.ref, 'Attendance'); // Reference to the Attendance sub-collection
            const attendanceSnapshot = await getDocs(attendanceRef);

            // Extract attendance dates
            attendanceSnapshot.forEach((doc) => {
                const attendanceData = doc.data();
                const attendanceDate = attendanceData.dateInDDMMYYYY; 
                dates.push(attendanceDate);
            });
        }
        return dates;

    } catch (error) {
        console.log('Error fetching Staff Attendance Data : ', error);
        return [];
    }
};