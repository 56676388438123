import React, { useState } from 'react';
import { cloud_url } from '../../../assets/links';
import { handleSendPersonalizedMessage } from '../../../helperFunctions/basicHelper';

const BulkMessageSender = ({ users1 = [] }) => {
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('');
    const [number, setNumber] = useState(0);

    const handleSendSMS = () => handleSendPersonalizedMessage('sms', number, subject, message);
    const handleSendWhatsApp = () => handleSendPersonalizedMessage('whatsapp', number, subject, message);
    const handleSendEmail = () => handleSendPersonalizedMessage('email', number, subject, message);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-200">
            <div className="bg-tertiary w-full max-w-lg rounded-lg p-8 shadow-lg">
                <h2 className="text-2xl font-semibold text-primary mb-6">Send Bulk Messages</h2>
                <div className="space-y-4">
                    {/* {key === 'Email' && ( */}
                        <textarea 
                            rows="1" 
                            placeholder="Subject" 
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            className="w-full p-2 border rounded"
                        />

                        <textarea 
                            rows="1" 
                            placeholder="Number" 
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                            className="w-full p-2 border rounded"
                        />
                    {/* // )} */}
                    <textarea 
                        rows="4" 
                        placeholder="Enter message" 
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="w-full p-2 border rounded"
                    />
                </div>

                <div className="mt-6 space-x-4">
                    {/* {key === 'Phone' && (
                        <> */}
                            <button type="button" onClick={handleSendSMS} className="bg-secondary text-tertiary py-2 px-4 rounded-2xl">
                                Send SMS
                            </button>
                            <button type="button" onClick={handleSendWhatsApp} className="bg-secondary text-white py-2 px-4 rounded-2xl">
                                Send WhatsApp
                            </button>
                        {/* </>
                    )} */}
                    {/* {key === 'Email' && ( */}
                        <button type="button" onClick={handleSendEmail} className="bg-secondary text-tertiary py-2 px-4 rounded-2xl">
                            Send Email
                        </button>
                    {/* )} */}
                    <button type="button" onClick={() => window.history.back()} className="ml-4 bg-secondary text-white py-2 px-4 rounded-2xl">
                        Back
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BulkMessageSender;
