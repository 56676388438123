import React, { useState, useEffect } from 'react';
import { fetchAttendanceOfStaff } from './staffPageHelpers';

/**
 * StaffCalendar Component
 * 
 * Renders a calendar for the selected month and year.
 * Highlights the dates when the staff member was present.
 * 
 * @param {Object} selectedStaff    - The currently selected staff member.
 * @param {Object} gymData          - Information of the gym
 */
const StaffCalendar = ({ selectedStaff, gymData }) => {
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [attendanceDates, setAttendanceDates] = useState([]);

    // Fetch attendance dates when selected staff changes
    useEffect(() => {
        if (selectedStaff) {
            const fetchAttendance = async () => {
                const attendance = await fetchAttendanceOfStaff(selectedStaff.email, gymData); 
                setAttendanceDates(attendance || []); // Ensure attendanceDates is always an array
            };
            fetchAttendance(); // Call the async function
        }
    }, [selectedStaff, gymData]);

    /**
     * Check if a date is present in the attendance dates
     * @param {Date} date - Date object to check
     * @returns {boolean} - True if the date is in attendanceDates, false otherwise
     */
    const isPresentOnDate = (date) => {
        const dateStr = date.getDate().toString().padStart(2, '0') + '-' +
                        (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
                        date.getFullYear();
        return attendanceDates.includes(dateStr);
    };

    /**
     * Generate the dates for the current month.
     * @returns {Array} - List of Date objects for the current month.
     */
    const generateCalendarDates = () => {
        const dates = [];
        const firstDay = new Date(currentYear, currentMonth, 1);
        const lastDay = new Date(currentYear, currentMonth + 1, 0);

        // Fill initial empty dates
        for (let i = 0; i < firstDay.getDay(); i++) {
            dates.push(null);
        }

        // Fill dates of the month
        for (let i = 1; i <= lastDay.getDate(); i++) {
            dates.push(new Date(currentYear, currentMonth, i));
        }

        return dates;
    };

    /**
     * Handle changing to the previous month.
     */
    const handlePrevMonth = () => {
        if (currentMonth === 0) {
            setCurrentMonth(11);
            setCurrentYear(currentYear - 1);
        } else {
            setCurrentMonth(currentMonth - 1);
        }
    };

    /**
     * Handle changing to the next month.
     */
    const handleNextMonth = () => {
        if (currentMonth === 11) {
            setCurrentMonth(0);
            setCurrentYear(currentYear + 1);
        } else {
            setCurrentMonth(currentMonth + 1);
        }
    };

    const calendarDates = generateCalendarDates();

    return (
        <div className="w-full md:w-1/2 lg:w-2/3 h-full rounded-lg shadow-md p-4 border border-tertiary ml-0 md:ml-4">
            {/* Calendar Navigation */}
            <div className="flex justify-between items-center mb-2">
                <button
                    onClick={handlePrevMonth}
                    className="px-2 py-1 bg-secondary text-white rounded-md"
                >
                    &lt;
                </button>
                <h3 className="text-lg font-semibold">
                    {new Date(currentYear, currentMonth).toLocaleString('default', { month: 'long' })} {currentYear}
                </h3>
                <button
                    onClick={handleNextMonth}
                    className="px-2 py-1 bg-secondary text-white rounded-md"
                >
                    &gt;
                </button>
            </div>

            {/* Calendar Grid */}
            <div className="grid grid-cols-7 gap-1">
                {/* Days of the Week */}
                {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
                    <div key={day} className="text-center font-semibold text-xs">{day}</div>
                ))}

                {/* Calendar Dates */}
                {calendarDates.map((date, index) => (
                    <div
                        key={index}
                        className={`h-10 border p-1 text-xs ${date ? 'cursor-pointer hover:bg-secondary hover:text-white' : ''} text-center pt-3 ${date && isPresentOnDate(date) ? 'bg-secondary text-white' : ''}`}
                    >
                        {date && date.getDate()}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StaffCalendar;
