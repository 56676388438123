import React, { useState, useEffect } from "react";
import { db } from "../../../config/firebase";
import { collection , getDocs, onSnapshot } from "@firebase/firestore";
import AttendanceTable from "../../../helperFunctions/DashboardScenesHelpers/MainDisplayPageHelpers/AttendanceTable";
import Calendar from "../../../helperFunctions/DashboardScenesHelpers/MainDisplayPageHelpers/Calendar";
import ClassesToday from "../../../helperFunctions/DashboardScenesHelpers/MainDisplayPageHelpers/ClassesToday";
import Alert from "../../../helperFunctions/DashboardScenesHelpers/MainDisplayPageHelpers/Alert";
import { GenderGraph } from "../../../helperFunctions/DashboardScenesHelpers/MemberManagementComponents/GenderGraph";
import { cityList, gymList } from "../../../components/Databases";
import { convertDateToDDMMYYYY } from "../../../helperFunctions/basicHelper";
import OverAllAttendance from "../../../helperFunctions/DashboardScenesHelpers/MainDisplayPageHelpers/OverallAttendance";
import { getTotalMonthsAttendance } from "../../../helperFunctions/DashboardScenesHelpers/MemberManagementComponents/FetchMembersData";


/**
 * Fetches the attendance data and returns the attended users list.
 * @param {Object} gymData - Contains the gym details.
 * @param {String} selectedDate - The date for which the attendance is to be displayed.
 * @returns {Promise<Array>} - A promise that resolves to an array of attended users.
 */
export const fetchAttendance = async (gymData, selectedDate) => {
    try {
        const attendanceRef = collection(db, cityList, gymData.city, gymList, gymData.gymName, 'Calendar', selectedDate, 'Attendance');

        // Fetch the snapshot once and extract the data
        const snapshot = await getDocs(attendanceRef);
        const attendedUserList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        
        // Return the attended users list
        return attendedUserList;
    } catch (error) {
        console.log('Error in fetching attendance : ', error);
    }
}


/**
 * Renders the Overview Page of the Dashboard.
 * @param {Object} gymData - Contains the gym information.
 * @returns {JSX.Element} The Overview Page component.
 */
const OverviewPage = ({ gymData, activeTab }) => {

    const [attendedUsers, setAttendedUsers] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const users = await fetchAttendance(gymData, convertDateToDDMMYYYY(selectedDate));
            setAttendedUsers(users);
        };

        fetchData();
    }, [gymData, selectedDate]);

    return (
        <div className="flex flex-col w-full px-4 sm:px-8 pb-4">
            <div className="flex flex-col sm:flex-row justify-between pb-8">
                <p className="text-left text-3xl font-bold text-secondary">Overview</p>
            </div>
            {gymData ? (
                <div className="flex flex-col lg:flex-row justify-between lg:gap-y-0 gap-x-8 lg:max-w-[80vw] ">
                    <div className='flex flex-col w-full'>
                        <div className='flex flex-col lg:flex-row justify-between gap-8'>
                            <GenderGraph gymData={gymData} legendLayout="horizontal" />
                            <OverAllAttendance gymData={gymData} activeTab={activeTab}/>
                        </div>
                        <div className="grid grid-cols-1">
                            <AttendanceTable 
                                attendedUsers={attendedUsers} 
                                selectedDate={selectedDate} 
                                gymData={gymData} 
                                setRefreshKey={setRefreshKey} 
                            />
                            <ClassesToday gymData={gymData} selectedDate={convertDateToDDMMYYYY(selectedDate)}/>
                        </div>
                    </div>
                    <div className="flex flex-col lg:w-[450px] pt-4 lg:pt-0">
                        <Calendar 
                            selectedDate={selectedDate} 
                            setSelectedDate={setSelectedDate} 
                        />
                        <Alert selectedDate={selectedDate} gymData={gymData}/>
                    </div>
                </div>
            ) : (
                <p className="text-primary">Loading gym profile...</p>
            )}
        </div>
    );
};

export default OverviewPage;
