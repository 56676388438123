import React, { useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import { ManuallyMarkMemberPresent } from './UserAttendanceDataHelper';

/**
 * UserAttendanceModal component
 * 
 * This component displays a modal for manually marking user attendance. It includes
 * a form where the user can select a type (e.g., phone number, email), enter the 
 * corresponding information, and select one or more workouts. If 'other' is selected
 * in the workout dropdown, a custom workout input field is shown.
 * 
 * @param {Object} props - The component props
 * @param {boolean} props.isOpen - Whether the modal is open or not
 * @param {Function} props.onClose - Function to close the modal
 * @param {Object} props.gymData - The gym data to be passed when marking attendance
 * @param {Function} props.setRefreshKey - helps reloading the Parent Component
 */
const UserAttendanceModal = ({ isOpen, onClose, gymData, setRefreshKey }) => {
    const [selectedType, setSelectedType] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [countryCode, setCountryCode] = useState('+91');
    const [userWorkouts, setUserWorkouts] = useState([]);
    const [customWorkout, setCustomWorkout] = useState('');

    const workoutOptions = [
        { value: 'Leg', label: 'Leg' },
        { value: 'Chest', label: 'Chest' },
        { value: 'Abs', label: 'Abs' },
        { value: 'Cardio', label: 'Cardio' },
        { value: 'Back', label: 'Back' },
        { value: 'Shoulder', label: 'Shoulder' },
        { value: 'Other', label: 'Other' }
    ];

    /**
     * Handles the change of the dropdown type selection.
     * Resets input fields and workouts selection when type changes.
     * 
     * @param {Event} e - The change event.
     */
    const handleTypeChange = (e) => {
        setSelectedType(e.target.value);
        setInputValue('');
        setUserWorkouts([]);
        setCustomWorkout('');
    };

    /**
     * Handles the change of the input field value.
     * 
     * @param {Event} e - The change event.
     */
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    /**
     * Handles the change of the workout type selection.
     * Updates the state with the selected workouts.
     * 
     * @param {Array} selectedOptions - The selected options.
     */
    const handleWorkoutChange = (selectedOptions) => {
        setUserWorkouts(selectedOptions);
        if (!selectedOptions.some(option => option.value === 'other')) {
            setCustomWorkout('');
        }
    };

    /**
     * Handles the change of the custom workout input value.
     * 
     * @param {Event} e - The change event.
     */
    const handleCustomWorkoutChange = (e) => {
        setCustomWorkout(e.target.value);
    };

    /**
     * Handles the change of the country code dropdown selection.
     * 
     * @param {Event} e - The change event.
     */
    const handleCountryCodeChange = (e) => {
        setCountryCode(e.target.value);
    };

    /**
     * Handles the form submission.
     * Gathers user information and marks attendance.
     * Resets the form and closes the modal after submission.
     * 
     * @param {Event} e - The submit event.
     */
    const handleFormSubmit = (e) => {
        e.preventDefault();
        const userInfo = {
            typeOfMatch: selectedType,
            userCred: selectedType === 'phoneNumber' ? `${countryCode}${inputValue}` : inputValue,
            userWorkouts: userWorkouts.map(workout => workout.value === 'other' ? customWorkout : workout.value),
        };
        // If Marking Attendance is successful
        // Refresh he ClientsManagement
        if (ManuallyMarkMemberPresent(gymData, userInfo))
            setRefreshKey((prevKey) => prevKey + 1);

        onClose(); // Close the modal after form submission
        setSelectedType('');
        setInputValue('');
        setCountryCode('+91');
        setUserWorkouts([]);
        setCustomWorkout('');
    };

    const customStyles = {
        control: (base) => ({
            ...base,
            borderColor: 'gray',
            '&:hover': {
                borderColor: 'gray',
            },
            boxShadow: 'none',
        }),
        multiValue: (base) => ({
            ...base,
            backgroundColor: '#A8DADC', // Secondary color
        }),
        multiValueLabel: (base) => ({
            ...base,
            color: '#1D3557', // Primary color
        }),
        multiValueRemove: (base) => ({
            ...base,
            color: '#1D3557', // Primary color
            ':hover': {
                backgroundColor: '#1D3557', // Primary color
                color: 'white',
            },
        }),
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="User Attendance Modal"
            className="bg-secondary p-6 rounded-md shadow-lg max-w-md mx-auto mt-24 md:mt-56 text-black relative"
            overlayClassName="fixed top-0 left-0 w-full h-full z-50 bg-black bg-opacity-50"
        >
            <div className="flex justify-end -mt-4">
                <button onClick={onClose} className="text-tertiary hover:text-primary transition text-4xl">&times;</button>
            </div>
            <h2 className="text-xl font-bold mb-4 text-primary text-center">Mark Attendance Manually</h2>
            <form onSubmit={handleFormSubmit}>
                <div className="mb-4">
                    <label className="block text-primary mb-2">Type</label>
                    <select
                        value={selectedType}
                        onChange={handleTypeChange}
                        className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-primary focus:border-primary"
                    >
                        <option value="">Select Type</option>
                        <option value="membershipID">Membership ID</option>
                        <option value="phoneNumber">Phone Number</option>
                        <option value="email">Email</option>
                        <option value="userID">User ID</option>
                    </select>
                </div>
                {selectedType && (
                    <div className="mb-4">
                        <label className="block text-primary mb-2">{selectedType.charAt(0).toUpperCase() + selectedType.slice(1)}</label>
                        {selectedType === 'phoneNumber' && (
                            <div className="flex">
                                <select
                                    value={countryCode}
                                    onChange={handleCountryCodeChange}
                                    className="mr-2 px-3 py-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-primary focus:border-primary"
                                >
                                    <option value="+91">+91</option>
                                    <option value="+1">+1</option>
                                    <option value="+44">+44</option>
                                    <option value="+61">+61</option>
                                </select>
                                <input
                                    type="text"
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    className="flex-1 px-3 py-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-primary focus:border-primary"
                                />
                            </div>
                        )}
                        {selectedType !== 'phoneNumber' && (
                            <input
                                type="text"
                                value={inputValue}
                                onChange={handleInputChange}
                                className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-primary focus:border-primary"
                            />
                        )}
                    </div>
                )}
                {selectedType && (
                    <div className="mb-4">
                        <label className="block text-primary mb-2">Workout</label>
                        <Select
                            isMulti
                            options={workoutOptions}
                            value={userWorkouts}
                            onChange={handleWorkoutChange}
                            styles={customStyles}
                            placeholder="Select Workout(s)"
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                        {userWorkouts.some(workout => workout.value === 'other') && (
                            <input
                                type="text"
                                value={customWorkout}
                                onChange={handleCustomWorkoutChange}
                                className="mt-2 w-full px-3 py-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-primary focus:border-primary"
                                placeholder="Enter custom workout"
                            />
                        )}
                    </div>
                )}
                <div className="flex justify-center">
                    <button
                        type="submit"
                        className="px-4 py-2 bg-tertiary text-primary rounded-md hover:bg-primary hover:text-tertiary"
                    >
                        Submit
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default UserAttendanceModal;
