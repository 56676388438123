import React, { useState, useEffect } from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { themeColors } from '../../../theme';
import { getTotalMonthsAttendance } from '../MemberManagementComponents/FetchMembersData';
import LoadingSpinner from '../../../components/LoadingSpinner';

/**
 * OverAllAttendance Component
 * 
 * This component displays an area chart representing the attendance data 
 * from the 1st of the current month to the current date. The chart automatically
 * adjusts its dimensions based on the screen size and the active tab.
 * 
 * @param {Object} gymData - Contains the gym information necessary to fetch attendance data.
 * @param {string} activeTab - Indicates the currently active tab, used to adjust chart dimensions.
 */
const OverAllAttendance = ({ gymData, activeTab }) => {
    const [chartDimensions, setChartDimensions] = useState({ width: 450, height: 250 });
    const [dailyAttendance, setDailyAttendance] = useState([]);
    const [loading, setLoading] = useState(true);

    /**
     * Fetches attendance data and updates the dailyAttendance state.
     * The data is fetched based on the current month and provided gymData.
     */
    useEffect(() => {
        const fetchAttendanceData = async () => {
            setLoading(true);
            const data = await getTotalMonthsAttendance(gymData);
            setDailyAttendance(data);
            setLoading(false);
        };

        fetchAttendanceData();

        /**
         * Handles resizing of the chart based on the window dimensions.
         * Adjusts the chart width and height according to the screen size and active tab.
         */
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;

            let width = (screenWidth * 450) / 1920;
            let height = (screenHeight * 250) / 1080;

            if (screenWidth < 640) { // Mobile devices
                width *= 2.8;
                height *= 1.5;
            } else if (screenWidth < 1024) { // Tablets
                width *= 3.8;
                height *= 1.75;
            } else { // Desktops
                width *= activeTab === 'overview' ? 1.5 : 2.1;
            }

            setChartDimensions({ width, height });
        };

        handleResize(); // Set initial dimensions based on screen size
        window.addEventListener('resize', handleResize); // Listen for window resize events

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [activeTab]);



    return (
        <div className="flex flex-col rounded-3xl border-secondary border-2 p-4 h-full min-h-44 md:min-h-56 w-full bg-tertiary">
            <p className="text-xl text-secondary font-bold text-left mb-2">Attendance:</p>
            <div className="w-full h-full flex items-center justify-center">
                {loading ? (
                        <LoadingSpinner/>
                    ) : (
                        <ResponsiveContainer width={chartDimensions.width} height={chartDimensions.height}>
                            <AreaChart data={dailyAttendance}>
                                <defs>
                                    <linearGradient id="gradientFill" x1="0" x2="0" y1="0" y2="1">
                                        <stop offset="0%" stopColor={themeColors.primary} stopOpacity={0.5} />
                                        <stop offset="100%" stopColor={themeColors.fg} stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis 
                                    dataKey="date" 
                                    tick={{ fontSize: 12, fontWeight: 'bold', fill: themeColors.fg }} 
                                />
                                <YAxis 
                                    tick={{ fontSize: 12, fontWeight: 'bold', fill: themeColors.fg }} 
                                />
                                <Tooltip />
                                <Area
                                    type="monotone"
                                    dataKey="totalUsers"
                                    stroke={themeColors.bg}
                                    fill={`url(#gradientFill)`}
                                    strokeWidth={2}
                                    dot={false} // Remove dots on data points
                                />
                            </AreaChart>
                        </ResponsiveContainer>
                    )}
            </div>
        </div>
    );
};

export default OverAllAttendance;
