import { addDoc, collection, getDoc, getDocs, query, setDoc, updateDoc, doc, limit, where } from "@firebase/firestore";
import { db } from "../../config/firebase";
import { cityList, gymList, userList } from "../../components/Databases";
import { getCurrentMonth, getCurrentYear, getDate, getTime } from "../basicHelper";
import { getRandomDate } from "./DashboardHelper";

/**
 * Formats a timestamp to a readable time in 24-hour clock format.
 * @param {string} timestamp - The timestamp to format.
 * @returns {string} - The formatted time.
 */
export const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
};

/**
 * Fetches user attendance data from Firestore for the given gym and date.
 * @param {Object} gymData - Object containing gym details (city and gymName).
 * @param {String} date - The selected date in DD-MM-YYYY format.
 * @returns {Array} - The user attendance data with local time.
 */
export const getUserAttendanceData = async (gymData, date) => {
    try {

        // Reference to the attendance collection for the specific gym and date
        const attendanceRef = collection(db, cityList, gymData.city, gymList, gymData.gymName, 'Calendar', date, 'Attendance');

        // Query documents where inTime exists
        const q = query(attendanceRef);

        // Fetch documents from Firestore
        const querySnapshot = await getDocs(q);

        // Format the data
        const attendanceData = [];
        querySnapshot.forEach((doc) => {
            attendanceData.push(doc.data());
        });

        return attendanceData;
    } catch (error) {
        console.error('Error fetching attendance data:', error);
        return []; // Return an empty array or handle error as needed
    }
};

/**
 * Sorts the attendance data based on the given key and direction.
 * @param {Array} data - The attendance data to sort.
 * @param {string} key - The key to sort by.
 * @param {string} direction - The sort direction ('ascending' or 'descending').
 * @returns {Array} - The sorted attendance data.
 */
export const sortData = (data, key, direction) => {
    return data.sort((a, b) => {
    if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
    if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
    return 0;
    });
};

/**
 * This function is used to manually mark attendance of a user
 * @param {dict} gymData
 * @param {dict} userInfo   :   userCred
 *                          :   typeOfMatch
 *                          :   userWorkouts
 * @returns {boolean}
 */
export const ManuallyMarkMemberPresent = async (gymData, userInfo) => {
    const todaysDate = getDate()
    try {
        const userDoc = await checkIfUserExists({
            city        :   gymData.city,
            gymName     :   gymData.gymName,
            typeOfMatch :   userInfo.typeOfMatch,
            userCred    :   userInfo.userCred,
        })
        // First check if the user exists in the gym's database
        if (!userDoc.status) {
            return false; // User doesnt exist
        }

        // Add attendance in user's database
        await storeUserAttendanceInUserCalendar(userInfo);

        // As user exists, mark them present
        /*
         * Database Flow :
         * cityList -> {City} -> gymList -> {Gym Name} -> Calendar -> {Date} -> Attendance -> 
         * Document :
         * userName, userID, InTime
         */
        const gymBasedAttendanceRef = collection(db, cityList, gymData.city, gymList, gymData.gymName, 'Calendar', todaysDate, 'Attendance');

        await addDoc(gymBasedAttendanceRef, {
            'userName'  :   userDoc.userData.userName,
            'userID'    :   userDoc.userData.userID || '',
            'inTime'    :   getTime(),
            'outTime'   :   new Date(Date.now() + 90 * 60000).toISOString(),
            'workout'   :   userInfo.userWorkouts || [],
        } );

        // Update the total number of attendance
        const gymRef = doc(collection(db, cityList, gymData.city, gymList, gymData.gymName, 'Calendar'), todaysDate);
        const gymDoc = await getDoc(gymRef);

        if (gymDoc.exists()) {
            const gymData = gymDoc.data();
            const totalUsersAttendance = gymData.totalUsersAttendance || 0;

            await updateDoc(gymRef, {
                'totalUsersAttendance': totalUsersAttendance + 1
            });
        } else {
            // Document does not exist, create it with totalUsersAttendance set to 1
            // console.log('Document does not exist. Creating with totalUsersAttendance: 1');
            await setDoc(gymRef, {
                'totalUsersAttendance': 1
            });
        }

        window.alert('Document Added!');
        return true;

    } catch (error) {
        window.alert('Error adding document: ', error);
        return false;
    }
}

/**
 * Checks whether a user is present in the gym's database or not
 * @param {Object} info               :   The information object containing necessary details.
 * @param {String} info.city          :   The City gym is present in.
 * @param {String} info.gymName       :   The Name of the gym.
 * @param {String} info.typeOfMatch   :   The Type of entry, the gym is sending in (for example : email, contactNo, userID).
 * @param {String} info.userCred      :   The user credential used to check if the user exists or not.
 * @returns {Object}                  :   An object containing status and userData.
 */
const checkIfUserExists = async (info) => {
    try {
        console.log('checkIfUserExists : ', info);
        const userRef = collection(db, cityList, info.city, gymList, info.gymName, userList);
        const userDoc = query(userRef, where(info.typeOfMatch, '==', info.userCred), limit(1));
        const userDocs = await getDocs(userDoc);

        // if user doesn't exist
        if (userDocs.empty) {
            window.alert(
                `User Does Not Exist In Gym's Database!\nAre you sure you entered the credential correctly?\n${info.typeOfMatch} : ${info.userCred}`
            );
            return {
                status: false,
                userData: null
            };
        }

        // else if user exists
        let userData = null;
        userDocs.forEach(doc => {
            userData = doc.data();
        });

        return {
            status: true,
            userData: userData
        };
    } catch (error) {
        console.error('Error checking user existence: ', error);
        return {
            status: false,
            userData: null
        };
    }
};

/**
 * Fetches user data from the Firestore database for a given email.
 * @param {string} entryEmail - The email of the user to fetch data for.
 * @returns {Promise<void>}
 */
export const getMoreUsers = async (entryEmail) => {
    try {
        // Reference to the 'users' collection in Firestore
        const userRef = collection(db, 'user');
        
        // Create a query to fetch documents where the 'email' field matches the entryEmail
        const userQuery = query(userRef, where('email', '==', entryEmail), limit(1));
        
        // Execute the query and get the matching documents
        const querySnapshot = await getDocs(userQuery);
        
        // Check if any documents were found
        if (!querySnapshot.empty) {
            // Get the first document in the query snapshot
            const userDoc = querySnapshot.docs[0];
            const userData = userDoc.data();

            // Adding the data to gyms user
            const gymRef = collection(db, cityList, 'delhi', gymList, '21fitness', userList);
            await addDoc(gymRef, {
                email           :   entryEmail,
                userName        :   userData.name,
                userID          :   userDoc.id,
                age             :   userData.age,
                memberSince     :   getRandomDate(),
                membershipTill  :   getRandomDate(1),
                membershipFrom  :   getRandomDate(),
                DOB             :   getRandomDate(2),
                phoneNumber     :   '+919999999998',
                gender          :   userData.gender,
                status          :   'Active Member',
                membershipID    :   '21F004'
            })
        } else {
            console.log('No user found with the provided email.');
        }
    } catch (error) {
        console.log('Error in getting more users:', error);
    }
};

/**
 * Adds User Attendance in User's calendar
 * @param   {Object}    userInfo    -   Information about the user
 *                                      :   userCred        -   User Credential
 *                                      :   typeOfMatch     -   Type of user credential (field to match)
 *                                      :   userWorkouts    -   Workout of the user
 * @return  {Boolean}               -   True, if save successful; false, otherwise
 */
const storeUserAttendanceInUserCalendar = async(userInfo) => {
    try {
        const userRef       = collection(db, 'user');
        const userQuery     = query(userRef, where(userInfo.typeOfMatch, '==', userInfo.userCred), limit(1));
        const userSnapshot  = await getDocs(userQuery);
        
        if (!userSnapshot.empty) {
            const userDoc    = userSnapshot.docs[0];

            // Create calendar document path dynamically
            const calendarRef = doc(db, 'user', userDoc.id, 'Calendar', getCurrentYear(), getCurrentMonth(), getDate());

            // Store attendance with workout details
            await setDoc(calendarRef, {
                present     : true,
                workout     : userInfo.userWorkouts,
                timestamp   : getTime(),
            });

            return true;  // Return true on success
        }

        // If no matching user found, return false
        return false;
        
    } catch (error) {
        console.error('Could not add attendance in user calendar:', error);
        return false;
    }
};
