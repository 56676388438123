import React, { useState, useEffect } from 'react';
import MemberManagement from '../../../helperFunctions/DashboardScenesHelpers/MemberManagementComponents/MemberManagement';
import ExpiringMemberSnippet from '../../../helperFunctions/DashboardScenesHelpers/MemberManagementComponents/ExpiringMemberSnippet';
import { GenderGraph } from '../../../helperFunctions/DashboardScenesHelpers/MemberManagementComponents/GenderGraph';
import { getMemberData } from '../../../helperFunctions/DashboardScenesHelpers/MemberManagementComponents/FetchMembersData';
import { getDate } from '../../../helperFunctions/basicHelper';
import { sortData } from '../../../helperFunctions/DashboardScenesHelpers/UserAttendanceDataHelper';
import RecentAttendance from '../../../helperFunctions/DashboardScenesHelpers/MemberManagementComponents/RecentAttendance';
import { MembersAndPTGraph } from '../../../helperFunctions/DashboardScenesHelpers/MemberManagementComponents/MembersAndPTGraph';
import { AgeGraph } from '../../../helperFunctions/DashboardScenesHelpers/MemberManagementComponents/AgeGraph';
import SidePanel from '../../../components/SidePanel';
import { ClientButtons } from '../../../helperFunctions/DashboardScenesHelpers/MemberManagementComponents/ClientButtons';
import AddRevenue from '../../../helperFunctions/DashboardScenesHelpers/FinanceComponents/AddRevenue';
import { fetchAttendance } from './Overview';
import OverAllAttendance from '../../../helperFunctions/DashboardScenesHelpers/MainDisplayPageHelpers/OverallAttendance';
import PTSessionModal from '../../../helperFunctions/DashboardScenesHelpers/MemberManagementComponents/PTSession';

/**
 * 
 * @param {Object} gymData - Contains information about the gym
 * @param {String} activeTab - ?
 * @returns {JSX.Element} T
 * @returns 
 */
export const ClientsManagement = ({ gymData, activeTab }) => {

    const [memberData,      setMemberData]      = useState([]);
    const [showAllMembers,  setShowAllMembers]  = useState(false);
    const [attendanceData,  setAttendanceData]  = useState([]);
    const [isPanelExpanded, setIsPanelExpanded] = useState(false);
    const [selectedMember,  setSelectedMember]  = useState(null);
    const [showAddMember,   setShowAddMember]   = useState(false);
    const [showPtModal,     setShowPtModal]     = useState(false);

    const togglePanel = () => {
        setIsPanelExpanded(!isPanelExpanded);
    };

    const basicGymData = {
        city: gymData.city || "",  // Ensure gymData has the required fields
        gymName: gymData.gymName || ""
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getMemberData(gymData);
                setMemberData(data);
            } catch (error) {
                console.error('Error fetching member data:', error);
            }
        };
        fetchData();
    }, [gymData, selectedMember]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchAttendance(basicGymData, getDate());
                setAttendanceData(data);
            } catch (error) {
                // console.error('Error fetching attendance data:', error);
            }
        };
        fetchData();
    }, [basicGymData]);

    const handleShowAllMembers = () => {
        setShowAllMembers(true);
    };

    const handleBackToExpiringMembers = () => {
        setShowAllMembers(false);
    };

    /**
     * Toggles the visibility of the Add Member component.
     */
    const toggleAddMember = () => {
        setShowAddMember((prev) => !prev);
    };

    return (
        <div className="flex flex-row w-full pl-8 pr-4">
            <div className="w-full">
                <p className="text-left text-3xl font-bold text-secondary">Clients</p>
                {showAllMembers && (
                    <button onClick={handleBackToExpiringMembers} className="relative top w-fit px-4 py-2 bg-secondary text-black rounded-xl">
                        &larr; Back
                    </button>
                )}
                <div className="flex flex-col lg:flex-row justify-between py-4">
                    {showAddMember ? (
                        <div className="justify-center w-full">
                            <AddRevenue onBackClick={toggleAddMember} gymData={gymData} lastPage='Clients' />
                        </div>
                    ) : !showAllMembers ? (
                        <>
                            <div className="flex flex-col w-full space-y-6">
                                <div className='flex flex-col lg:flex-row justify-between gap-8'>
                                    <OverAllAttendance gymData={gymData} activeTab={activeTab}/>
                                    {/* <GenderGraph gymData={basicGymData} /> */}
                                </div>
                                <div className='flex flex-col lg:flex-row justify-between gap-8'>
                                    <MembersAndPTGraph gymData={gymData}/>
                                    {/* Age Graph */}
                                    <AgeGraph gymData={basicGymData} legendLayout="vertical"/>
                                    <ClientButtons toggleAddMember={toggleAddMember} setShowPtModal={setShowPtModal}/>
                                </div>
                                <div className='flex flex-col lg:flex-row'>
                                    <ExpiringMemberSnippet memberData={memberData} onShowAllMembers={handleShowAllMembers} />
                                </div>
                            </div>
                            <PTSessionModal isOpen={showPtModal} onClose={() => setShowPtModal(false)} gymData={gymData}/>
                        </>
                    ) : (
                        <MemberManagement memberData={memberData} gymData={gymData} basicGymData={basicGymData} selectedMember={selectedMember} setSelectedMember={setSelectedMember} />
                    )}
                </div>
            </div>
            <SidePanel activeTab={activeTab} isExpanded={isPanelExpanded} togglePanel={togglePanel} />
        </div>
    );
};