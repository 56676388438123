import { addDoc, collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "@firebase/firestore";
import { getCurrentMonthAndYear, getLastMonthAndYear, getLastSixMonthsAndYearWithNames, getMonthYearFromTimestamp } from "../../userDatabaseHelper";
import { db } from "../../../config/firebase";
import { cityList, gymList } from "../../../components/Databases";

/**
 * Fetches the leads data for the last 6 months and returns it in a format suitable for a chart.
 * @param {Object} gymData - Gym details including the city and gymName
 * @returns {Array<Object>} - An array of objects where each object contains the month name, total leads, and converted leads.
 */
export const fetchLeadsDataLastSixMonthsForGraph = async (gymData) => {
    const lastSixMonths = getLastSixMonthsAndYearWithNames();
    const chartData = [];

    try {
        // Fetch the data for all six months in parallel
        const promises = lastSixMonths.map(async (monthInfo) => {
            const LeadsRef = doc(db, cityList, gymData.city, gymList, gymData.gymName, "Leads", monthInfo.monthYear);
            const summaryDoc = await getDoc(LeadsRef);

            if (summaryDoc.exists()) {
                const data = summaryDoc.data();
                return {
                    name        : monthInfo.name, // e.g., "January"
                    leads       : data.totalLeads || 0,
                    converted   : data.converted || 0,
                };
            } else {
                // Return default values if the document doesn't exist
                return { name: monthInfo.name, leads: 0, converted: 0 };
            }
        });

        // Wait for all promises to resolve and populate chartData
        chartData.push(...await Promise.all(promises));

        return chartData.reverse(); // Data ready for the graph
    } catch (error) {
        console.error("Error fetching lead data for last 6 months: ", error);
        throw error;
    }
};

/**
 * Fetches leads data for both the current month and the previous month.
 * @param {Object} gymData - Contains the all the information about the gym
 * @returns {Promise<Array>} - An array of lead data from the current and last month.
 */
export const fetchLeadsData = async (gymData) => {
    const currentMonth = getCurrentMonthAndYear();
    const lastMonth    = getLastMonthAndYear();

    const LeadsRefCurrent = doc(db, cityList, gymData.city, gymList, gymData.gymName, "Leads", currentMonth);
    const LeadsRefLast    = doc(db, cityList, gymData.city, gymList, gymData.gymName, "Leads", lastMonth);

    try {
        // Fetch the "Summary" document for the current month
        const summaryDocCurrent = await getDoc(LeadsRefCurrent);
        let leadsCurrentMonth = { totalLeads: 0, convertedLeads: 0 }; // Default values

        if (summaryDocCurrent.exists()) {
            leadsCurrentMonth = {
                totalLeads      : summaryDocCurrent.data().totalLeads || 0,
                convertedLeads  : summaryDocCurrent.data().converted || 0
            };
        }

        // Fetch the "Summary" document for the last month
        const summaryDocLast = await getDoc(LeadsRefLast);
        let leadsLastMonth = { totalLeads: 0, convertedLeads: 0 }; // Default values

        if (summaryDocLast.exists()) {
            leadsLastMonth = {
                totalLeads     : summaryDocLast.data().totalLeads || 0,
                convertedLeads : summaryDocLast.data().converted || 0
            };
        }

        // Combine data from both months
        const totalLeads          = leadsCurrentMonth.totalLeads     + leadsLastMonth.totalLeads;
        const totalConvertedLeads = leadsCurrentMonth.convertedLeads + leadsLastMonth.convertedLeads;

        return { totalLeads, totalConvertedLeads };
    } catch (error) {
        console.error("Error fetching lead data: ", error);
        throw error;
    }
};



/**
 * Fetches leads data for both the current month and the previous month.
 * @param {Object} gymData - Contains the all the information about the gym
 * @returns {Promise<Array>} - An array of lead data from the current and last month.
 */
export const fetchLeadsInfoData = async (gymData) => {
    const currentMonth = getCurrentMonthAndYear();
    const lastMonth = getLastMonthAndYear();

    const LeadsRefCurrent = collection(db, cityList, gymData.city, gymList, gymData.gymName, "Leads", currentMonth, "LeadInfo");
    const LeadsRefLast    = collection(db, cityList, gymData.city, gymList, gymData.gymName, "Leads", lastMonth, "LeadInfo");
    try {
        // Fetch data for the current month
        const querySnapshotCurrent = await getDocs(LeadsRefCurrent);
        const leadsCurrentMonth    = querySnapshotCurrent.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Fetch data for the previous month
        const querySnapshotLast = await getDocs(LeadsRefLast);
        const leadsLastMonth    = querySnapshotLast.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Combine data from both months
        const allLeads = [...leadsCurrentMonth, ...leadsLastMonth];

        return allLeads;
    } catch (error) {
        console.error("Error fetching lead data: ", error);
        throw error;
    }
};


// Generic function to query and update Firestore based on the 'email' field
export const updateLeadField = async (gymData, item, field, value) => {
    try {
        // Get the month-year from timestamp
        const monthYear = getMonthYearFromTimestamp(item.timestamp);

        // Construct the path to the Leads collection for the specific month
        const leadsCollectionRef = collection(db, cityList, gymData.city, gymList, gymData.gymName, 'Leads', monthYear, 'LeadInfo');

        // Query the collection where the email matches
        const q = query(leadsCollectionRef, where('email', '==', item.email));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            // Update the document with the new field value
            querySnapshot.forEach(async (docSnapshot) => {
                const docRef = docSnapshot.ref;
                await updateDoc(docRef, {
                    [field]: value
                });
            });
        } else {
            console.error("No matching document found.");
        }
    } catch (error) {
        console.error('Error updating Firestore document:', error);
    }
};

export const updateRemarks = async (gymData, lead, field, value) => {
    const monthYear = getMonthYearFromTimestamp(lead.timestamp); // Assuming 'lead.timestamp' is available

    // Collection reference for leads in the current gym and month
    const collectionRef = collection(db, cityList, gymData.city, gymList, gymData.gymName, 'Leads', monthYear, 'LeadInfo');

    // Query the collection to find documents where the email matches the lead's email
    const q = query(collectionRef, where("email", "==", lead.email));

    try {
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            // Loop through all matching documents
            querySnapshot.forEach(async (docSnapshot) => {
                const docRef = doc(db, docSnapshot.ref.path);

                // Update the document's remarks (or other fields)
                await updateDoc(docRef, {
                    [field]: value,
                });

                console.log(`Remarks updated successfully for ${lead.email}`);
            });
        } else {
            console.log(`No documents found with email: ${lead.email}`);
        }
    } catch (error) {
        console.error("Error updating remarks:", error);
    }
};

// To update/add the price of the membership at which the lead bought it, to be added by the gym owner where tehy click the converted field from SalesPipeline
export const updatePrice = async (gymData, lead, field, value) => {
    const monthYear = getMonthYearFromTimestamp(lead.timestamp); 

    // Collection reference for leads in the current gym and month
    const collectionRef = collection(db, cityList, gymData.city, gymList, gymData.gymName, 'Leads', monthYear, 'LeadInfo');

    // Query the collection to find documents where the email matches the lead's email
    const q = query(collectionRef, where("email", "==", lead.email));

    try {
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            // Loop through all matching documents
            querySnapshot.forEach(async (docSnapshot) => {
                const docRef = doc(db, docSnapshot.ref.path);

                // Update the document's remarks (or other fields)
                await updateDoc(docRef, {
                    [field]: value,
                });

                console.log(`Price Added successfully for ${lead.email}`);
            });
        } else {
            console.log(`No documents found with email: ${lead.email}`);
        }
    } catch (error) {
        console.error("Error updating remarks:", error);
    }
}


// to increment the converted leads by one 1 when gym owner updates the converted section in the sales page
export const updateConvertedLeads = async (gymData, currStatus) => {
    try {
        const monthYear = getCurrentMonthAndYear();

        // Construct the path to the Leads collection for the specific month
        const leadsDocRef = doc(db, cityList, gymData.city, gymList, gymData.gymName, 'Leads', monthYear);
        const docSnapshot = await getDoc(leadsDocRef);
        
        if (!docSnapshot.empty && (Object.keys(docSnapshot.data()).length > 0)) {
            const currentconvertedLeads = docSnapshot.data().converted || 0;
            if (currStatus === false)   // if the lead was not converted yet and we are converting it, then in the database increment "converted" field by 1
                await updateDoc(leadsDocRef, { converted: currentconvertedLeads + 1 });
            else                        // if the lead was converted and for some reason it is not converted anymore, then in the database decrement "converted" field by 1
                await updateDoc(leadsDocRef, { converted: currentconvertedLeads - 1 });
        }
    } catch (error) {
        console.error("Error in updateConvertedLeads", error);   
    }
}

export const addNewLead = async (newLead, gymData, setSubmitting) => {
    try {

        const currMonthYear = getCurrentMonthAndYear();
        // Reference to the Leads collection
        const leadRef = collection(db, cityList, gymData.city, gymList, gymData.gymName, 'Leads', currMonthYear, 'LeadInfo');
        // Add the new lead to Firestore
        await addDoc(leadRef, newLead);    
        
        const totalLeadsRef = doc(db, cityList, gymData.city, gymList, gymData.gymName, 'Leads', currMonthYear);
        // Get the current month name
        const monthName = new Date().toLocaleString('default', { month: 'long' });

        // Check if the document exists
        const docSnapshot = await getDoc(totalLeadsRef);

        if (docSnapshot.exists() && (Object.keys(docSnapshot.data()).length > 0)) {
            // Document exists, update the totalLeads field using updateDoc
            const currentTotalLeads = docSnapshot.data().totalLeads || 0;
            await updateDoc(totalLeadsRef, { totalLeads: currentTotalLeads + 1 });
        } else {
            // Document doesn't exist, create it with default values using setDoc
            await setDoc(totalLeadsRef, {
                converted: 0,
                totalLeads: 1,
                monthName: monthName
            });
        }
        setSubmitting(false);
    } catch (error) {
        console.error("Error adding lead:", error);
        setSubmitting(false);
    }
}

// Function to toggle 'contacted' status and update the database
export const toggleContacted = async (item, gymData, setLeadsInfoData, setSubmitting) => {
    setSubmitting(true);
    
    const newStatus = !item.contacted; // Toggle the value
    try {
        // First, update Firestore
        await updateLeadField(gymData, item, 'contacted', newStatus);

        // If Firestore update succeeds, update the local state
        setLeadsInfoData(prevData =>
            prevData.map((lead) =>
                lead.email === item.email ? { ...lead, contacted: newStatus } : lead
            )
        );
    } catch (error) {
        console.error("Error updating contacted status:", error);
    }
    setSubmitting(false);
};

// Function to toggle 'interested' status and update the database
export const toggleInterested = async (item, gymData, setLeadsInfoData, setSubmitting) => {
    setSubmitting(true);

    const newStatus = !item.interested; // Toggle the value
    try {
        // First, update Firestore
        await updateLeadField(gymData, item, 'interested', newStatus);
        // If Firestore update succeeds, update the local state
        setLeadsInfoData(prevData =>
            prevData.map((lead) =>
                lead.email === item.email ? { ...lead, interested: newStatus } : lead
            )
        );
    } catch (error) {
        console.error("Error updating interested status:", error);
    }
    setSubmitting(false);
};

// Function to toggle 'converted' status and update the database
export const toggleConverted = async (item, gymData, setLeadsData, setLeadsInfoData, setSubmitting) => {
    setSubmitting(true);

    const currStatus = item.converted;      // To decrement the converted leads value if its currently set to converted, and increase converted leads value if its not converted yet
    const newStatus  = !item.converted;     // Toggle the value
    try {
        // First, update individual lead
        await updateLeadField(gymData, item, 'converted', newStatus);

        // Update lead doc
        await updateConvertedLeads(gymData, currStatus);

        if(currStatus === false)
            setLeadsData((prev) => ({...prev, totalConvertedLeads: prev.totalConvertedLeads + 1}));
        else 
            setLeadsData((prev) => ({...prev, totalConvertedLeads: prev.totalConvertedLeads - 1}));

        // If Firestore update succeeds, update the local state
        setLeadsInfoData(prevData =>
            prevData.map((lead) =>
                lead.email === item.email ? { ...lead, converted: newStatus } : lead
            )
        );
    } catch (error) {
        console.error("Error updating converted status:", error);
        // Optionally show an error notification to the user
    }
    setSubmitting(false);
};