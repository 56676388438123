/**
 * Returns the Country Code of the user
 * Based on their IP Address
 * @returns {String} - The Country Code user's IP is from
 */
export const getUserCountryCode = async () => {
    try {
        const response = await fetch('https://ipwhois.app/json/');
        const data = await response.json();
        if (data && data.country_code) {
            // console.log('User City:', data);
            return data.country_code;
        } else {
            console.error('Country not found');
            return 'US';
        }
    } catch (error) {
        console.error('Error fetching country from IP:', error.message);
        return 'US';
    }
}

// Latitude and longitude
export const getCoordinates = async (address, locality, city, country) => {
    const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(address + locality + city + country)}&format=json`;

    try {
        const response = await fetch(url, {
            headers: {
                'User-Agent': 'YourAppName/1.0'  // Add a custom User-Agent to comply with the Nominatim usage policy
            }
        });

        const data = await response.json();
        console.log("Data nig : ", data);

        if (data.length > 0) {
            return {
                lat: data[0].lat,
                lon: data[0].lon
            };
        } else {
            console.error('No coordinates found for the address');
            return null;
        }
    } catch (error) {
        console.error('Error fetching coordinates:', error);
        return null;
    }
};