import { useEffect, useState } from "react";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { AddNewLeadModal, AddPriceModal, EditRemarksModal } from "./SalesPipeline";
import { addNewLead, toggleContacted, toggleConverted, toggleInterested, updatePrice, updateRemarks } from "./SalesPageHelpers";
import { timeFormat } from "../../userDatabaseHelper";
import { checkValidFormDetails } from "../FinanceComponents/AddRevenueHelper";
import UserProfile from "../MemberManagementComponents/UserProfile";

// Receives
// gymData          - to get the coreect path in the database
// leadsInfoData    - inforamtion of each and every individual lead
// setLeadsInfoData - to update the state locally
// setLeadsData     - to pass the updated data in the other components of this page 
// setShowAllLeads  - to toggle between All leads page and other components
export const AllLeadsPage = ({ gymData, leadsInfoData, setLeadsInfoData, setLeadsData, setShowAllLeads }) => {
    const [submitting           , setSubmitting          ] = useState(false);
    const [showEditRemarksModal , setShowEditRemarksModal] = useState(false);
    const [selectedLead         , setSelectedLead        ] = useState(null); // State to track the selected lead
    const [showAddPriceModal    , setShowAddPriceModal   ] = useState(false);
    const [isModalOpen          , setIsModalOpen         ] = useState(false);
    const [showLeadsDetailsModal, setShowLeadDetailsModal] = useState(false);
    const [sortField            , setSortField           ] = useState("userName"); // Default sorting by userName
    const [sortOrder            , setSortOrder           ] = useState("asc"); // Default sorting order
    const [searchQuery          , setSearchQuery         ] = useState(""); // State for search input
    const [statusFilter         , setStatusFilter        ] = useState("All"); // State for status filter


    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top-left corner (x: 0, y: 0)
    }, []);

    // Filter leads based on the search query and status filter
    const filteredLeads = leadsInfoData?.filter((lead) => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        const matchesSearchQuery = 
            lead.userName?.toLowerCase().includes(lowerCaseQuery) ||    // Include if name matches
            lead.phoneNumber?.includes(searchQuery) ||                  // Include if phone number matches
            lead.email?.toLowerCase().includes(lowerCaseQuery);         // Include if email matches

        const matchesStatusFilter = 
            statusFilter  === "All" ||                                  // Show all leads if "All" is selected
            (statusFilter === "Contacted"   && lead.contacted)  ||
            (statusFilter === "Interested"  && lead.interested) ||
            (statusFilter === "Converted"   && lead.converted);

        return matchesSearchQuery && matchesStatusFilter && lead.userName && lead.userName.trim() !== ""; // Exclude leads without a name
    });


    const sortLeads = (leads) => {
        const sortedLeads = [...leads]; // Create a shallow copy to avoid mutating original leads
    
        sortedLeads.sort((a, b) => {
            if (sortField === "userName") {
                const nameA = a.userName?.toLowerCase() || "";
                const nameB = b.userName?.toLowerCase() || "";
    
                if (sortOrder === "asc") {
                    return nameA.localeCompare(nameB);
                } else {
                    return nameB.localeCompare(nameA);
                }
            } else if (sortField === "timestamp") {
                const dateA = new Date(a.timestamp);
                const dateB = new Date(b.timestamp);
    
                if (sortOrder === "asc") {
                    return dateA - dateB; // Sort ascending by date
                } else {
                    return dateB - dateA; // Sort descending by date
                }
            }
            return 0; // Default case (no sort)
        });
    
        return sortedLeads;
    };

    const sortedLeads = sortLeads(filteredLeads || []);

    const handleToggleContacted = async(lead) => {
        setSubmitting(true);
        await toggleContacted(lead, gymData, setLeadsInfoData, setSubmitting);
        setSubmitting(false);
    };

    const handleToggleInterested = async (lead) => {
        setSubmitting(true);
        await toggleInterested(lead, gymData, setLeadsInfoData, setSubmitting);
        setSubmitting(false);
    };

    const handleAddNewLead = async (event) => {
        event.preventDefault();
    
        setSubmitting(true);
    
        const newLead = {
            boughtMembership    : false,
            contacted           : event.target.contacted.checked, // Get the checkbox value
            converted           : event.target.converted.checked, // Get the checkbox value
            email               : event.target.email.value,
            interested          : event.target.interested.checked, // Get the checkbox value
            phoneNumber         : `+91${event.target.phoneNumber.value}`,
            signedIn            : null,
            tempUser            : '',
            timestamp           : new Date().toISOString(),
            userCity            : event.target.userCity.value.toLowerCase(),
            userName            : event.target.userName.value,
            remarks             : event.target.remarks.value,
        };

        const isValidForm = await checkValidFormDetails("New Lead", newLead);

        if(isValidForm === false) {
            alert("Please fill the details correctly");
            setSubmitting(false);
            return;
        }
    
        await addNewLead(newLead, gymData, setSubmitting);
    
        setSubmitting(false);
        setIsModalOpen(false); // Close the modal after submission
    }

    const handleRemarksUpdate = async (e, lead) => {
        e.preventDefault();
    
        setSubmitting(true);
    
        // Get the updated remarks from the form input
        const updatedRemarks = e.target.remarks.value;
    
        try {
            // Call Firestore function to update the remarks field
            await updateRemarks(gymData, lead, 'remarks', updatedRemarks);
    
            // Update local state after successfully updating the database
            setLeadsInfoData(prevData =>
                prevData.map((item) =>
                    item.email === lead.email ? { ...item, remarks: updatedRemarks } : item
                )
            );
    
        } catch (error) {
            // console.error("Error updating remarks:", error);
        }
    
        setSubmitting(false);
        setShowEditRemarksModal(false);  // Close the modal after submission
    };

    const handleAddPrice = async (e, lead) => {
        e.preventDefault();
    
        setShowAddPriceModal(false);  // Close the modal after submission
        setSubmitting(true);
        const membershipPrice = e.target.price.value;
    
        try {
            // Call Firestore function to update the remarks field
            await updatePrice(gymData, lead, 'membershipPrice', membershipPrice);
            
            // if the lead is already converted (true) then no need to set it to false
            if(!lead.converted) await toggleConverted(lead, gymData, setLeadsData, setLeadsInfoData, setSubmitting);
    
            // Update local state after successfully updating the database
            setLeadsInfoData(prevData =>
                prevData.map((item) =>
                    item.email === lead.email ? { ...item, membershipPrice: membershipPrice } : item
                )
            );
    
        } catch (error) {
            // console.error("Error Adding Price:", error);
        }
    
        setSubmitting(false);
    }

    return (
        <div className="mt-4">
            <button 
                onClick={() => setShowAllLeads(false)}
                className="text-sm md:text-base font-semibold text-primary bg-secondary py-2 px-4 hover:bg-primary hover:text-tertiary transition-all duration-300 rounded-lg"
            >
                &lt; Back
            </button>
            <div className="flex flex-col pt-2 w-full lg:w-7/8">
                <div className="flex justify-between items-center">
                    <p className="text-sm md:text-lg text-secondary font-bold text-left p-2">Sales Pipeline</p>
                    <button 
                        className='text-tertiary font-semibold text-sm md:text-base bg-secondary px-2 md:px-4 py-1 rounded-md md:rounded-xl mr-2 hover:scale-105 hover:shadow-lg hover:shadow-primary transition-all duration-300'
                        onClick={() => setIsModalOpen(true)}
                    >
                        Add New Lead
                    </button>
                </div>

                <div className="flex gap-4 md:gap-20 mt-2">
                    {/* Search Input */}
                    <input
                        type="text"
                        placeholder="Search by name, email, or phone number..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="text-xs md:text-base mb-4 px-4 py-2 border-2 border-secondary rounded-md w-1/2 md:w-1/4"
                    />

                    {/* Dropdown for Status Filter */}
                    <select
                        value={statusFilter}
                        onChange={(e) => setStatusFilter(e.target.value)}
                        className="text-xs md:text-base mb-4 px-4 py-2 border-2 border-secondary rounded-md w-1/2 md:w-1/4"
                    >
                        <option value="All">All Leads</option>
                        <option value="Contacted">Contacted</option>
                        <option value="Interested">Interested</option>
                        <option value="Converted">Converted</option>
                    </select>
                </div>

                <div className="flex flex-col pb-4 rounded-2xl md:rounded-3xl border-secondary border-2 py-1 px-1 md:py-2 md:px-2">
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-tertiary">
                            <thead>
                                <tr>
                                    <th className="py-1 px-2 md:py-2 md:px-4 border-b-2 border-secondary text-center text-secondary text-xs md:text-sm"></th>
                                    <th
                                        className="py-1 px-2 md:py-2 md:px-4 border-b-2 border-secondary text-center text-secondary text-xs md:text-sm cursor-pointer min-w-28"
                                        onClick={() => {
                                            const newSortOrder = sortField === "userName" && sortOrder === "asc" ? "desc" : "asc";
                                            setSortField("userName");
                                            setSortOrder(newSortOrder);
                                        }}
                                    >
                                        Full Name {sortField === "userName" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
                                    </th>                                    
                                    <th className="py-1 px-2 md:py-2 md:px-4 border-b-2 border-secondary text-center text-secondary text-xs md:text-sm">Phone Number</th>
                                    <th
                                        className="py-1 px-2 md:py-2 md:px-4 border-b-2 border-secondary text-center text-secondary text-xs md:text-sm cursor-pointer"
                                        onClick={() => {
                                            const newSortOrder = sortField === "timestamp" && sortOrder === "asc" ? "desc" : "asc";
                                            setSortField("timestamp");
                                            setSortOrder(newSortOrder);
                                        }}
                                    >
                                        Time {sortField === "timestamp" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
                                    </th>                                    
                                    <th className="py-1 px-2 md:py-2 md:px-4 border-b-2 border-secondary text-center text-secondary text-xs md:text-sm">Contacted</th>
                                    <th className="py-1 px-2 md:py-2 md:px-4 border-b-2 border-secondary text-center text-secondary text-xs md:text-sm">Interested</th>
                                    <th className="py-1 px-2 md:py-2 md:px-4 border-b-2 border-secondary text-center text-secondary text-xs md:text-sm">Converted</th>
                                    <th className="py-1 px-2 md:py-2 md:px-4 border-b-2 border-secondary text-center text-secondary text-xs md:text-sm">Price</th>
                                    <th className="py-1 px-2 md:py-2 md:px-4 border-b-2 border-secondary text-center text-secondary text-xs md:text-sm">Remarks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedLeads && sortedLeads.length > 0 ? 
                                    (sortedLeads.map((item, index) => (
                                        <tr
                                            key={index}
                                            className="cursor-pointer"
                                        >
                                            <td className="py-1 px-2 md:py-2 md:px-4 border-b text-center text-xs md:text-sm font-normal md:font-semibold" onClick={() => {setSelectedLead(item); setShowLeadDetailsModal(true)}}>{index + 1}</td>
                                            <td className="py-1 px-2 md:py-2 md:px-4 border-b text-center text-xs md:text-sm font-normal md:font-semibold" onClick={() => {setSelectedLead(item); setShowLeadDetailsModal(true)}}>{item.userName}</td>
                                            <td className="py-1 px-2 md:py-2 md:px-4 border-b text-center text-xs md:text-sm font-normal md:font-semibold" onClick={() => {setSelectedLead(item); setShowLeadDetailsModal(true)}}>{item.phoneNumber}</td>
                                            <td className="py-1 px-2 md:py-1 border-b text-center text-xs md:text-sm font-normal md:font-semibold min-w-28">{timeFormat(item.timestamp)}</td>
                                            <td
                                                className="py-1 px-2 md:py-2 md:px-4 border-b text-center text-xs md:text-sm font-normal md:font-semibold cursor-pointer"
                                                onClick={() => {handleToggleContacted(item); setSelectedLead(item);}} // Pass both index and item
                                            >
                                                {item.contacted ? '✔️' : '-'}
                                            </td>
                                            <td
                                                className="py-1 px-2 md:py-2 md:px-4 border-b text-center text-xs md:text-sm font-normal md:font-semibold cursor-pointer"
                                                onClick={() => {handleToggleInterested(item); setSelectedLead(item);}}
                                            >
                                                {item.interested ? '✔️' : '-'}
                                            </td>
                                            <td
                                                className="py-1 px-2 md:py-2 md:px-4 border-b text-center text-xs md:text-sm font-normal md:font-semibold cursor-pointer"
                                                onClick={() => {setShowAddPriceModal(true); setSelectedLead(item)}}
                                            >
                                                {item.converted ? '✔️' : '-'}
                                            </td>
                                            <td className="py-1 px-2 md:py-2 md:px-4 border-b text-center text-xs md:text-sm font-normal md:font-semibold cursor-default">{item.membershipPrice ? item.membershipPrice : '-'}</td>
                                            <td
                                                className="py-1 px-2 md:py-2 md:px-4 border-b text-center text-xs md:text-sm font-normal md:font-semibold cursor-pointer"
                                                onClick={() => { 
                                                    setSelectedLead(item);          // Set the selected lead
                                                    setShowEditRemarksModal(true);  // Show the modal
                                                }}
                                            >
                                                {item.remarks}
                                            </td>
                                        </tr>
                                    ))
                                // ) : leadsInfoData.length === null ? (
                                //     <tr className='h-[400px] text-4xl text-primary flex justify-between items-center'>
                                //         No Data Available
                                //     </tr>
                                ) : (
                                    <tr className='h-[400px] w-full flex justify-center items-center'>
                                        <td className="text-4xl text-primary">No results for this search</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    
                </div>

                {isModalOpen && 
                    <AddNewLeadModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onSubmit={handleAddNewLead} />}

                {showEditRemarksModal && selectedLead && (
                    <EditRemarksModal lead={selectedLead} onClose={() => setShowEditRemarksModal(false)} onSubmit={handleRemarksUpdate} />
                )}

                {showAddPriceModal && selectedLead && (
                    <AddPriceModal lead={selectedLead} onClose={() => setShowAddPriceModal(false)} onSubmit={handleAddPrice}/>
                )}

                {showLeadsDetailsModal && selectedLead && (
                    <div className="fixed inset-y-0 left-0 z-50 bg-black bg-opacity-50 flex items-center">
                        <UserProfile currentPage="sales" member={selectedLead} onClose={() => setShowLeadDetailsModal(false)} basicGymData={{city: selectedLead.userCity, gymName: gymData.gymName}} gymData={gymData}/>
                    </div>        
                )}
                    

                {submitting && 
                    <div className='z-50 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'> 
                        <div className='h-44 w-44'>
                            <LoadingSpinner />
                        </div>
                    </div>}
            </div>
        </div>
    );
};