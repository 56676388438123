import React, { useState, useEffect } from 'react';
import { fetchExpenseReceipts } from './FetchFinanceData';

// Defining how many receipts we need per page
const receiptsPerPage = 12;

/**
 * ExpenseReceipts component to display expense receipts with tabs for filtering by type
 * and a search function to filter by staff name.
 *
 * @param {Function} onBackClick - Function to handle the back button click event.
 * @param {Object} gymData - Contains Gym Information
 * @returns {JSX.Element}
 */
const ExpenseReceipts = ({ onBackClick, gymData }) => {
    const [expenses, setExpenses] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [activeTab, setActiveTab] = useState('All');

    // Fetches expenses data when the component is mounted or gymData changes.
    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchExpenseReceipts(gymData);
            setExpenses(data);
        };
        fetchData();
    }, [gymData]);

    // Sort receipts in descending order by timestamp
    const sortedReceipts = expenses.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

    // Filter receipts based on the active tab and search term
    const filteredReceipts = getFilteredReceipts(sortedReceipts, activeTab, searchTerm);

    // Calculate the indices for the current page
    const indexOfLastReceipt = currentPage * receiptsPerPage;
    const indexOfFirstReceipt = indexOfLastReceipt - receiptsPerPage;
    const currentReceipts = filteredReceipts.slice(indexOfFirstReceipt, indexOfLastReceipt);

    // Calculate total pages
    const totalPages = Math.ceil(filteredReceipts.length / receiptsPerPage);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    return (
        <div className="px-8 py-8">
            <div className="flex justify-between items-center mb-4">
                <button className="bg-secondary text-white py-2 px-4 rounded hover:bg-primary" onClick={onBackClick}>
                    &#8592; Back
                </button>
                <div className="flex items-center space-x-4">
                    <div className="tabs flex space-x-2">
                        {['All', 'Salary', 'Maintenance', 'Misc'].map((tab) => (
                            <button
                                key={tab}
                                className={`tab-button px-4 py-2 rounded ${activeTab === tab ? 'bg-secondary text-white' : 'bg-white text-black border'}`}
                                onClick={() => {
                                    setActiveTab(tab);
                                    setCurrentPage(1); // Reset to first page when tab changes
                                }}
                            >
                                {tab}
                            </button>
                        ))}
                    </div>
                    <input
                        type="text"
                        className="border rounded px-4 py-2"
                        placeholder="Search by staff name"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            </div>

            {currentReceipts.length > 0 ? (
                <>
                    {renderAllReceipts(currentReceipts)}

                    {/* Pagination controls */}
                    <div className="flex justify-center space-x-5 mt-4">
                        <button
                            className={`py-2 px-4 rounded ${currentPage === 1 ? 'bg-white text-gray-500' : 'bg-primary text-white'}`}
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        {[...Array(totalPages)].map((_, index) => (
                            <button
                                key={index + 1}
                                className={`py-2 px-4 rounded ${currentPage === index + 1 ? 'bg-secondary text-white' : 'bg-white text-black border'}`}
                                onClick={() => handlePageChange(index + 1)}
                            >
                                {index + 1}
                            </button>
                        ))}
                        <button
                            className={`py-2 px-4 rounded ${currentPage === totalPages ? 'bg-gray-300 text-gray-500' : 'bg-primary text-white'}`}
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                </>
            ) : (
                <p className="text-center text-lg">No receipts found.</p>
            )}
        </div>
    );
};

export default ExpenseReceipts;


/**
 * Filters the receipts based on the active tab and search term.
 *
 * @param {Array} receipts - Array of all expense receipts.
 * @param {String} activeTab - The current active tab for filtering receipts.
 * @param {String} searchTerm - The search term for filtering by staff name.
 * @returns {Array} - Filtered array of receipts.
 */
const getFilteredReceipts = (receipts, activeTab, searchTerm) => {
    return receipts.filter((receipt) => {
        let matchesSearch = ""
        const matchesTab = activeTab === 'All' ||
            (activeTab === 'Salary' && receipt.orderType === 'Salary') ||
            (activeTab === 'Maintenance' && receipt.orderType === 'Maintenance') ||
            (activeTab === 'Misc' && receipt.orderType !== 'Salary' && receipt.orderType !== 'Maintenance');
        
    
        if (receipt.displayName)
            matchesSearch = receipt.displayName.toLowerCase().includes(searchTerm.toLowerCase());
        else 
            matchesSearch = receipt.staffName.toLowerCase().includes(searchTerm.toLowerCase());
        
        return matchesTab && matchesSearch;
    });
};


/**
 * Renders all filtered receipts in a 4-column grid layout with different details based on order type.
 *
 * @param {Array} receipts - Array of filtered receipt objects.
 * @returns {JSX.Element} - The rendered receipts component.
 */
const renderAllReceipts = (receipts) => {
    return (
        <div className="grid grid-cols-4 gap-4">
            {receipts.map((receipt, index) => (
                <div key={index} className="p-4 bg-secondary rounded-lg shadow-md relative text-logoGray">
                    <div className="receipt-header mb-2">
                        <span className="display-name text-lg font-medium text-left">
                            <strong>{receipt.displayName || receipt.staffName}</strong>
                        </span>
                        <span className="timestamp text-sm text-logoGray">
                            {new Date(receipt.timestamp).toLocaleString()}
                        </span>
                    </div>
                    <hr className="border-tertiary my-2" />
                    <div className="flex flex-col gap-1">
                        {receipt.orderType === 'Salary' && (
                            <>
                                <span className="text-sm text-left"><strong>Salary Month:</strong> {receipt.salaryMonth}</span>
                                <span className="text-sm text-left"><strong>Position:</strong> {receipt.position}</span>
                                <span className="text-sm text-left"><strong>Order Type:</strong> {receipt.orderType}</span>
                            </>
                        )}
                        {receipt.orderType === 'Maintenance' && (
                            <>
                                <span className="text-sm text-left"><strong>Paid By:</strong> {receipt.staffName}</span>
                                <span className="text-sm text-left"><strong>Position:</strong> {receipt.displayName}</span>
                                <span className="text-sm text-left"><strong>Order Type:</strong> {receipt.orderType}</span>
                            </>
                        )}
                        {receipt.orderType !== 'Salary' && receipt.orderType !== 'Maintenance' && (
                            <>
                                <span className="text-sm text-left"><strong>Paid By:</strong> {receipt.staffName}</span>
                                <span className="text-sm text-left"><strong>Position:</strong> {receipt.displayName}</span>
                                <span className="text-sm text-left"><strong>Order Type:</strong> {receipt.orderType}</span>
                            </>
                        )}
                        <hr className="border-tertiary my-2" />
                        <span className="text-right text-lg font-bold">₹ {receipt.amount / 100}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};
