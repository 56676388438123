/**
 * ReceiptDetailsModal.js
 * 
 * Renders the PrintView of the Receipts in a modal and allows the user to generate a PDF or send the receipt details to a phone number.
 */

import React, { useState } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { toWords } from "number-to-words";
import { gypsyImage } from "../../../assets/gymLogos/gypsyLogo";
import { handleSendPersonalizedMessage } from "../../basicHelper";

// Initialize pdfMake with fonts
pdfMake.vfs = pdfFonts.pdfMake.vfs;

/**
 * Converts the total amount to words using the number-to-words library.
 * 
 * @param {number} amount - The total amount to convert to words.
 * @returns {string} - The total amount in words.
 */
const getTotalAmountInWords = (amount) => {
    return toWords(amount).toUpperCase();
};

/**
 * Creates the PDF document definition.
 * 
 * @param {Object} receipt - The receipt details.
 * @param {Object} gymData - The gym details.
 * @returns {Object} - The document definition for the PDF.
 */
const createDocDefinition = (receipt, gymData) => {
    const totalAmount = receipt.amount / 100 || 0;
    const totalAmountInWords = getTotalAmountInWords(totalAmount);

    let netPrice = totalAmount / 1.18;
    let cgst = 0.09 * netPrice;
    let sgst = 0.09 * netPrice;
    sgst = parseFloat(sgst.toFixed(2));
    cgst = parseFloat(sgst.toFixed(2));
    netPrice = parseFloat(netPrice.toFixed(2));

    return {
        content: [
            {
                image: gymData.gymLogo,     // Ensure gymLogo contains a valid base64 string or an empty string as a fallback
                fit: [85, 85],              // Adjust the size of the logo
                alignment: 'left',          // Center the logo
                margin: [0, 0, 0, 10]       // Add some margin below the logo
            },
            {
                text: gymData.gymDisplayName || 'Gym Name',
                fontSize: 20,
                bold: true,
                alignment: 'center',
                margin: [0, -80, 0, 10]
            },
            {
                text: `${gymData.address || 'Address'}, ${gymData.cityDisplayName || 'City'}\n` +
                    `Phone: ${gymData.contactNo || 'Phone'} | Email: ${gymData.email || 'Email'}\n` +
                    `GSTIN: ${gymData.gstin || 'N/A'}`,
                fontSize: 10,
                alignment: 'center',
                margin: [0, 0, 0, 20]
            },
            {
                text: `Name: ${receipt.userName || 'N/A'}\n` +
                    `Phone: ${receipt.phoneNumber || 'N/A'}`,
                margin: [0, 0, 0, 10]
            },
            {
                text: `Transaction No: ${receipt.paymentID || 'N/A'}\n` +
                    `Receipt Date: ${receipt.timestamp ? new Date(receipt.timestamp).toLocaleDateString('en-GB') : 'N/A'}\n` +
                    `Payment Status: Paid`,
                margin: [0, 0, 0, 10]
            },
            {
                table: {
                    headerRows: 1,
                    widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto'],
                    body: [
                        [
                            { text: 'Qty', bold: true },
                            { text: 'Start Date', bold: true, alignment: 'right' },
                            { text: 'End Date', bold: true, alignment: 'right' },
                            { text: 'Net Amount', bold: true, alignment: 'right' },
                            { text: 'CGST (9%)', bold: true, alignment: 'right' },
                            { text: 'SGST (9%)', bold: true, alignment: 'right' },
                            { text: 'Total Amount', bold: true, alignment: 'right' },
                        ],
                        [
                            { text: `${receipt.months || '0'} Months`, fontSize: 8 },
                            { text: `${receipt.startDate ? receipt.startDate : 'N/A'}`, alignment: 'right', fontSize: 8 },
                            { text: `${receipt.endDate ? receipt.endDate : 'N/A'}`, alignment: 'right', fontSize: 8 },
                            { text: `₹${netPrice || '0'}`, alignment: 'right', fontSize: 8 },
                            { text: `₹${cgst || '0'}`, alignment: 'right', fontSize: 8 },
                            { text: `₹${sgst || '0'}`, alignment: 'right', fontSize: 8 },
                            { text: `₹${totalAmount || '0'}`, alignment: 'right', fontSize: 8 },
                        ],
                    ]
                },
                layout: {
                    fillColor: '#f3f3f3',
                    hLineColor: function (i, node) { return (i === 0 || i === node.table.body.length) ? 'black' : '#ccc'; },
                    vLineColor: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 'black' : '#ccc'; },
                    hLineWidth: function (i, node) { return (i === 0 || i === node.table.body.length) ? 1 : 0.5; },
                    vLineWidth: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 1 : 0.5; },
                    paddingLeft: function (i, node) { return 4; },
                    paddingRight: function (i, node) { return 4; },
                    paddingTop: function (i, node) { return 2; },
                    paddingBottom: function (i, node) { return 2; },
                }
            },
            {
                columns: [
                    {
                        width: '*',
                        text: [
                            `Payment Date and Time: ${receipt.timestamp ? `${new Date(receipt.timestamp).toLocaleDateString('en-GB')} ${new Date(receipt.timestamp).toLocaleTimeString('en-GB')}` : 'N/A'}`,
                            `\nReceipt No: ${receipt.receiptNumber || 'N/A'}`,
                            `\nPersonal Trainer : ${receipt.personalTrainer ? receipt.personalTrainer : 'No'}`
                        ],
                        margin: [0, 0, 10, 0]
                    },
                    {
                        width: 'auto',
                        text: [
                            `Total: ₹${receipt.amount / 100 || '0'}\n`,
                            `Paid: ₹${receipt.amount / 100 || '0'}`
                        ],
                        alignment: 'right'
                    }
                ]
            },
            {
                text: `Total Amount in Words: Rupees ${totalAmountInWords} Only`,
                alignment: 'right',
                margin: [0, 10, 0, 10]
            },
            {
                text: `${gymData.gymDisplayName || 'Gym Name'} Terms & Conditions:`,
                bold: true,
                margin: [0, 20, 0, 5]
            },
            {
                ul: [
                    'Membership rates can be revised by the management.',
                    'No membership is refundable.'
                ]
            }
        ],
        styles: {
            tableHeader: {
                bold: true,
                fontSize: 12,
                color: 'black'
            },
            tableBody: {
                fontSize: 10
            }
        }
    };
};

/**
 * Generates and opens the PDF.
 * 
 * @param {Object} receipt - The receipt details.
 * @param {Object} gymData - The gym details.
 */
const generatePdf = (receipt, gymData) => {
    console.log("GymData",gymData);
    const docDefinition = createDocDefinition(receipt, gymData);
    const generatedPDF = pdfMake.createPdf(docDefinition);
    generatedPDF.open();
    return generatedPDF;
};

/**
 * ReceiptDetailsModal Component
 * 
 * @param {Object} props - The component props.
 * @param {Object} props.receipt - The receipt details.
 * @param {Function} props.onClose - Function to close the modal.
 * @param {Object} props.gymData - The gym details.
 * @returns {JSX.Element} - The rendered component.
 */
const ReceiptDetailsModal = ({ receipt, onClose, gymData }) => {
    const [numberToSendOn, setNumberToSendOn] = useState("");

    const totalAmount = receipt.amount / 100 || 0;
    const totalAmountInWords = getTotalAmountInWords(totalAmount);

    let netPrice = totalAmount / 1.18;
    let cgst = 0.09 * netPrice;
    let sgst = 0.09 * netPrice;
    sgst = parseFloat(sgst.toFixed(2));
    cgst = parseFloat(sgst.toFixed(2));
    netPrice = parseFloat(netPrice.toFixed(2));

    /**
     * Handles sending the receipt via WhatsApp by generating the PDF and providing it to the user.
     * Since WhatsApp Web doesn't support direct file attachments via URL, the user must download and then share the file manually.
     */
    const handleSendToPhone = () => {
        const docDefinition = createDocDefinition(receipt, gymData);
        const pdfDocGenerator = pdfMake.createPdf(docDefinition);

        pdfDocGenerator.getBlob((blob) => {
            // Create a blob URL to allow downloading
            const url = URL.createObjectURL(blob);

            // Open the blob URL for the user to download the file
            const link = document.createElement('a');
            link.href = url;
            link.download = `${receipt.paymentID}.pdf`;
            link.click();

            // Display a message to the user to send this downloaded file via WhatsApp manually
            alert('Please download the PDF and send it manually through WhatsApp.');
        });
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center px-40">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">Receipt Details</h2>
                <button onClick={onClose} className="text-lg font-bold">
                    ✖
                </button>
            </div>
            <div id="receipt-content">
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-8 rounded-lg shadow-lg w-2/3">
                        <div className="flex justify-between items-start mb-4">
                            <div>
                                <p>
                                    <strong>Name:</strong> {receipt.userName || 'N/A'}
                                </p>
                                <p>
                                    <strong>Phone:</strong> {receipt.phoneNumber || 'N/A'}
                                </p>
                            </div>
                            <div>
                                <p>
                                    <strong>Transaction No:</strong> {receipt.paymentID || 'N/A'}
                                </p>
                                <p>
                                    <strong>Receipt Date:</strong>{" "}
                                    {receipt.timestamp ? new Date(receipt.timestamp).toLocaleDateString('en-GB') : 'N/A'}
                                </p>
                                <p>
                                    <strong>Receipt Time:</strong>{" "}
                                    {receipt.timestamp ? new Date(receipt.timestamp).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }) : 'N/A'}

                                </p>
                                <p>
                                    <strong>Payment Status:</strong> Paid
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-b py-4 mb-4">
                            <table className="w-full">
                                <thead>
                                    <tr>
                                        <th className="text-left">Qty</th>
                                        <th className="text-right">Start Date</th>
                                        <th className="text-right">End Date</th>
                                        <th className="text-right">Net Amount</th>
                                        <th className="text-right">CGST (9%)</th>
                                        <th className="text-right">SGST (9%)</th>
                                        <th className="text-right">Total Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{receipt.months || '0'} MONTHS</td>
                                        <td className="text-right">{receipt.startDate ? receipt.startDate : 'N/A'}</td>
                                        <td className="text-right">{receipt.endDate ? receipt.endDate : 'N/A'}</td>
                                        <td className="text-right">₹{netPrice || '0'}</td>
                                        <td className="text-right">₹{cgst || '0'}</td>
                                        <td className="text-right">₹{sgst || '0'}</td>
                                        <td className="text-right">₹{totalAmount || '0'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="text-right mb-4">
                            <p>
                                Total Amount in Words:{" "}
                                <strong>Rupees {getTotalAmountInWords(receipt.amount / 100 || 0)} Only</strong>
                            </p>
                        </div>
                        <div className="flex justify-between items-center border-t pt-4">
                            <div>
                                <p>
                                    <strong>Payment Date:</strong> {new Date(receipt.timestamp).toLocaleDateString() || 'N/A'}
                                </p>
                                <p>
                                    <strong>Receipt No:</strong> {receipt.receiptNumber || 'N/A'}
                                </p>
                                <p>
                                    <strong>Personal Trainer:</strong> {receipt.personalTrainer || 'No'}
                                </p>
                            </div>
                            <div className="text-right">
                                <p>Total: ₹{receipt.amount / 100 || '0'}</p>
                                <p>Paid: ₹{receipt.amount / 100 || '0'}</p>
                            </div>
                        </div>
                        <div className="text-center mt-4">
                            <p>
                                <strong>{gymData.gymDisplayName || 'Gym Name'} Terms & Conditions:</strong>
                            </p>
                            <ul>
                                <li>Membership rates can be revised by the management.</li>
                                <li>No membership is refundable.</li>
                            </ul>
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-3">
                                <div>Send to Phone:</div>
                                <input
                                    type="email"
                                    onChange={(e) => setNumberToSendOn(e.target.value)}
                                    value={numberToSendOn}
                                    className="border border-secondary rounded px-2 py-1"
                                />
                                <button onClick={() => testSend(numberToSendOn)} className="bg-primary text-white py-2 px-4 rounded">
                                    Send
                                </button>
                            </div>
                            <div className="mt-4 flex justify-end">
                                <button
                                    onClick={() => generatePdf(receipt, gymData)}
                                    className="bg-primary text-white py-2 px-4 rounded"
                                >
                                    Print
                                </button>
                                <button
                                    onClick={onClose}
                                    className="bg-secondary hover:bg-primary text-white py-2 px-4 rounded ml-2"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-4 flex justify-end">
                <button
                    onClick={onClose}
                    className="bg-primary text-white py-2 px-4 rounded"
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default ReceiptDetailsModal;


/**
 * Temp function
 */
const checkSend = async () => {
    const url = '/v1/account'; // Replace with the actual Facebook API endpoint
    const data = {
        cc              : "+91",                          // Your country code
        phone_number    : "7827145068", // Your phone number without the country code
        method          : "sms",                               // Can be 'sms' or 'voice'
        cert            : cert,        // The verified name certificate in base64
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer YOUR_ACCESS_TOKEN' // Use the actual access token from Facebook
            },
            body: JSON.stringify(data) // Convert data object to JSON string
        });

        if (response.ok) {
            const result = await response.json();
            console.log('WhatsApp service started:', result);
        } else {
            console.error('Error:', response.statusText);
        }
    } catch (error) {
        console.error('Request failed:', error);
    }
};

const testSend = async(numberToSendOn) => {
    await handleSendPersonalizedMessage('email', numberToSendOn, 'gymCred')
}

const cert = "CmYKIgjr+8bLoKelAhIGZW50OndhIglUcmFpbiBSZXhQn5SfuAYaQMeFgj8Hd1KOoe1c2iGCDyIGDq0XnBQF4kA3RgjL6oJjhrnEs/ORhnJyWjJ2LoGTCq+sXEmy/5rLr2ntVW9zbAESLm1cW9mtn4BV4ESHs5+kby6UXuHlWs3YgtJTOU6tPFv72Ksar9GvEcbrnwTENNU=";