/**
 * StaffButtonsHelpers.js
 * 
 * This file contains the helper functions to add / subtract staff & more
 */

import { addDoc, collection, doc, getDocs, query, updateDoc, where } from "@firebase/firestore";
import { db } from "../../../config/firebase";
import { cityList, gymList, staffList } from "../../../components/Databases";
import { amountChecker, emailChecker, phoneNumberChecker, positionChecker, staffNameChecker, userNameChecker } from "../../ContraintsChecker";

/**
 * Checks if Staff Member exists in the TrainRex Database
 * @param {String} staffID      -   Email/PhoneNumber of the staff Member
 * @param {String} checkThrough -   the field to check the staffID by (default : email)
 */
export const checkIfStaffExistsInTrainRexDatabase = async (staffID, checkThrough = 'email') => {
    let staffInfo = {
        staffExists :   false,
        staffName   :   '',
        staffGender :   '',
        phoneNumber :   '',
        staffID     :   '',
    }
    try {
        const userRef = collection(db, 'user');
        const userQuery = query(userRef, where(checkThrough, '==', staffID))
        const userSnapshot = await getDocs(userQuery)

        if (!userSnapshot.empty) {
            const userDoc = userSnapshot.docs[0];
            const userData = userDoc.data();
            staffInfo.staffExists   =   true                        // staff exists
            staffInfo.staffName     =   userData.name;              // name
            staffInfo.staffID       =   userDoc.id;                 // userID
            staffInfo.phoneNumber   =   userData.phoneNumber;       // phoneNumber
            staffInfo.staffGender   =   userData.gender;            // gender
        }

    } catch (error) {
        console.error('Error in Checking for Staff Member', error);
    }

    return staffInfo; // Return the data
}

/**
 * Checks if staff already exists in the gym Database
 * @param {String} staffID      -   Email/PhoneNumber of the staff Member
 * @param {Object} gymData      -   gym information
 * @param {String} checkThrough -   the field to check the staffID by (default : email)
 * @returns {Boolean}           -   true, if staff already exists; false otherwise
 */
export const checkIfStaffExistsinGymDatabase = async (staffID, gymData, checkThrough = 'email') => {
    try {
        const userRef = collection(db, cityList, gymData.city, gymList, gymData.gymName, staffList);
        const userQuery = query(userRef, where(checkThrough, '==', staffID))
        const userSnapshot = await getDocs(userQuery)
        console.log("Mil gya")

        if (userSnapshot.empty) {
            console.log("Nhi mila");
            return false;
        }

    } catch (error) {
        console.error('Error in Checking for Staff Member', error);
    }
    return true;

}

/**
 * Checks for the user constraints
 * @param {Object} staffData    -   Staff member form details
 * @returns {Boolean}           -   true, if all checks are passed; false, otherwise
 */
export const checkForConstraintsForStaff = (staffData) => {
    return (
        phoneNumberChecker(staffData.phoneNumber)       &&      // PhoneNumber
        userNameChecker(staffData.staffName)            &&      // Name
        amountChecker(staffData.salary)                 &&      // Salary
        emailChecker(staffData.email)                   &&      // Email
        positionChecker(staffData.position)             &&      // Position
        true
    )
}

/**
 * Adds the staff member to gym's database
 * @param {Object} staffData    -   Staff member form details
 * @param {Object} gymData      -   gym information
 * @returns {Boolean}           -   true, if storage successful; false, otherwise
 */
export const addStaffMember = async (staffData, gymData) => {
    try {
        const gymStaffRef   =   collection(db, cityList, gymData.city, gymList, gymData.gymName, staffList)
        await addDoc(gymStaffRef, staffData);

        // Also change the userType of the trainer in db
        changeUserTypeOfStaff(staffData.staffID, gymData.gymName);

        return true;

    } catch (error) {
        alert('Error in adding staff member. Please Try Again!');
        console.log('Error in adding staff : ', error);
        return false;
    }
    
}

/**
 * Changes the userType of the staff Member
 * @param {String} staffID  -   userID of the staff member
 * @param {String} gymName  -   Name of the gym to update in db
 * @returns {Boolean}       -   true, if updation successful; false, otherwise
 */
const changeUserTypeOfStaff = async (staffID, gymName) => {
    try {
        // Create a reference to the user's document in the 'user' collection
        const userDocRef = doc(db, 'user', staffID);

        // Update the 'userType' field to 'staff'
        await updateDoc(userDocRef, {
            userType    :   'staff',
            gymName     :   gymName,
        });
        return true;
    } catch (error) {
        console.log("Error updating userType to 'staff':", error);
        return false;
    }
};