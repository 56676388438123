import React from 'react';
import "../../../CustomCss.css";
import { parseDate } from '../../basicHelper';

/**
 * Component to display the top 5 members whose membership is expiring soon
 * @param {Array} memberData - Array of member objects
 * @param {Function} onShowAllMembers - Function to handle "Show All" button click to display all members
 */
const ExpiringMemberSnippet = ({ memberData, onShowAllMembers }) => {
    // Function to determine if membership is expiring within 7 days
    const isExpiringWithinWeek = (membershipTill) => {
        const membershipTillDate = parseDate(membershipTill);
        const today = new Date();
        const nextWeek = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000); // 7 days from today
        return membershipTillDate > today && membershipTillDate <= nextWeek;
    };

    // Filter out expired memberships and sort members by the soonest membershipTill date
    const validMembers = [...memberData]
        .filter(member => parseDate(member.membershipTill) > new Date()) // Only include members whose membership is still valid
        .sort((a, b) => parseDate(a.membershipTill) - parseDate(b.membershipTill)); // Sort by soonest membershipTill

    // Get members expiring within the next week
    const expiringSoonMembers = validMembers.filter(member => isExpiringWithinWeek(member.membershipTill));

    // If we have fewer than 5 expiring members, fill with the next soonest members
    let topExpiringMembers = expiringSoonMembers.slice(0, 5);
    if (topExpiringMembers.length < 5) {
        const remainingSlots = 5 - topExpiringMembers.length;
        const additionalMembers = validMembers.slice(expiringSoonMembers.length, expiringSoonMembers.length + remainingSlots);
        topExpiringMembers = [...topExpiringMembers, ...additionalMembers];
    }
    
    return (
        <div className="flex flex-col w-full">
            <div className="flex flex-col">
                <p className="text-lg text-secondary font-bold text-left pb-2 px-2">Client List</p>
                <div className="flex flex-col pb-4 rounded-2xl border-secondary border-2 py-2 px-2">
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-tertiary">
                            <thead>
                                <tr>
                                    <th className="py-2 px-2 sm:px-4 border-b-2 border-secondary text-center text-secondary text-xs sm:text-base md:text-xl">Name</th>
                                    <th className="py-2 px-2 sm:px-4 border-b-2 border-secondary text-center text-secondary text-xs sm:text-base md:text-xl">Phone Number</th>
                                    <th className="py-2 px-2 sm:px-4 border-b-2 border-secondary text-center text-secondary text-xs sm:text-base md:text-xl">Membership Till</th>
                                </tr>
                            </thead>
                            <tbody>
                                {topExpiringMembers.map((member, index) => {
                                    const isWithinWeek = isExpiringWithinWeek(member.membershipTill);
                                    const rowClassName = index % 2 === 0 ? 'bg-secondary' : 'bg-tertiary';
                                    const textClassName = isWithinWeek ? 'text-highlightRed font-bold' : '';

                                    return (
                                        <tr key={index} className={`${rowClassName} ${textClassName}`}>
                                            <td className="py-2 px-2 sm:px-4 border-b text-center text-xs font-semibold sm:text-sm md:text-base">{member.userName}</td>
                                            <td className="py-2 px-2 sm:px-4 border-b text-center text-xs font-semibold sm:text-sm md:text-base">{member.phoneNumber}</td>
                                            <td className="py-2 px-2 sm:px-4 border-b text-center text-xs font-semibold sm:text-sm md:text-base">{member.membershipTill}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="h-0.5 bg-secondary rounded-full mt-2"></div>
                    <div className="mt-2 flex justify-center">
                        <button className="button-cover-rect text-xs sm:text-sm md:text-lg font-semibold text-secondary hover:underline" role="button" onClick={onShowAllMembers}>
                            <span>See More</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExpiringMemberSnippet;
