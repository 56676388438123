// src/helperFunctions/DashboardScenesHelpers/AddNewClass.js
import React, { useEffect, useRef, useState } from 'react';
import { db, dbGym } from '../../../config/firebase';
import { addDoc, collection, doc, getDocs, query, updateDoc, where } from "@firebase/firestore";
import { cityList, classList, gymList } from '../../../components/Databases';

export const ShowList = ({ id, onClose }) => {
    const modalRef = useRef();
    const [interestedUsers, setInterestedUsers] = useState(null);
    const [newUser, setNewUser] = useState({ name: '', phoneNumber: '', isInterested: true, hasBooked: false, hasAttended: false });

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose();
        }
    };

    const fetchUserList = async (id) => {
        const usersSnapshot = await getDocs(collection(dbGym, 'Classes', id, 'Users'));
        const userList = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setInterestedUsers(userList);
    };

    useEffect(() => {
        fetchUserList(id);

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [id]);

    const handleStatusChange = async (idUser, value, variable) => {
        const userRef = doc(dbGym, 'Classes', id, 'Users', idUser);
        await updateDoc(userRef, { [variable]: value === 'Yes' });
        fetchUserList(id);
    };

    const handleAddUser = async () => {
        await addDoc(collection(dbGym, 'Classes', id, 'Users'), newUser);
        setNewUser({ name: '', phoneNumber: '', isInterested: true, hasBooked: false, hasAttended: false });
        fetchUserList(id);
    };

    if (interestedUsers === null) {
        return null; // or a loading indicator
    }

    return (
        <div id="List" className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50 p-4">
            <div ref={modalRef} className="bg-lightTertiary w-full sm:w-3/4 lg:w-1/2 max-h-full overflow-y-auto rounded-3xl p-6 sm:p-8 shadow-lg">
                <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold mb-4 sm:mb-6 text-primary">Interested Users</h2>
                <ul className="space-y-4 sm:space-y-6">
                    {interestedUsers.map(user => (
                        <li key={user.id} className="border-b border-gray-200 pb-4">
                            <div className="flex flex-col space-y-2">
                                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                                    <p className="text-lg font-medium">{user.name}</p>
                                    <p className="text-gray-600">{user.phoneNumber}</p>
                                </div>
                                <div className="flex flex-col sm:flex-row sm:space-x-6 space-y-2 sm:space-y-0">
                                    <div className="flex items-center">
                                        <label className="mr-2">Interested:</label>
                                        <select
                                            value={user.isInterested ? 'Yes' : 'No'}
                                            onChange={(e) => handleStatusChange(user.id, e.target.value, 'isInterested')}
                                            className="bg-gray-100 border border-gray-300 rounded-lg py-1 px-2"
                                        >
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    <div className="flex items-center">
                                        <label className="mr-2">Booked:</label>
                                        <select
                                            value={user.hasBooked ? 'Yes' : 'No'}
                                            onChange={(e) => handleStatusChange(user.id, e.target.value, 'hasBooked')}
                                            className="bg-gray-100 border border-gray-300 rounded-lg py-1 px-2"
                                        >
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    <div className="flex items-center">
                                        <label className="mr-2">Attended:</label>
                                        <select
                                            value={user.hasAttended ? 'Yes' : 'No'}
                                            onChange={(e) => handleStatusChange(user.id, e.target.value, 'hasAttended')}
                                            className="bg-gray-100 border border-gray-300 rounded-lg py-1 px-2"
                                        >
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                <h2 className="text-xl sm:text-2xl lg:text-3xl font-semibold mt-8 mb-4 text-primary">Add New User</h2>
                <div className="space-y-4">
                    <input
                        type="text"
                        placeholder="Name"
                        value={newUser.name}
                        onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
                        className="w-full p-2 sm:p-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
                    />
                    <input
                        type="text"
                        placeholder="Phone Number"
                        value={newUser.phoneNumber}
                        onChange={(e) => setNewUser({ ...newUser, phoneNumber: e.target.value })}
                        className="w-full p-2 sm:p-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
                    />
                    <button onClick={handleAddUser} className="w-full bg-secondary text-white py-2 sm:py-3 rounded-lg shadow hover:bg-secondary-dark transition">
                        Add User
                    </button>
                </div>
                <button type="button" onClick={onClose} className="mt-4 sm:mt-6 w-full bg-secondary text-white py-2 sm:py-3 rounded-lg shadow hover:bg-red-700 transition">
                    Close
                </button>
            </div>
        </div>
    );
}    



// Helper function to handle outside clicks for closing the modal
export const useOutsideClick = (ref, callback) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback]);
};

// Days of the week options
export const daysOfWeek = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
];

// Function to update the class days selection
export const updateDaysSelection = (days, day) => {
    return days.includes(day)
        ? days.filter(d => d !== day)
        : [...days, day];
};

/**
 * Adds a new class to the database if it does not already exist.
 * @param {Object} gymData - Information about the gym.
 * @param {Object} newClass - Details of the new class to be added.
 * @returns {Promise<boolean>} - Returns true if the class is added successfully, false otherwise.
 */
export const addClassToDataBase = async (gymData, newClass) => {
    try {
        // Reference to the classes collection in Firestore
        const classRef = collection(db, cityList, gymData.city, gymList, gymData.gymName, classList);

        // Query to check if a class with the same classID already exists
        const q = query(classRef, where("classID", "==", newClass.classID));
        const querySnapshot = await getDocs(q);

        // If a document with the same classID already exists, return true
        // True; because the modal closes
        if (!querySnapshot.empty) {
            alert('Class already exists!');
            return true;
        }

        // Add the new class to the collection
        await addDoc(classRef, newClass);
        console.log('Class added successfully.');
        return true;

    } catch (error) {
        console.error('Error in Adding Class To Database:', error);
        return false;
    }
};