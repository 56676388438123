import React, { useState, useEffect } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from 'recharts';
import { fetchCityWiseData } from "../../../helperFunctions/DashboardScenesHelpers/DashboardHelper";
import { themeColors } from "../../../theme";

const CityWiseDataTab = () => {
    const [cityWiseData, setCityWiseData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const cityData = await fetchCityWiseData();
                setCityWiseData(cityData);
                setLoading(false);
                console.log("City-wise data:", cityData);
            } catch (error) {
                console.error("Error fetching city-wise data:", error);
                setError(error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <div className="flex justify-center items-center h-screen">Loading...</div>;
    }

    if (error) {
        return <div className="flex justify-center items-center h-screen text-black">Error: {error.message}</div>;
    }

    return (
        <div className="flex flex-col bg-tertiary pt-12 lg:pt-24 px-6 lg:px-0"> {/* Removed lg:px-56 for full-width on large screens */}
            <div className="flex flex-col lg:flex-row justify-between w-full text-2xl font-semibold mb-4">
                <div className="w-full"> {/* Ensure the chart container spans full width */}
                    <h2 className="text-lg lg:text-2xl font-semibold mb-4">City-wise Attendance</h2>
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart data={cityWiseData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="city" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="users" fill={themeColors.fg} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
};

export default CityWiseDataTab;
