import React, { useEffect, useState } from "react";
import GymSignIn from "./DashBoardScenes/GymSignIn";
import DashboardScenesController from "./DashBoardScenes/DashboardScenesController";
import { onAuthStateChanged } from "firebase/auth";
import { authGym } from "../../config/firebase";

const GymsDashboard = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const [user, setUser] = useState(null); // State to store user information

  // Listener to handle authentication state changesp
  useEffect(() => {
    const authStateChangeListener = onAuthStateChanged(authGym, (user) => {
      if (user) {
        setUser(user);
        setIsLoggedIn(true);
      } else {
        setUser(null);
        setIsLoggedIn(false);
      }
    });

    // Cleanup the listener on component unmount
    return () => authStateChangeListener();
  }, []);

  /**
   * On Successful Sign In, this recovers user from GymSignIn
   * @param {user} userData
   */
  const handleSignIn = async (userData) => {
    setUser(userData);
    setIsLoggedIn(true);
  };

  return (
    <div className="flex flex-col min-h-screen bg-secondary py-8 justify-center">
      {!isLoggedIn ? (
        <GymSignIn onSignIn={handleSignIn} />
      ) : (
        <DashboardScenesController user={user} />
      )}
    </div>
  );
};

export default GymsDashboard;
