import React, { useEffect, useState } from 'react';
import RingGraph from '../../../components/RingGraph';
import { graphColors, themeColors } from '../../../theme';
import { getRevenueData } from './FetchFinanceData';

/**
 * RevenueGraph Component - Fetches revenue data and renders a RingGraph component.
 * @param {Object} gymData - Has gym Data
 * @returns {JSX.Element} - A div containing the RingGraph component with the fetched revenue data.
 */
const RevenueGraph = ({gymData}) => {
    const [revenueData, setRevenueData] = useState([]);
    const strokeColor = themeColors.lightAccent;
    const strokeWidth = 4;
    const [legendLayout, setLegendLayout] = useState('vertical'); // Responsive legend layout
    const wantCenterLabel = true;

    // Responsive dimensions for the chart
    const chartWidth = '100%';
    const chartHeight = '100%';
    const innerRadius = '50%';
    const outerRadius = '80%';

    useEffect(() => {
        const fetchData = async () => {
            const data = await getRevenueData(gymData);
            setRevenueData(data);
        };

        // Adjust legend layout based on screen size
        const updateLayout = () => {
            if (window.innerWidth < 600) {
                setLegendLayout('horizontal');
            } else {
                setLegendLayout('vertical');
            }
        };

        fetchData();
        updateLayout();
        window.addEventListener('resize', updateLayout);

        return () => window.removeEventListener('resize', updateLayout);
    }, []);

    return (
        <div 
            className="flex flex-col rounded-3xl border-secondary border-2 p-4 justify-between"
            style={{ width: '100%', maxWidth: '600px' }}
        >
            <p className="text-xl text-secondary font-bold text-center">Revenue Graph</p>
            <div style={{ width: '120%', height: '300px' }}>
                <RingGraph 
                    data={revenueData} 
                    colors={graphColors} 
                    innerRadius={innerRadius} 
                    outerRadius={outerRadius} 
                    strokeColor={strokeColor} 
                    strokeWidth={strokeWidth}
                    legendLayout={legendLayout}
                    wantCenterLabel={wantCenterLabel}
                    width={chartWidth} 
                    height={chartHeight}
                />
            </div>
        </div>
    );
};

export default RevenueGraph;
