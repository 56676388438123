import React, { useEffect, useState } from 'react';
import RingGraph from '../../../components/RingGraph';
import { graphColors, themeColors } from '../../../theme';
import { getExpenseData } from './FetchFinanceData';

/**
 * ExpensesGraph Component - Fetches expenses data and renders a RingGraph component.
 * @param {Object} gymData - Contains the gym Data
 * @returns {JSX.Element} - A div containing the RingGraph component with the fetched expenses data.
 */
const ExpensesGraph = ({gymData}) => {
    const [expensesData, setExpensesData] = useState([]);
    const strokeColor = themeColors.lightAccent;
    const strokeWidth = 4;
    const [legendLayout, setLegendLayout] = useState('horizontal'); // Responsive legend layout
    const centerLabel = false;

    // Responsive dimensions for the chart
    const chartWidth = '100%';
    const chartHeight = '100%';
    const innerRadius = '50%';
    const outerRadius = '80%';

    useEffect(() => {
        const fetchData = async () => {
            const data = await getExpenseData(gymData);
            setExpensesData(data);
        };

        // Adjust legend layout based on screen size
        const updateLayout = () => {
            if (window.innerWidth < 600) {
                setLegendLayout('vertical');
            } else {
                setLegendLayout('horizontal');
            }
        };

        fetchData();
        updateLayout();
        window.addEventListener('resize', updateLayout);

        return () => window.removeEventListener('resize', updateLayout);
    }, []);

    return (
        <div 
            className="flex flex-col rounded-3xl border-secondary border-2 py-2 justify-between"
            style={{ width: '100%', maxWidth: '400px'}}
        >
            <p className="text-xl text-secondary font-bold text-center">Expenses Graph</p>
            <div style={{ width: '100%', height: '300px' }}>
                <RingGraph 
                    data={expensesData} 
                    colors={graphColors} 
                    innerRadius={innerRadius} 
                    outerRadius={outerRadius} 
                    strokeColor={strokeColor} 
                    strokeWidth={strokeWidth}
                    legendLayout={legendLayout}
                    wantCenterLabel={centerLabel}
                    width={chartWidth} 
                    height={chartHeight}
                />
                </div>
        </div>
    );
};

export default ExpensesGraph;
