import React from 'react';

/**
 * FourButtons - Renders a set of four square buttons with specified actions and styles.
 *
 * @param {function} toggleAddRevenue - Function to toggle the Add Revenue screen.
 * @param {function} toggleAddExpense - Function to toggle the Add Expense screen.
 * @param {function} setViewHistory - Function to set the view history state.
 * @param {function} setViewExpenseHistory - Function to set the view Expense history state.
 * @returns {JSX.Element} - A component with four styled buttons.
 */

// Random names added in props to avoid error for styling
const StaffButtons = ({ AddStaff, scheduleShift, generateReport, salaries }) => {
    return (
        <div className="flex flex-col md:flex-row lg:flex-col gap-2 md:gap-8 min-w-[84vw] md:min-w-[96vw] lg:min-w-[25vw] max-h-full lg:max-h-96">
            <div className='flex gap-4'>
                <button 
                    className="square-button text-base lg:text-xl w-full md:w-36 lg:w-[16vw] lg:h-56 bg-white p-8 sm:p-12 h-32 sm:h-40 text-left flex flex-col justify-center items-start" 
                    role="button" 
                    onClick={AddStaff}
                >
                    <span className="block">Add New<br />Staff</span>
                    <span className="hidden sm:block"> &#9664; Add New<br/>Staff </span>
                </button>


                <button
                    className="square-button text-base lg:text-xl w-full md:w-36 lg:w-[16vw] lg:h-56 bg-secondary text-tertiary p-8 sm:p-12 h-32 sm:h-40 text-left flex flex-col justify-center items-start"
                    role="button"
                    onClick={scheduleShift}
                >
                    <span className="block">Schedule<br />Shift</span>
                    <span className="hidden sm:block"> &#9650; Coming <br/>Soon</span>
                </button>
            </div>

            <div className='flex gap-4'>
                <button
                    className="square-button text-base lg:text-xl w-full md:w-36 lg:w-[16vw] lg:h-56 bg-primary text-tertiary p-8 sm:p-12 h-32 sm:h-40 text-left flex flex-col justify-center items-start"
                    role="button"
                    onClick={generateReport}
                >
                    <span className="block">Generate<br />Report</span>
                    <span className="hidden sm:block"> &#9660; Coming <br/>Soon</span>
                </button>


                <button 
                    className="square-button text-base lg:text-xl w-full md:w-36 lg:w-[16vw] lg:h-56 bg-bg text-secondary p-8 sm:p-12 h-32 sm:h-40 text-left flex flex-col justify-center items-start" 
                    role="button" 
                    onClick={salaries}
                >
                    <span className="block">Salaries<br /></span>
                    <span className="hidden sm:block"> &#9654; Coming <br/>Soon</span>
                </button>
            </div>
        </div>
    );
};

export default StaffButtons;