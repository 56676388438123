import React from 'react';
import Modal from 'react-modal';
import { MdAccessTime, MdPerson, MdCalendarToday, MdAttachMoney } from 'react-icons/md';

/**
 * Modal to display detailed information about a class.
 * @param {Object} props - The component props.
 * @param {Object} props.classDetails - The details of the selected class.
 * @param {Function} props.onClose - Function to close the modal.
 * @returns {JSX.Element} The rendered modal component.
 */
const DisplayClassModal = ({ classDetails, onClose }) => {
    if (!classDetails) return null; // Return null if no classDetails are provided

    // Convert classSchedule object to a readable string
    const scheduleEntries = Object.entries(classDetails.classSchedule).map(([day, time]) => (
        <div key={day} className="flex justify-between items-center">
            <span className="text-black">{day}</span>
            <span className="text-tertiary">{time}</span>
        </div>
    ));

    return (
        <Modal
            isOpen={!!classDetails} // Open modal only if classDetails are provided
            onRequestClose={onClose}
            contentLabel="Class Details"
            className="fixed inset-0 flex items-center justify-center z-50"
            overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-70"
            closeTimeoutMS={300} // Animation duration for closing
        >
            <div className="bg-secondary rounded-lg shadow-lg p-8 w-full max-w-3xl relative flex flex-col space-y-6">
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 text-tertiary hover:text-primary transition text-3xl"
                >
                    &times;
                </button>
                <h2 className="text-3xl font-semibold text-primary text-center mb-6">{classDetails.className}</h2>
                
                <div className="flex flex-col space-y-6">
                    <section className="flex flex-col space-y-4 border-t border-gray-300 pt-4">
                        <div className="flex items-center space-x-2">
                            <MdPerson className="text-primary text-xl" />
                            <span className="font-semibold text-black text-lg">Instructor</span>
                            <span className="text-tertiary">{classDetails.trainer}</span>
                        </div>
                    </section>

                    <section className="flex flex-col space-y-4 border-t border-gray-300 pt-4">
                        <div className="flex flex-col space-y-2">
                                <span className="font-semibold text-black text-lg">Class Schedule</span>
                                <div className="flex flex-col space-y-2 ml-4">
                                    {scheduleEntries}
                                </div>
                        </div>
                    </section>
                    
                    <section className="flex flex-col space-y-4 border-t border-gray-300 pt-4">
                        <div className="flex items-center space-x-2">
                            <MdAccessTime className="text-primary text-xl" />
                            <span className="font-semibold text-black text-lg">Total Slots</span>
                            <span className="text-tertiary">{classDetails.availableSlots}</span>
                        </div>
                        <div className="flex items-center space-x-2">
                            <MdCalendarToday className="text-primary text-xl" />
                            <span className="font-semibold text-black text-lg">Active</span>
                            <span className="text-tertiary">{classDetails.isActive ? 'Yes' : 'No'}</span>
                        </div>
                    </section>
                    
                    <section className="flex flex-col space-y-4 border-t border-gray-300 pt-4">
                        <div className="flex items-center space-x-2">
                            <MdAttachMoney className="text-primary text-xl" />
                            <span className="font-semibold text-black text-lg">Price for Members</span>
                            <span className="text-tertiary">Rs {classDetails.priceForMembers / 100}</span>
                        </div>
                        <div className="flex items-center space-x-2">
                            <MdAttachMoney className="text-primary text-xl" />
                            <span className="font-semibold text-black text-lg">Price for Non-Members</span>
                            <span className="text-tertiary">Rs {classDetails.priceForNonMembers / 100}</span>
                        </div>
                    </section>
                </div>
            </div>
        </Modal>
    );
};

export default DisplayClassModal;
