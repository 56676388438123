import React, { useEffect, useState } from 'react';

const SalesMetrics = ({ leadsData }) => {
    const [data, setData] = useState();

    useEffect(() => {
        setData(leadsData);
    }, [leadsData])

    return (
        <div className="flex flex-col gap-4 w-full lg:w-2/5 justify-between">
        <div className="flex justify-between items-center p-2 md:p-4 bg-[#f0f8e2] text-secondary border-2 border-[#4a8c47] rounded-2xl md:rounded-3xl h-16 md:h-20">
            <p className="text-base lg:text-lg font-bold">Total Leads</p>
            <p className="text-2xl lg:text-3xl font-bold">{data?.totalLeads}</p>
        </div>
        <div className="flex justify-between items-center p-2 md:p-4 bg-secondary text-tertiary border-2 border-[#4a8c47] rounded-2xl md:rounded-3xl h-16 md:h-20">
            <p className="text-base lg:text-lg font-bold">Converted Leads</p>
            <p className="text-2xl lg:text-3xl font-bold">{data?.totalConvertedLeads}</p>
        </div>
        <div className="flex justify-between items-center p-2 md:p-4 bg-primary text-secondary border-2 border-[#4a8c47] rounded-2xl md:rounded-3xl h-16 md:h-20">
            <p className="text-base lg:text-lg font-bold flex items-center">
            Sales Target <span className="ml-2 text-sm">✏️</span>
            </p>

            {/* to add an input box for the gymOwner to put the values of the sales target and remove the current thing below */}
            <p className={`text-2xl lg:text-3xl font-bold`}>{data?.salesTarget ? data?.salesTarget : null}</p> 
        </div>
        <div className="flex justify-between items-center p-2 md:p-4 bg-[#f0f8e2] text-secondary border-2 border-[#4a8c47] rounded-2xl md:rounded-3xl h-16 md:h-20">
            <p className="text-base lg:text-lg font-bold">Monthly Sales</p>
            <p className="text-2xl lg:text-3xl font-bold">{data?.totalConvertedLeads}</p>
        </div>
        </div>
    );
};

export default SalesMetrics;
