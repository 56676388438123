import { collection, doc, getDoc, getDocs, setDoc, updateDoc, query, where, limit } from "@firebase/firestore";
import { db, dbGym } from "../config/firebase"
import { IsCityValid, IfGymDocExists, addCollectionsToGyms } from "./GymFormHelpersExtended";
import { cityList, gymList } from "../components/Databases";
import { useEffect, useState } from "react";
import { getCoordinates } from "./userLocationHelpers";

/**
 * Stores the City of the Gym
 * (Needs to check if the City already exists or not)
 * @param   :   City Name
 * @return  :   bool - true if success; false otherwise
 */
export const storeCity = async (cityName) => {
    try {
        console.log('Storing City : ', cityName);


        // Check if the city is a valid city or not
        // TO BE DONE IN THE FUTURE
        // using Geocoding API
        if(!IsCityValid(cityName)){
            console.log('Invalid City!');
            return false;
        }


        // Database handling
        const cityRef = collection(db, cityList);
        const cityDocRef = doc(cityRef, cityName);
        const cityDoc = await getDoc(cityDocRef);


        // If the City is already listed, return
        if (cityDoc.exists()) {
            console.log('City Exists!');
            return true;
        }
        console.log('City Doesn\'t Exist, adding now!');
        // Else add the City to the database
        await setDoc(cityDocRef, {
            'city'          :   cityName,   // Name of the city
            'NoOfGyms'      :   0,          // Keeps track of the total gyms that ever partnered with us
            'NoOfActiveGyms':   0           // Keeps track of the active gyms in the locality
        });


        return true;




    } catch (error) {
        console.error("Error storing city : ", error);
        return false;
    }
}


/**
 * Stores the Gym Details
 * (Needs to check if the Gym already exists or not)
 * If yes, change the status to true.
 * @param   :   •   City
 *              •   Gym Name
 *              •   Locality
 *              •   Owner's Name
 *              •   Contact Number
 * @return  :   bool - true if success; false otherwise
 */
export const storeGym = async (info, user) => {
    try {
        // Fetch the coordinates using the address
        // const coordinates = await getCoordinates(info.address, info.locality, info.city, info.country);

        // If no coordinates are found, stop the process
        // if (!coordinates) {
        //     throw new Error('Unable to fetch coordinates for the address');
        // }

        const toUpdate = {
            gymID            : user.uid,
            name             : info.gymName,
            gymDisplayName   : info.gymDisplayName,
            codeName         : info.gymName.toLowerCase().replace(/\s+/g, ''),  // This is done to find the same gym if exists
            gymName          : info.gymName.toLowerCase().replace(/\s+/g, ''),  // Same as above for consistency
            address          : info.address,
            owner            : info.owner,
            status           : true,
            locality         : info.locality,
            phoneNumber      : info.phoneNumber,
            gymContactNo     : info.gymContactNo,                               // Gym-specific contact number
            gstin            : info.gstin==='' ? '-' : info.gstin,              // if gstin field is empty then "-" will be stored in the database else the value of the field
            rating           : 0,                                               // Default rating when gym is created
            NoOfReview       : 0,
            SumOfReviews     : 0,
            gymLogo          : info.gymLogo,
            Portfolio        : info.Portfolio || '<url>',                       // Default URL for portfolio
            email            : info.email,
            timeSlots        : info.timeSlots,
            pinCode          : info.pinCode,
            country          : info.country,
            accountName      : info.accountName,
            accountCategory  : info.accountCategory,
            accountNumber    : info.accountNumber,
            accountType      : info.accountType,
            ifscCode         : info.ifscCode,
            paymentModel     : info.paymentModel,
            exclusiveModel   : info.exclusiveModel,                             // True if exclsuive model is selected
            prices           : info.prices,
            cityDisplayName  : info.cityDisplayName,
            city             : info.city,
            facilities       : info.facilities,
            equipmentList    : info.equipmentList,
            classes          : info.classes,

            // Step 3: Add latitude and longitude to the stored data
            // latitude         : '28.647504',
            // longitude        : '77.341502',
        };
        console.log("Adding to database ", toUpdate)


        // Add Gym to Gym Database (trainrex website)
        await addUserToGymDoc(user,info);


        // Database management
        const gymRef    = collection(db, cityList, info.city, gymList);
        const gymDocRef = doc(gymRef, info.gymName);


        // gymName Reference to find the gym with same "codeName"
        const gymQuery = query(gymRef, where('gymName', '==', toUpdate.gymName), limit(1));
        const gymDocs = await getDocs(gymQuery);


        // Check if the gym exists already
        // If yes, change the status to active
        if (!gymDocs.empty) {
            // Get the first document
            const gymDoc = gymDocs.docs[0];
            // console.log('Gym document data:', gymDoc.data());
            return await IfGymDocExists(info.city, gymDoc, gymDocRef, toUpdate);
        }


        // Add Details to the Website database, if gym doesn't exist
        await setDoc (gymDocRef , toUpdate);


        /*
         * Add further collections
         * Reviews  : Keeps Track of the reviews of the gym. Doc have field like    :   • Name of Reviewer
         *                                                                              • Review Date
         *                                                                              • Rating
         *                                                                              • Feedback
         *                                                                              • Suggestions for improvement
         *
         * Users    : Keeps Record of all the users. Users have fields like         :   • User Name
         *                                                                              • Member Since
         *                                                                              • User ID
         *                                                                              • Gym Membership ID
         *                                                                              • Address
         *                                                                              • Identification Number ? (like aadhar)
         *
         * Calendar : Keeps A tab of all the dates. These dates have fields like    :   • Timestamp
         *                                                                              • totalUsersAttendance  -   to keep a track of total users who came
         *                                                                              • Year
         *                                                                              • Month
         *                                                                              • Date
         *                                                  - Further Collections   :   • Attendance (individually see which users came)
         *                                                                              • Workout (this keeps track of what machines have users used on a specific day)
         *                                                                              • Classes (the classes the fitness centre is offereing on that specific date)
         *
         * Finances :   Keeps a tab of all the centre's finances, fields are like   :   • {Years} containing
         *                                                                              • TotalRevenue
         *                                                                              • TotalExpense
         *                                                  - Further Collections   :   • Revenue (Both has month like segregation and contain receits)
         *                                                                              • Expense
         *          
         * Staff    : Keeps Record of all the staff members. Have fields like       :   • Staff Name
         *                                                                              • Staff ID
         *                                                                              • Staff Email
         *                                                                              • Salary
         *                                                                              • Position
         *                                                                              • Identification Number ? (like aadhar)
         * Classes :  Keeps record of all the classes of the Gyms. Fields Like      :   • class Schedule
         *                                                                              • available slots
         *                                                                              • price for members
         *                                                                              • price for non members
         *                                                                              • trainer
         */
        await addCollectionsToGyms(gymDocRef);


        // Update the number of gyms of the city
        const cityRef = collection(db, cityList);
        const cityDocRef = doc(cityRef, info.city);
        const cityDoc = await getDoc(cityDocRef);
        const cityData = cityDoc.data();


        await updateDoc(cityDocRef, {
            'NoOfActiveGyms'    : cityData.NoOfActiveGyms + 1,
            'NoOfGyms'          : cityData.NoOfGyms + 1
        });


        return true;


    } catch (error) {
        console.error("Error storing Gym : ", error);
        return false;
    }
}


const addUserToGymDoc = async(user, info) => {
    try {
        // console.log('Adding Gym to Firestore');
        const userRef = doc(dbGym, cityList, info.city, gymList, user.uid);
        await setDoc(userRef, info);


        // Adding to the the website firebase
        const gymRef = doc(dbGym, gymList, user.uid);
        await setDoc(gymRef, info);
        return true;
    } catch (error) {
        console.error("Error adding user to Firestore", error);
        return false;
    }
};


export const getDefaultFormDataState = (user) => {
    return {
        city                : '',
        gym                 : '',
        locality            : '',
        owner               : '',
        phoneNumber         : user.phoneNumber,
        gymContactNo        : '',
        address             : '',
        openingTime         : '',
        closingTime         : '',
        timeSlots           : {
            Monday      : [{ openingTime: '', closingTime: '' }],
            Tuesday     : [{ openingTime: '', closingTime: '' }],
            Wednesday   : [{ openingTime: '', closingTime: '' }],
            Thursday    : [{ openingTime: '', closingTime: '' }],
            Friday      : [{ openingTime: '', closingTime: '' }],
            Saturday    : [{ openingTime: '', closingTime: '' }],
            Sunday      : [{ openingTime: '', closingTime: '' }],
        },
        gymLogo             : '',
        Portfolio           : [],
        email               : user.email,
        gstin               : '',
        country             : '',
        pinCode             : '',

        //Bank Details of gym owner
        accountName         : '',
        accountCategory     : '',
        accountNumber       : '',
        accountType         : '',
        ifscCode            : '',
        
        // Pricing Structure (Single, Couple, Family)
        paymentModel        : '',          // if Exclusive is selected then it is true else it is false
        exclusiveModel      : false,          // if Exclusive is selected then it is true else it is false
        prices              : {
            single              : {
                dailyPrice              : '',
                monthlyPrice            : '',
                quarterlyPrice          : '',
                halfYearlyPrice         : '',
                yearlyPrice             : '',

                exclusiveDailyPrice     : '',  
                exclusiveMonthlyPrice   : '',
                exclusiveQuarterlyPrice : '',
                exclusiveHalfYearlyPrice: '',
                exclusiveYearlyPrice    : '',   
            },
            couple              : {
                dailyPrice              : '',
                monthlyPrice            : '',
                quarterlyPrice          : '',
                halfYearlyPrice         : '',
                yearlyPrice             : '',

                exclusiveDailyPrice     : '',  
                exclusiveMonthlyPrice   : '',
                exclusiveQuarterlyPrice : '',
                exclusiveHalfYearlyPrice: '',
                exclusiveYearlyPrice    : '',   
            }, 
            family              : {
                dailyPrice              : '',
                monthlyPrice            : '',
                quarterlyPrice          : '',
                halfYearlyPrice         : '',
                yearlyPrice             : '',

                exclusiveDailyPrice     : '',  
                exclusiveMonthlyPrice   : '',
                exclusiveQuarterlyPrice : '',
                exclusiveHalfYearlyPrice: '',
                exclusiveYearlyPrice    : '',   
            },
        },

        // Equipment List
        equipmentList       : {
            AbductionAndAdductionMachine    : false,    
            AdductorMachine                 : false,
            ArmWrestlingPlatform            : false,
            BackrollMachine                 : false,
            BattleRope                      : false,
            CableCrossover                  : false,
            CrossTurner                     : false,
            CableRowMachine                 : false,
            CalfMachine                     : false,
            ChestPress                      : false,
            DeclineBench                    : false,
            DeclinePress                    : false,
            Dumbbells                       : false,
            FlatBench                       : false,
            HackSquatMachine                : false,
            HyperExtension                  : false,
            InclineBench                    : false,
            InfiniteMachine                 : false,
            LatPullDown                     : false,
            LegCurlMachine                  : false,
            LegExtension                    : false,
            LegPress                        : false,
            PeckDeckFly                     : false,
            PreacherCurlMachine             : false,
            PullUpBar                       : false,
            RowingMachine                   : false,
            ShoulderPress                   : false,
            SmithMachine                    : false,
            SquatBar                        : false,
            StationaryBicycle               : false,
            Treadmill                       : false,
            Tyre                            : false
        },

        // Facilities
        facilities          : {
            Spa                 : false,
            Sauna               : false,
            CardioArea          : false,
            ChangingRoom        : false,
            StrengthArea        : false,
            LoungeAndCafe       : false,
            Locker              : false,
            Shower              : false,
            BoxingRing          : false,
            ValetParking        : false,
            WallClimbing        : false,
            PersonalTrainer     : false,
            Washrooms           : false,
            AirConditioner      : false,
            FullyAirConditioned : false,
        },
        
        // Classes
        classes             : {
            MMA                 : false,
            Zumba               : false,
            HIIT                : false,
            FunctionalTraining  : false,
            CoreClasses         : false,
            KickBoxing          : false,
            Aerobics            : false,
            Pilates             : false,
        },
    };
}