import React from 'react';

const LoadingSpinner = () => {
    return (
        <div className="flex justify-center items-center mt-3 lg:mt-0">
            {/* <div> Loading </div> */}
            <div className="w-16 h-16 border-t-4 border-b-4 border-secondary rounded-full animate-spin"></div>
        </div>
    );
};

export default LoadingSpinner;