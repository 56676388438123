import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { themeColors } from '../../../theme';
import { getConvertedData } from '../FinanceComponents/FetchFinanceData';
import { fetchLeadsDataLastSixMonthsForGraph } from './SalesPageHelpers';
import LoadingSpinner from '../../../components/LoadingSpinner';

const dummyData = [
  { name: 'January', leads: 400, converted: 240 },
  { name: 'February', leads: 300, converted: 139 },
  { name: 'March', leads: 200, converted: 98 },
  { name: 'April', leads: 278, converted: 200 },
  { name: 'May', leads: 189, converted: 150 },
  { name: 'June', leads: 239, converted: 123 },
  { name: 'July', leads: 349, converted: 240 },
];

/**
 * CustomLegend Component - Renders a custom legend for the chart with leads and converted leads indicators.
 *
 * @returns {JSX.Element} - A div containing the custom legend.
 */
const CustomLegend = () => (
  <div className="flex flex-col sm:flex-row justify-end items-center p-2">
    <div className="flex items-center mb-2 sm:mb-0 sm:mr-4">
      <div className="w-5 h-5 rounded-full" style={{ backgroundColor: themeColors.white, border: `2px solid ${themeColors.fg}` }}></div>
      <span className="ml-2 text-sm text-secondary font-bold">Leads</span>
    </div>
    <div className="flex items-center">
      <div className="w-5 h-5 rounded-full" style={{ backgroundColor: themeColors.fg }}></div>
      <span className="ml-2 text-sm text-secondary font-bold">Converted Leads</span>
    </div>
  </div>
);

/**
 * ConvertedLeadsChart Component - Displays a bar chart of leads and converted leads data.
 * If the data is empty, it shows the chart in the background with a "Not enough data available" overlay.
 *
 * @param {Object} gymData - contains gym details
 * @returns {JSX.Element} - A div containing the bar chart or an overlay with "Not enough data available".
 */
const ConvertedLeadsChart = ({ gymData }) => {
    const [convertedData, setConvertedData] = useState([]);
    const [device       , setDevice       ] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchLeadsDataLastSixMonthsForGraph(gymData);
            setConvertedData(data);
        };
        fetchData();
    }, [gymData]);

     // Function to update device type based on window width
    const updateDeviceType = () => {
        const width = window.innerWidth;
        if (width <= 768) {
            setDevice('mobile');
        } else if (width > 768 && width <= 1024) {
            setDevice('tablet');
        } else {
            setDevice('desktop');
        }
    };

    useEffect(() => {
        // Set initial device type
        updateDeviceType();

        // Add event listener to track window resize
        window.addEventListener('resize', updateDeviceType);

        // Cleanup the event listener when component unmounts
        return () => {
            window.removeEventListener('resize', updateDeviceType);
        };
    }, []);

    const chartOpacity = convertedData.length === 0 ? 0.5 : 1; // Dim the chart when there's not enough data
    
    return (
        <div className="flex flex-col pt-2 w-full lg:w-1/2">
            <div className="flex flex-row justify-between">
                <p className="text-lg text-secondary font-bold text-left p-2">Converted Leads</p>
            </div>
            <div className={`flex ${convertedData.length === 0 ? 'flex-row justify-center items-center' : 'flex-col'} py-4 rounded-3xl border-secondary border-2 ${device === 'mobile' ? '' : '' } pr-2 h-full relative`}>
                {convertedData.length === 0 && <LoadingSpinner />}

                {convertedData.length !== 0 && 
                <ResponsiveContainer width="100%" height={device === 'mobile' ? 300 : 400}>
                    <BarChart
                            data={convertedData.length === 0 ? dummyData : convertedData}
                            margin={{ top: 20, bottom: 5 }}
                            barGap={5}
                            style={{ opacity: chartOpacity }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis 
                            dataKey="name" 
                            stroke={themeColors.secondary} // Set X-axis color to secondary
                            tick={{ fill: themeColors.secondary }} // Set X-axis tick color to secondary
                        />
                        <YAxis 
                            stroke={themeColors.secondary} // Set Y-axis color to secondary
                            tick={{ fill: themeColors.secondary }} // Set Y-axis tick color to secondary
                        />
                        <Tooltip />
                        <Legend content={<CustomLegend />} verticalAlign="top" align="right" />
                        <Bar
                            dataKey="leads"
                            fill={themeColors.white}
                            stroke={themeColors.fg}
                            strokeWidth={2}
                            radius={[10, 10, 0, 0]} // Apply rounded edges to the top of the bars
                        />
                        <Bar
                            dataKey="converted"
                            fill={themeColors.fg}
                            radius={[10, 10, 0, 0]} // Apply rounded edges to the top of the bars
                        />
                    </BarChart>
                </ResponsiveContainer>}

                {/* {convertedData.length === 0 && (
                <div 
                    className="absolute inset-0 flex justify-center items-center bg-lightTertiary bg-opacity-40 rounded-3xl"
                    style={{ zIndex: 1 }}
                >
                    <p className="text-secondary text-3xl font-bold">Not enough data available</p>
                </div>
                )} */}
            </div>      
        </div>
    );
};

export default ConvertedLeadsChart;
