import React, { useState, useRef } from 'react';
import { useOutsideClick, daysOfWeek, updateDaysSelection, addClassToDataBase } from './ClassManagementHelper';
import { formatWordToLowerCase } from '../../basicHelper';

/**
 * AddNewClass Component
 * A modal form component for adding a new class to the system.
 *
 * Props:
 * - onClassAdded: Function to call when a new class is successfully added.
 * - onClose: Function to close the modal.
 */
export const AddNewClass = ({ onClassAdded, onClose, gymData }) => {
    const modalRef = useRef();

    // Custom hook to handle outside clicks and close the modal
    useOutsideClick(modalRef, onClose);

    // State to manage form data
    const [newClass, setNewClass] = useState({
        className: '',
        days: [],
        sameTimings: true,
        classSchedule: {},
        duration: '',
        trainer: '',
        availableSlots: 0,
        priceForMembers: 0,
        priceForNonMembers: 0,
        isActive: false,
    });

    /**
     * Handle input changes for both text inputs and checkboxes.
     * Updates the state based on the input's name and value.
     *
     * @param {Object} e - The event object.
     */
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setNewClass(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    /**
     * Handle changes in the day selection.
     * Toggles the selected day in the state.
     *
     * @param {string} day - The day to be toggled in the selection.
     */
    const handleDayChange = (day) => {
        setNewClass(prevState => ({
            ...prevState,
            days: updateDaysSelection(prevState.days, day),
        }));
    };

    /**
     * Handle changes in the class schedule.
     * Updates the timing for each day in the classSchedule state.
     *
     * @param {Object} e - The event object.
     */
    const handleScheduleChange = (e) => {
        const { name, value } = e.target;
        setNewClass(prevState => ({
            ...prevState,
            classSchedule: { ...prevState.classSchedule, [name]: value },
        }));
    };

    /**
     * Handle adding a new class.
     * Sends the new class data to the database.
     */
    const handleAddClass = async () => {

        const classData = {
            className           : newClass.className,
            days                : newClass.days,
            sameTimings         : newClass.sameTimings,
            classSchedule       : newClass.sameTimings
                ? newClass.days.reduce((schedule, day) => {
                    schedule[day] = newClass.classSchedule.all;
                    return schedule;
                }, {})
                : newClass.classSchedule,
            duration            : newClass.duration,
            trainer             : newClass.trainer,
            availableSlots      : parseInt(newClass.availableSlots, 10),
            totalSlots          : parseInt(newClass.availableSlots, 10),
            priceForMembers     : parseInt(newClass.priceForMembers * 100, 10),     // Store Amount in Paise
            priceForNonMembers  : parseInt(newClass.priceForNonMembers * 100, 10),  // Store Amount in Paise
            isActive            : newClass.isActive,
            classID             : formatWordToLowerCase(newClass.className)
        };

        if (addClassToDataBase(gymData, classData)) {
            onClassAdded();
            onClose();
        }
        else {
            alert('Failed to Add Class');
        }
    };

    const {
        className,
        days,
        sameTimings,
        classSchedule,
        duration,
        trainer,
        availableSlots,
        priceForMembers,
        priceForNonMembers,
        isActive
    } = newClass;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50 p-4 sm:p-6">
            <form ref={modalRef} className="bg-tertiary w-full sm:w-3/4 lg:w-1/2 p-6 sm:p-8 h-[80vh] lg:h-[80vh] overflow-scroll border-2 border-secondary ">
                <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold mb-4 text-primary">Add New Class</h2>
    
                {/* Class Name Input */}
                <div className="mb-4 flex items-center">
                    <label className="text-black font-bold w-1/3" htmlFor="className">Class Name</label>
                    <input
                        type="text"
                        name="className"
                        id="className"
                        placeholder="Enter class name"
                        onChange={handleChange}
                        className="h-12 px-4 rounded-xl bg-tertiary text-primary mb-2 w-full border-4 border-secondary placeholder-color custom-input"
                    />
                </div>
    
                {/* Days Selection */}
                <div className="mb-4">
                    <label className="text-secondary font-bold">Days:</label>
                    <div className=" grid grid-cols-2 gap-y-2 gap-x-4 mt-2 lg:flex lg:flex-wrap lg:gap-2">
                        {daysOfWeek.map((day) => (
                            <label key={day} className="flex items-center">
                                <input
                                    type="checkbox"
                                    name="days"
                                    checked={days.includes(day)}
                                    onChange={() => handleDayChange(day)}
                                    className="mr-2 grid-span-1"
                                />
                                {day}
                            </label>
                        ))}
                    </div>
                </div>
    
                {/* Same Timings for All Days */}
                <div className="mb-4 flex items-center justify-center">
                    <label className="flex items-center">
                        <input
                            type="checkbox"
                            name="sameTimings"
                            checked={sameTimings}
                            onChange={handleChange}
                            className="mr-2"
                        />
                        Same Timings for All Days?
                    </label>
                </div>
    
                {/* Class Schedule Input */}
                {!sameTimings && (
                    <div className="mb-4">
                        <label className="text-secondary font-bold">Class Timings:</label>
                        <div className="flex flex-wrap gap-2 mt-2 justify-center">
                            {/* Added: if the days array is empty, this message is shown */}
                            {days.length == 0 && <div className='text-secondary font-semibold text-'>Select the days above to schedule the class timings</div>}
                            {days.map((day) => (
                                <div key={day} className="w-full flex items-center">
                                    <label className="text-black font-bold w-1/3">{day}:</label>
                                    <input
                                        type="time"
                                        name={day}
                                        onChange={handleScheduleChange}
                                        className="h-12 px-4 rounded-xl bg-tertiary text-primary mb-2 w-full border-4 lg:rounded-2xl border-secondary placeholder-color custom-input"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
    
                {/* Single Timing for All Days */}
                {sameTimings && (
                    <div className="mb-4 flex items-center">
                        <label className="text-black font-bold w-1/3" htmlFor="classScheduleAll">Class Schedule (Same for All Days)</label>
                        <input
                            type="time"
                            name="classSchedule"
                            id="classScheduleAll"
                            onChange={(e) => handleScheduleChange({ target: { name: 'all', value: e.target.value } })}
                            className="h-12 px-4 rounded-xl bg-white text-primary w-2/3"
                        />
                    </div>
                )}
    
                {/* Duration Input */}
                <div className="mb-4 flex items-center">
                    <label className="text-black font-bold w-1/3" htmlFor="duration">Duration (In Minutes)</label>
                    <input
                        type="text"
                        name="duration"
                        id="duration"
                        placeholder="60"
                        onChange={handleChange}
                        className="h-12 px-4 rounded-xl bg-white text-primary w-2/3"
                    />
                </div>
    
                {/* Trainer Input */}
                <div className="mb-4 flex items-center">
                    <label className="text-black font-bold w-1/3" htmlFor="trainer">Trainer</label>
                    <input
                        type="text"
                        name="trainer"
                        id="trainer"
                        placeholder="Enter trainer name"
                        onChange={handleChange}
                        className="h-12 px-4 rounded-xl bg-white text-primary w-2/3"
                    />
                </div>
    
                {/* Available Slots Input */}
                <div className="mb-4 flex items-center">
                    <label className="text-black font-bold w-1/3" htmlFor="availableSlots">Available Slots</label>
                    <input
                        type="number"
                        name="availableSlots"
                        id="availableSlots"
                        placeholder="Enter number of available slots"
                        onChange={handleChange}
                        className="h-12 px-4 rounded-xl bg-white text-primary w-2/3"
                    />
                </div>
    
                {/* Price Inputs */}
                <div className="mb-4 flex items-center justify-center">
                    <label className="text-black font-bold w-1/3" htmlFor="priceForMembers">Price for Members</label>
                    <input
                        type="number"
                        name="priceForMembers"
                        id="priceForMembers"
                        placeholder="Enter price for members"
                        onChange={handleChange}
                        className="h-12 px-4 rounded-xl bg-white text-primary w-2/3"
                    />
                </div>

                <div className="mb-4 flex items-center justify-center">
                    <label className="text-black font-bold w-1/3" htmlFor="priceForNonMembers">Price for Non-Members</label>
                    <input
                        type="number"
                        name="priceForNonMembers"
                        id="priceForNonMembers"
                        placeholder="Enter price for non-members"
                        onChange={handleChange}
                        className="h-12 px-4 rounded-xl bg-white text-primary w-2/3"
                    />
                </div>
    
                {/* Active Checkbox */}
                <div className="mb-4 flex items-center justify-center">
                    <label className="flex items-center">
                        <input
                            type="checkbox"
                            name="isActive"
                            checked={isActive}
                            onChange={handleChange}
                            className="mr-2"
                        />
                        Is Active
                    </label>
                </div>
    
                {/* Submit and Close Buttons */}
                <div className="flex justify-end gap-x-2">
                    <button
                        type="button"
                        onClick={handleAddClass}
                        className="py-2 px-6 bg-primary text-tertiary font-bold rounded-2xl shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50 transition-all"
                    >
                        Add Class
                    </button>
                    <button
                        type="button"
                        onClick={onClose}
                        className="py-2 px-6 bg-secondary text-tertiary font-bold rounded-2xl shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 transition-all"
                    >
                        Close
                    </button>
                </div>
            </form>
        </div>
    );
    
};
