// EarnSpendGraph.js

import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import '../../../CustomCss.css'; // Import the custom CSS file

/**
 * EarnSpendGraph component to display a circular percentage graph for earnings and spendings.
 * @param {Object} props - Component properties.
 * @param {number} props.spendAmount - The amount spent.
 * @param {number} props.earnAmount - The amount earned.
 * @returns {JSX.Element} The rendered component.
 */
const EarnSpendGraph = ({ spendAmount, earnAmount }) => {
    const totalAmount = spendAmount + earnAmount;
    const profitPercentage = ((earnAmount / totalAmount) * 100).toFixed(2);

    return (
        <div className="earn-spend-graph flex flex-col items-center">
            <h2 className="text-2xl font-bold mb-4">Earn and Spend Graph</h2>
            <div className="circular-graph-container">
                <CircularProgressbar
                    value={profitPercentage}
                    text={`${profitPercentage}%`}
                    styles={buildStyles({
                        rotation: 0.75,
                        strokeLinecap: 'round',
                        textSize: '16px',
                        pathTransitionDuration: 1.5,
                        pathColor: '#9EBC80', // Secondary color for earning
                        textColor: '#000',
                        trailColor: '#465130', // Highlight red color for spending
                        backgroundColor: '#9EBC80',
                    })}
                />
            </div>
            <div className="flex justify-between w-full mt-4">
                <div className="flex items-center">
                    <div className="legend-box bg-red-500 mr-2"></div>
                    <span>Spent: Rs {spendAmount}</span>
                </div>
                <div className="flex items-center">
                    <div className="legend-box bg-green-500 mr-2"></div>
                    <span>Earned: Rs {earnAmount} </span>
                </div>
            </div>
        </div>
    );
};

EarnSpendGraph.propTypes = {
    spendAmount: PropTypes.number.isRequired,
    earnAmount: PropTypes.number.isRequired,
};

export default EarnSpendGraph;
