import React, { useState } from 'react';
import { themeColors } from '../theme';
import { LocationIcon, SearchIcon, CalendarIcon, CautionIcon } from '../assets/WelcomeScreenImage';
import Calendar from '../helperFunctions/DashboardScenesHelpers/MainDisplayPageHelpers/Calendar';
import Alert from '../helperFunctions/DashboardScenesHelpers/MainDisplayPageHelpers/Alert';




const CustomAlert = ({ message, onClose }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-bg bg-opacity-50 relative inset-0"></div>
            <div className="absolute top-20 right-0 bg-tertiary rounded-lg p-6 text-center shadow-lg z-50">
                <p className="mb-4">{message}</p>
                <button
                    onClick={onClose}
                    className="bg-secondary text-tertiary py-2 px-4 rounded-lg hover:bg-primary transition-all duration-500"
                >
                    Close
                </button>
            </div>
        </div>
    );
};




const SidePanel = ({activeTab}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
    const [showAlert, setShowAlert] = useState(false);




    const handleCalendarClick = () => {
        setIsExpanded(true);
    };




    const handleSearchClick = () => {
        setShowAlert(true);
    };




    const handleCloseAlert = () => {
        setShowAlert(false);
    };




    const togglePanel = () => {
        setIsExpanded(!isExpanded);
    };




    // Function to format the date in "Monday, 21st August, 2024" format
    const formatDate = (dateString) => {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };


    // this is added because of content layout of different pages and how to calendar behaves when expands
    // const rightPosition = activeTab === "ClientsManagement" ? "right-64" : activeTab === "Finance" ? "right-0" : "right-0";


    return (
        <div
        // corrected expanded sidepanel width from 450px to w-58
            className={`transition-all overflow-x-visible duration-300 ease-in-out transform ${isExpanded ? `w-58 rounded-3xl z-50` : 'w-16 rounded-full'} bg-lightTertiary h-full hidden lg:flex flex-col justify-between cursor-pointer p-4 ml-4 relative`}
            onClick={() => !isExpanded && togglePanel()} // Open panel when not expanded
        >
            <div className="flex justify-center items-center pb-4 h-full">
                <div className="flex flex-row justify-between w-full h-full">
                    {isExpanded ? (
                        <div className="flex flex-col justify-between h-full w-full transition-opacity duration-500 ease-in-out opacity-100">
                            {/* Display the formatted date */}
                            <div className="flex flex-row justify-between py-2 items-center">
                                <p className="text-secondary text-xl font-bold text-left transition-transform duration-300 ease-in-out transform translate-y-0">
                                    {formatDate(selectedDate)}
                                </p>




                                {/* Cross Button to close the SidePanel */}
                                <button
                                    className="text-secondary text-3xl sm:text-5xl text-center transition-transform duration-300 ease-in-out transform hover:scale-110 focus:outline-none"
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent event from bubbling up to parent div
                                        togglePanel(); // Close panel
                                    }}
                                >
                                    &times;
                                </button>
                            </div>
                            <Calendar
                                selectedDate={selectedDate}
                                setSelectedDate={setSelectedDate}
                            />
                            <Alert selectedDate={selectedDate}/>
                        </div>
                    ) : (
                        <div className="flex flex-col items-center justify-start h-full w-full transition-opacity duration-500 ease-in-out opacity-100">
                            <CalendarIcon
                                className="mb-4 transition-transform duration-300 ease-in-out transform hover:scale-110"
                                onClick={handleCalendarClick}
                            />
                            <CautionIcon
                                className="transition-transform duration-300 ease-in-out transform hover:scale-110"
                                onClick={handleCalendarClick}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};




export default SidePanel;