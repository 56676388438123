import React, { useEffect, useState } from 'react';

const nationalHolidays = [
    new Date(2024, 0, 1),
    new Date(2024, 6, 4),
];

const myHolidays = [
    new Date(2024, 0, 1),
    new Date(2024, 6, 8),
];

const Calendar = ({ selectedDate, setSelectedDate }) => {
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [today, setToday] = useState(new Date());

    useEffect(() => {
        setToday(new Date());
    }, []);
    
    const generateCalendarDates = () => {
        const dates = [];
        const firstDay = new Date(currentYear, currentMonth, 1);
        const lastDay = new Date(currentYear, currentMonth + 1, 0);

        for (let i = 0; i < firstDay.getDay(); i++) {
            dates.push(null);
        }

        for (let i = 1; i <= lastDay.getDate(); i++) {
            dates.push(new Date(currentYear, currentMonth, i));
        }

        return dates;
    };

    const calendarDates = generateCalendarDates();

    const handlePrevMonth = () => {
        if (currentMonth === 0) {
            setCurrentMonth(11);
            setCurrentYear(currentYear - 1);
        } else {
            setCurrentMonth(currentMonth - 1);
        }
    };

    const handleNextMonth = () => {
        if (currentMonth === 11) {
            setCurrentMonth(0);
            setCurrentYear(currentYear + 1);
        } else {
            setCurrentMonth(currentMonth + 1);
        }
    };

    const isToday = (date) => {
        if (date !== null) {
            return date.getDate() === today.getDate() &&
                date.getMonth() === today.getMonth() &&
                date.getFullYear() === today.getFullYear();
        }
    };

    const isHoliday = (date) => {
        if (date !== null) {
            return nationalHolidays.some(holiday =>
                holiday.getDate() === date.getDate() &&
                holiday.getMonth() === date.getMonth() &&
                holiday.getFullYear() === date.getFullYear()
            );
        }
    };

    const isMyHoliday = (date) => {
        if (date !== null) {
            return myHolidays.some(holiday =>
                holiday.getDate() === date.getDate() &&
                holiday.getMonth() === date.getMonth() &&
                holiday.getFullYear() === date.getFullYear()
            );
        }
    };

    const formatDate = (date) => {
        const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        return localDate.toISOString().split('T')[0];
    };

    return (
        <div className="flex flex-col ">
            <div className="rounded-2xl border-secondary border-2">
                <div className="flex justify-between px-4 items-center mb-4 py-2 bg-secondary rounded-t-xl">
                    <button onClick={handlePrevMonth} className="bg-secondary text-2xl text-tertiary rounded-md font-bold">&lt;</button>
                    <h3 className="text-lg font-bold text-tertiary">
                        {new Date(currentYear, currentMonth).toLocaleString('default', { month: 'long' })} {currentYear}
                    </h3>
                    <button onClick={handleNextMonth} className="bg-secondary text-2xl text-tertiary rounded-md font-bold">&gt;</button>
                </div>
                <div className="grid grid-cols-7 gap-1 flex-grow pb-2">
                    {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
                        <div key={day} className="text-center font-semibold text-secondary text-xs sm:text-sm md:text-sm">{day}</div>
                    ))}
                    {calendarDates.map((date, index) => (
                        <button
                            onClick={() => setSelectedDate(formatDate(date))}
                            key={index}
                            className={`border font-semibold text-xs sm:text-sm md:text-base text-secondary ${date ? 'cursor-pointer hover:bg-secondary hover:text-white hover:rounded-full mx-2 lg:mx-0 py-1' : ''} 
                                ${isToday(date) ? 'text-primary' : ''} 
                                ${isHoliday(date) ? 'text-tertiary bg-secondary rounded-full' : ''} 
                                ${isMyHoliday(date) ? 'border-2 border-secondary rounded-full' : ''}
                                ${date && (selectedDate === formatDate(date)) ? 'border-2 border-primary rounded-full' : ''}`}
                        >
                            {date && date.getDate()}
                        </button>
                    ))}
                </div>
            </div>
            <div className="flex flex-row pt-4">
                <div className="flex flex-row justify-between px-8 space-x-4">
                    <div className="flex items-center mb-2">
                        <div className="h-4 w-4 bg-secondary text-tertiary rounded-full mr-2"></div>
                        <span className="font-semibold text-secondary text-xs sm:text-sm">National Holidays</span>
                    </div>
                    <div className="flex items-center mb-2">
                        <div className="h-4 w-4 border-2 border-secondary rounded-full mr-2"></div>
                        <span className="font-semibold text-secondary text-xs sm:text-sm">My Holidays</span>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Calendar;
