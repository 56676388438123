export const AllStaff = ({ staffList, handleStaffClick, selectedStaff, setShowStaffDetails }) => {
    
    return (
        <>
            <h2 className="text-3xl font-semibold mb-4 text-secondary">Staff List</h2>
            <div className="flex flex-col pb-4 rounded-2xl min-h-16 lg:min-h-24 border-secondary border-4 py-2 px-2 overflow-x-auto">
                <table className="min-w-full bg-tertiary">
                    <thead>
                        <tr className="text-xs sm:text-sm md:text-md lg:text-xl">
                            <th className="py-2 px-4 border-b-2 border-secondary text-center text-secondary">Staff Name</th>
                            <th className="py-2 px-4 border-b-2 border-secondary text-center text-secondary">Position</th>
                            <th className="py-2 px-4 border-b-2 border-secondary text-center text-secondary">Salary</th>
                            <th className="py-2 px-4 border-b-2 border-secondary text-center text-secondary">Phone Number</th>
                        </tr>
                    </thead>


                    {/* Divider Line */}
                    <tbody>
                        <tr>
                            <td colSpan="4" className="h-2 border-secondary"></td>
                        </tr>
                    </tbody>

                    {/* Main Body */}
                    <tbody>
                        {staffList.map((staff, index) => (
                            <tr
                                key={index}
                                onClick={() => {
                                    handleStaffClick(staff);
                                    setShowStaffDetails(true);
                                }}
                                // changed the condtional render to based on email rather than id
                                className={`text-xs sm:text-sm md:text-md lg:text-base text-black cursor-pointer hover:bg-secondary hover:text-white ${selectedStaff?.email === staff.email ? 'bg-secondary text-white' : 'bg-tertiary text-black'} ${index === 0 ? 'pt-4' : ''}`}
                            >
                                <td className="py-2 px-4 border-b text-center font-semibold">{staff.staffName}</td>
                                <td className="py-2 px-4 border-b text-center font-semibold">{staff.position}</td>
                                <td className="py-2 px-4 border-b text-center font-semibold">{staff.salary}</td>
                                <td className="py-2 px-4 border-b text-center font-semibold">{staff.phoneNumber}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};
