import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../../../config/firebase';
import { updateDoc, doc } from '@firebase/firestore';
import { AddNewClass } from '../../../helperFunctions/DashboardScenesHelpers/ClassesHelpers/AddNewClass';
import { fetchClasses } from '../../../helperFunctions/DashboardScenesHelpers/ClassesHelpers/fetchClassData';
import { cityList, classList, gymList } from '../../../components/Databases';
import DisplayClassModal from '../../../helperFunctions/ModalHelpers/DisplayClassModal';

/**
 * Class Management Page
 * Fetches Classes in the main Classes Database and displays them.
 * @param {Object} gymData - Contains all the gym information.
 * @returns {JSX.Element} The rendered component.
 */
const ClassManagement = ({ gymData }) => {
    const [showAddClassForm, setShowAddClassForm] = useState(false);
    const [selectedClass, setSelectedClass] = useState(null);
    const [classes, setClasses] = useState([]);

    // Fetch classes from the database
    const loadClasses = useCallback(async () => {
        const fetchedClasses = await fetchClasses(gymData);
        setClasses(fetchedClasses);
    }, [gymData]);

    useEffect(() => {
        loadClasses();
    }, [loadClasses]);

    // Handle status change for a class
    const handleStatusChange = async (id, value) => {
        const classRef = doc(db, cityList, gymData.city, gymList, gymData.gymName, classList, id);
        await updateDoc(classRef, { isActive: value === 'Yes' });
        setClasses(prevClasses => 
            prevClasses.map(cls => 
                cls.id === id ? { ...cls, isActive: value === 'Yes' } : cls
            )
        );
    };

    // Open the form to add a new class
    const handleAddClassClick = () => {
        setShowAddClassForm(true);
    };

    // Close the add class form and modal
    const handleCloseForm = () => {
        setShowAddClassForm(false);
        setSelectedClass(null);
    };

    // Open the modal with class details
    const handleClassClick = (cls) => {
        setSelectedClass(cls);
    };

    return (
        <div className="flex flex-col md:flex-row w-full p-4 md:pl-8 md:pr-4">
            <div className="flex flex-col w-full">
                <div className="flex flex-col md:flex-row justify-between w-full mb-4">
                    <p className="text-left text-2xl md:text-3xl font-bold text-secondary">Class Management</p>
                  <div className='flex gap-2'>
                  <button
                        className="text-tertiary font-bold bg-secondary py-2 px-4 mt-4 md:mt-0 rounded-lg shadow transition-colors"
                    >
                        Add Classes for next Week
                    </button>
                    <button
                        onClick={handleAddClassClick}
                        className="text-tertiary font-bold bg-secondary py-2 px-4 mt-4 md:mt-0 rounded-lg shadow transition-colors"
                    >
                        Add New Class
                    </button>
                  </div>
                </div>
                {showAddClassForm && (
                    <AddNewClass onClassAdded={loadClasses} onClose={handleCloseForm} gymData={gymData} />
                )}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {classes.map(cls => (
                        <div
                            key={cls.id}
                            className="bg-white shadow-lg rounded-lg p-4 cursor-pointer hover:shadow-xl transition-shadow"
                        >
                            <h3 className="text-lg md:text-xl font-bold text-gray-800">{cls.className}</h3>
                            <p className="text-sm text-gray-600">Instructor: <span className="font-semibold">{cls.trainer}</span></p>
                            <p className="text-sm text-gray-600">Available Slots: <span className="font-semibold">{cls.availableSlots}</span></p>
                            <p className="text-sm text-gray-600">Total Slots: <span className="font-semibold">{cls.totalSlots}</span></p>
                            <div className="flex flex-row justify-between mt-4">
                                <div>
                                    <label className="mr-2 text-gray-700">Active:</label>
                                    <select
                                        value={cls.isActive ? 'Yes' : 'No'}
                                        onChange={(e) => handleStatusChange(cls.id, e.target.value)}
                                        className="bg-gray-200 border border-gray-300 text-gray-700 rounded-lg py-1 px-2"
                                    >
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                                <div className="bg-secondary text-tertiary hover:bg-primary hover:text-secondary rounded-lg p-2"
                                        onClick={() => handleClassClick(cls)}>
                                    Show Details
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {selectedClass && (
                    <DisplayClassModal classDetails={selectedClass} onClose={handleCloseForm} />
                )}
            </div>
        </div>
    );
};

export default ClassManagement;
