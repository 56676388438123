/**
 * AddStaff.js
 * 
 * This file generates a form to add staff members in the gym database
 */

import React, { useEffect, useState } from 'react';
import { addStaffMember, checkForConstraintsForStaff, checkIfStaffExistsinGymDatabase, checkIfStaffExistsInTrainRexDatabase } from './StaffButtonsHelper';
import { getCountryPhoneCode } from '../../basicHelper';

/**
 * AddStaff Component
 * 
 * This component generates a form to add staff members to the gym database.
 * It accepts gymData as an argument to associate staff with a specific gym.
 * The form first checks if the staff's email exists in the userbase.
 * If the staff exists, the rest of the form fields are revealed and the phone number
 * and name are fetched from the existing data.
 * 
 * @param {Object} gymData - The data related to the gym where the staff is being added.
 * @param {Function} onBack - Callback function to handle back button click.
 */
const AddStaff = ({ onBack, gymData }) => {
    const [staffName, setStaffName] = useState('');
    const [staffInfo, setStaffInfo] = useState({});
    const [position, setPosition] = useState('Default');
    const [joiningDate, setJoiningDate] = useState('');
    const [shifts, setShifts] = useState([{ inTime: '', outTime: '' }]);
    const [salary, setSalary] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [remarks, setRemarks] = useState('');
    const [image, setImage] = useState(null);
    const [isChecked, setIsChecked] = useState(false); // To control visibility of the rest of the form

    const addShift = () => {
        setShifts([...shifts, { inTime: '', outTime: '' }]);
    };

    const handleShiftChange = (index, field, value) => {
        const newShifts = [...shifts];
        newShifts[index][field] = value;
        setShifts(newShifts);
    };
    console.log("gymData, ", gymData);

    /**
     * Checks if the staff email exists in the userbase and fetches existing details.
     * If not found, an alert is shown and the rest of the form remains hidden.
     */
    const handleCheckUser = async () => {
        try {
            const staffID = email; // Use the email or any identifier you want to check the database with
    
            // Check if the staff already exists in the gym database
            const staffExists = await checkIfStaffExistsinGymDatabase(staffID, gymData);
    
            if (staffExists) {
                alert('Staff Member already exists in Gym Database');
                onBack();
                return; // Exit the function if staff exists
            }
    
            // Else, check if the staff exists in the TrainRex database
            const staffData = await checkIfStaffExistsInTrainRexDatabase(staffID);
            if (staffData.staffExists) {
                setStaffInfo(staffData);
                setIsChecked(true);
            } else {
                alert('User not present in Train Rex Database. Please Sign Up first.');
            }
    
        } catch (error) {
            alert('Error in the system. Please try again!');
            console.log('Error in fetching staff info:', error);
        }
    };

    /**
     * Handles image upload and displays it in a round circle
     */
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;
    
        const maxWidth = 800;  // Define max width for resizing
        const maxHeight = 600; // Define max height for resizing
        const quality = 0.6;   // Define quality for compression (0.0 to 1.0)
    
        const reader = new FileReader();
        reader.onloadend = () => {
            const img = new Image();
            img.src = reader.result;
    
            img.onload = () => {
                const canvas = document.createElement('canvas');
                let width = img.width;
                let height = img.height;
    
                // Resize the image based on max width and height
                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round((height * maxWidth) / width);
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round((width * maxHeight) / height);
                        height = maxHeight;
                    }
                }
    
                // Set the canvas dimensions
                canvas.width = width;
                canvas.height = height;
    
                // Draw the image onto the canvas
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);
    
                // Convert the canvas to a Base64 string with the specified quality
                const dataUrl = canvas.toDataURL('image/jpeg', quality);
    
                // Set the resized and compressed image as the state
                setImage(dataUrl);
            };
        };
    
        reader.readAsDataURL(file);
    };
    
    // Log the Base64 string whenever it changes
    useEffect(() => {
        console.log("12345", image);
    }, [image]);


    // Submitting the form

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('phoneNumber : ', staffInfo.phoneNumber, ' : ', phoneNumber);
        const staffData = {
            staffName   :   staffInfo.staffName,
            staffID     :   staffInfo.staffID,
            gender      :   staffInfo.staffGender,
            position,
            joiningDate,
            shifts,
            salary,
            email,
            phoneNumber :   staffInfo.phoneNumber || `${await getCountryPhoneCode()}${phoneNumber}`,
            remarks,
            image
        };

        console.log('Staffdata ', staffData);

        if (checkForConstraintsForStaff(staffData)) {
            console.log('')
            await addStaffMember(staffData, gymData);
            resetForm();
            onBack();
            alert('Staff Added!');
            return;
        }

        else {
            alert('Please fill all the details!');
            return;
        }
    };

    const resetForm = () => {
        setStaffName('');
        setPosition('Default');
        setJoiningDate('');
        setShifts([{ inTime: '', outTime: '' }]);
        setSalary('');
        setEmail('');
        setPhoneNumber('');
        setRemarks('');
        setImage(null);
        setIsChecked(false);
    };

    return (
        <div className="min-h-screen flex flex-col lg:flex-row justify-start gap-4 md:gap-32 lg:gap-32 p-8 ml-0 lg:ml-56">
            {/* Back Button */}
            <button className='py-1 px-3 w-20 h-10 md:w-24 md:h-12 -ml-4 lg:-ml-60 rounded-md text-sm md:text-lg text-tertiary bg-secondary transition-all duration-300 hover:bg-primary' onClick={onBack}>&#8592; Back</button>


            {/* Form Container */}
            <div className="w-full max-w-4xl flex flex-col md:flex-row lg:mt-0 row items-start">
                {/* Image Section */}
                <div className="flex flex-col items-center -ml-16 md:-ml-0 pr-8 scale-75 md:scale-100">
                    <img
                        src={image || 'https://placehold.co/200x200/9EBC80/FFF'}
                        alt="Staff"
                        className="rounded-full md:w-60 md:h-60 object-cover mb-4 border border:color-black"
                    />
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        className="py-1 px-4 bg-secondary text-white rounded hover:bg-primary cursor-pointer mt-2"
                        title="Upload Image"
                    />
                </div>
    

                {/* Form Section */}
                <div className="w-full">
                    <h2 className="text-2xl mb-6 text-center font-semibold text-black">Add Staff</h2>
    
                    <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-6">
                        {/* Email Field */}
                        <div className="col-span-2">
                            <label className="block text-black mb-2">Email</label>
                            <div className="flex">
                                <input
                                    type="email"
                                    className="p-2 border rounded w-full text-black"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <button
                                    type="button"
                                    onClick={handleCheckUser}
                                    className="bg-secondary text-white ml-2 py-2 px-4 rounded hover:bg-primary"
                                >
                                    Check
                                </button>
                            </div>
                        </div>
    
                        {isChecked && (
                            <>
                                {/* Staff Name */}
                                <div>
                                    <label className="block text-black mb-2">Staff Name</label>
                                    <input
                                        type="text"
                                        className="p-2 border rounded w-full text-black"
                                        value={staffInfo.staffName}
                                        readOnly
                                    />
                                </div>
    
                                {/* Phone Number */}
                                <div>
                                    <label className="block text-black mb-2">Phone Number</label>
                                    <input
                                        type="tel"
                                        className="p-2 border rounded w-full text-black"
                                        value={staffInfo.phoneNumber}
                                        readOnly={staffInfo.phoneNumber ? true : false}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                    />
                                </div>
    
                                {/* Position */}
                                <div className="col-span-2">
                                    <label className="block text-black mb-2">Position</label>
                                    <select
                                        className="p-2 border rounded w-full text-black"
                                        value={position}
                                        onChange={(e) => setPosition(e.target.value)}
                                        required
                                    >
                                        <option value="Default">Select A Role</option>
                                        <option value="Owner">Owner</option>
                                        <option value="Receptionist">Receptionist</option>
                                        <option value="Trainer">Trainer</option>
                                        <option value="Support">Support</option>
                                    </select>
                                </div>
    

                                {/* Joining Date */}
                                <div>
                                    <label className="block text-black mb-2">Joining Date</label>
                                    <input
                                        type="date"
                                        className="p-2 border rounded w-full text-black"
                                        value={joiningDate}
                                        onChange={(e) => setJoiningDate(e.target.value)}
                                        required
                                    />
                                </div>
    
                                {/* Salary */}
                                <div>
                                    <label className="block text-black mb-2">Salary</label>
                                    <input
                                        type="number"
                                        className="p-2 border rounded w-full text-black"
                                        value={salary}
                                        onChange={(e) => setSalary(e.target.value)}
                                        required
                                    />
                                </div>
    
                                {/* Shifts */}
                                <div className="col-span-2">
                                    <label className="block text-black mb-2">Shifts</label>
                                    {shifts.map((shift, index) => (
                                        <div key={index} className="flex justify-between space-x-4 mb-2">
                                            <input
                                                type="time"
                                                className="p-2 border rounded w-full text-black"
                                                value={shift.inTime}
                                                onChange={(e) => handleShiftChange(index, 'inTime', e.target.value)}
                                                required
                                            />
                                            <input
                                                type="time"
                                                className="p-2 border rounded w-full text-black"
                                                value={shift.outTime}
                                                onChange={(e) => handleShiftChange(index, 'outTime', e.target.value)}
                                                required
                                            />
                                        </div>
                                    ))}
                                    <button type="button" onClick={addShift} className="bg-secondary text-white py-2 px-4 rounded hover:bg-primary w-full">
                                        Add Another Shift
                                    </button>
                                </div>
    
                                {/* Remarks */}
                                <div className="col-span-2">
                                    <label className="block text-black mb-2">Remarks</label>
                                    <textarea
                                        className="p-2 border rounded w-full text-black"
                                        value={remarks}
                                        onChange={(e) => setRemarks(e.target.value)}
                                        rows="3"
                                    ></textarea>
                                </div>
    
                                {/* Buttons */}
                                <div className="col-span-2 flex justify-between">
                                    <button
                                        type="submit"
                                        className="bg-secondary text-white py-2 px-4 rounded hover:bg-primary w-1/2"
                                    >
                                        Submit
                                    </button>
                                    <button
                                        type="reset"
                                        onClick={resetForm}
                                        className="bg-secondary text-white py-2 px-4 rounded hover:bg-primary w-1/2 ml-2"
                                    >
                                        Reset
                                    </button>
                                </div>
                            </>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );    

    
};

export default AddStaff;