import React, { useEffect, useState } from 'react';
import RingGraph from '../../../components/RingGraph';
import { graphColors, themeColors } from '../../../theme';
import { getMembershipDistribution } from './FetchFinanceData';

const dummyData = [
    { name: '1 Day', value: 18000, percentage: 12, color: '#A3C1AD' },
    { name: '1 Month', value: 18000, percentage: 12, color: '#E5E5E5' },
    { name: '3 Months', value: 18000, percentage: 12, color: '#9DA88E' },
    { name: '6 Months', value: 18000, percentage: 12, color: '#4F5D2F' },
    { name: 'Annual', value: 18000, percentage: 12, color: '#3D4637' },
];

// Corresponding Colors array for the RingGraph component
// const graphColors = dummyData.map(item => item.color);

/**
 * MembershipDistributionGraph Component - Fetches membership distribution data and renders a RingGraph component.
 *
 * @returns {JSX.Element} - A div containing the RingGraph component with the fetched membership distribution data.
 */
const MembershipDistributionGraph = (gymData) => {
    const [distribution, setDistribution] = useState([]);
    const strokeColor = themeColors.lightAccent;
    const strokeWidth = 4;
    const [legendLayout, setLegendLayout] = useState('horizontal'); // Responsive legend layout
    const centerLabel = false;
    const withLines = true;

    // Responsive dimensions for the chart
    const chartWidth = '100%';
    const chartHeight = '100%';
    const innerRadius = '40%';
    const outerRadius = '80%';

    useEffect(() => {
        const fetchData = async () => {
            const data = await getMembershipDistribution(gymData);
            setDistribution(data);
        };

        // Adjust legend layout based on screen size
        const updateLayout = () => {
            if (window.innerWidth < 600) {
                setLegendLayout('horizontal');
            } else {
                setLegendLayout('horizontal');
            }
        };

        fetchData();
        updateLayout();
        window.addEventListener('resize', updateLayout);

        return () => window.removeEventListener('resize', updateLayout);
    }, []);

    return (
        <div 
            className="flex flex-col pt-2 w-full lg:w-1/2"
            style={{ width: '100%', maxWidth: '700px' }}
        >
            <div className="flex flex-row justify-between">
                <p className="text-lg text-secondary font-bold text-left p-2">Membership Distribution Graph</p>
            </div>
            <div 
                className="flex flex-col py-4 rounded-3xl border-secondary border-2 py-2 px-2 h-full relative"
                style={{ width: '100%', height: '450px' }}
            >
                <RingGraph 
                    data={distribution.length === 0 ? dummyData:distribution} 
                    colors={graphColors} 
                    innerRadius={innerRadius} 
                    outerRadius={outerRadius} 
                    strokeColor={strokeColor} 
                    strokeWidth={strokeWidth}
                    legendLayout={legendLayout}
                    wantCenterLabel={centerLabel}
                    withLines={withLines}
                    width={chartWidth} 
                    height={chartHeight}
                />
                {distribution.length === 0 && (
                    <div 
                        className="absolute inset-0 flex items-center justify-center bg-lightTertiary bg-opacity-90 rounded-3xl"
                        style={{ zIndex: 1 }}
                    >
                        <p className="text-secondary text-3xl font-bold">Not enough data available</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MembershipDistributionGraph;
