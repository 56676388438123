import { collection, getDocs, query, where } from '@firebase/firestore';
import { db,  } from '../../../config/firebase';
import { cityList, classList, dbCalendar, gymList } from '../../../components/Databases';
import { getDate } from '../../basicHelper';

/**
 * Fetched the given date's classes
 * @param {Object} gymData - Contains the gym's information
 * @param {String} date - DD/MM/YYYY
 * @returns {Object} - Classes of the date
 */
export const fetchClassesOfDate = async (gymData, date) =>{
    try {
        const classCollectionRef = collection(
            db, 
            cityList, 
            gymData.city, 
            gymList, 
            gymData.gymName, 
            dbCalendar, 
            date,
            classList
        );
        const classesSnapshot = await getDocs(classCollectionRef);
        return classesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    } catch (error) {
        console.error("Error fetching classes: ", error);
        return [];
    }
}

/**
 * Fetches classes from the gym's database.
 * @param {Object} gymData - The gym data containing city and gym name.
 * @returns {Array} - Array of classes in the gym's database.
 */
export const fetchClasses = async (gymData) => {
    try {
        // Reference to the collection
        const classCollectionRef = collection(
            db,
            cityList,
            gymData.city,
            gymList,
            gymData.gymName,
            classList
        );

        // Query to filter documents with id 'testClass'
        const classQuery = query(classCollectionRef, where('__name__', '!=', 'testClass'));
        const classesSnapshot = await getDocs(classQuery);

        // Map the documents to include id and data
        return classesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    } catch (error) {
        console.error("Error fetching classes: ", error);
        return [];
    }
};

