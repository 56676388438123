import React, { useState, useEffect } from "react";
import RingGraph from "../../../components/RingGraph";
import { graphColors, themeColors } from "../../../theme";
import { getAgeData } from "./FetchMembersData";


/**
 * Displays a pie chart of age distribution.
 * @param {Object} gymData - Contains information about the gym
 * @param {String} legendLayout = Contains the type of layout needed
 * @returns {JSX.Element} AgeGraph Component
 */
export const AgeGraph = ({ gymData, legendLayout }) => {
    const [ageData, setAgeData] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            const data = await getAgeData(gymData);
            setAgeData(data);
        };
        fetchData();
    }, [gymData]);


    const strokeColor = themeColors.lightAccent;
    const strokeWidth = 4;
    const centerLabel = false;


    // Responsive dimensions for the chart
    const chartWidth = '100%';
    const chartHeight = '100%';
    const innerRadius = '50%';
    const outerRadius = '80%';


    return (
    <>
    {/* <PieChartGraph data={data} colors={COLORS} dataKey="value" nameKey="name" legendLayout={legendLayout} />; */}
    <div
            className="flex flex-col rounded-3xl border-secondary border-2 py-2 px-4 justify-between lg:max-h-96"
            style={{ width: '100%', maxWidth: '400px'}}
        >
            <p className="text-xl text-secondary font-bold text-left">Age Graph</p>
            <div style={{ width: '120%', height: '300px' }}>
                <RingGraph
                    data={ageData}
                    colors={graphColors}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    strokeColor={strokeColor}
                    strokeWidth={strokeWidth}
                    legendLayout={legendLayout}
                    wantCenterLabel={centerLabel}
                    width={chartWidth}
                    height={chartHeight}
                />
                </div>
        </div>
    </>
    )
};
