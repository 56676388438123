import { doc, getDoc } from "firebase/firestore";
import { dbGym, db } from "../../config/firebase";
import { gymList } from "../../components/Databases";
import { getDocs, collection, updateDoc, writeBatch } from "firebase/firestore";


/**
 * Gets the Gym Profile
 * @param {String} gymID - ID of the gym profile to fetch
 * @returns {Object|null} - Gym profile data, or null if not found
 */
export const getGymProfile = async (gymID) => {
    try {
        const userRef = doc(dbGym, gymList, gymID);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
            const gymData = docSnap.data();
            const city = gymData.city;
            const gymName = gymData.gymName;


            const gymRef = doc(db, "Cities", city, "Gyms", gymName);
            const gymDoc = await getDoc(gymRef);

            if (gymDoc.exists()) {
                return gymDoc.data();
            } else {
                console.log("No such gym document in the specified city!", city, gymName);
                return null;
            }
        } else {
            console.log("No such gym document in gym list!");
            return null;
        }
    } catch (error) {
        console.error("Error fetching gym profile:", error.message);
        return null;
    }
};

/**
 * Updates the Gym Pricing
 * @param {String} gymID - ID of the gym profile to fetch
 * @param {Object} pricingData - New gym pricing data
 * @returns {void} - None
 */
export const updateGymPricing = async (gymID, pricingData) => {
    const batch = writeBatch(db); 

    try {
        const userRef = doc(dbGym, gymList, gymID);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
            const gymData = docSnap.data();
            const city = gymData.city;
            const gymName = gymData.gymName;

            const gymsRef = collection(db, "Cities", city, "Gyms");
            const gymsSnapshot = await getDocs(gymsRef);

            gymsSnapshot.forEach((gymDoc) => {
                if (gymDoc.id === gymName) {
                    const gymRef = gymDoc.ref;
                    batch.update(gymRef, {
                        prices: pricingData,
                    });
                }
            });
            await batch.commit();
            console.log("Gym pricing updated successfully.");
        } else {
            console.log("No such gym document in gym list!");
        }
    } catch (error) {
        console.error("Error updating gym pricing:", error.message);
    }
}

/**
 * Updates the Gym Facilities
 * @param {String} gymID - ID of the gym profile to fetch
 * @param {Object} facilitiesData - New gym facilities data
 * @returns {void} - None
 */
export const updateGymFacilities = async (gymID, facilitiesData) => {
    const batch = writeBatch(db); 

    try {
        const userRef = doc(dbGym, gymList, gymID);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
            const gymData = docSnap.data();
            const city = gymData.city;
            const gymName = gymData.gymName;

            const gymsRef = collection(db, "Cities", city, "Gyms");
            const gymsSnapshot = await getDocs(gymsRef);

            gymsSnapshot.forEach((gymDoc) => {
                if (gymDoc.id === gymName) {
                    const gymRef = gymDoc.ref;
                    batch.update(gymRef, {
                        facilities: facilitiesData,
                    });
                }
            });
            await batch.commit();
            console.log("Gym facilities updated successfully.");
        } else {
            console.log("No such gym document in gym list!");
        }
    } catch (error) {
        console.error("Error updating gym facilities:", error.message);
    }
}

/**
 * Updates the Gym Equipments
 * @param {String} gymID - ID of the gym profile to fetch
 * @param {Object} equipmentData - New gym equipment data
 * @returns {void} - None
 */
export const updateGymEquipments = async (gymID, equipmentData) => {
    const batch = writeBatch(db);

    try {
        const userRef = doc(dbGym, gymList, gymID);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
            const gymData = docSnap.data();
            const city = gymData.city;
            const gymName = gymData.gymName;

            const gymsRef = collection(db, "Cities", city, "Gyms");
            const gymsSnapshot = await getDocs(gymsRef);

            gymsSnapshot.forEach((gymDoc) => {
                if (gymDoc.id === gymName) {
                    const gymRef = gymDoc.ref;
                    batch.update(gymRef, {
                        equipmentList: equipmentData,
                    });
                }
            });
            await batch.commit();
            console.log("Gym equipments updated successfully.");
        } else {
            console.log("No such gym document in gym list!");
        }
    } catch (error) {
        console.error("Error updating gym equipments:", error.message);
    }
}