import { collection, addDoc, getDocs, setDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { getTime, getDate } from '../basicHelper';
import { cityList,gymList } from '../../components/Databases';

export const getRandomDate = (flag = 0) => {
    const randomDate = Math.floor(Math.random() * 3) + 29
    var randomMonth, randomYear;
    // Membership Since
    if (flag == 0) {
        randomMonth = Math.floor(Math.random() * 6) + 1
        randomYear = Math.floor(Math.random() * 2) + 2023
    }
    // DOB
    else if (flag == 2) {
        randomMonth = Math.floor(Math.random() * 12) + 1
        randomYear = Math.floor(Math.random() * 5) + 1999
    }
    // Membership Till
    else {
        randomMonth = Math.floor(Math.random() * 5) + 8
        randomYear = Math.floor(Math.random() * 2) + 2024
    }
    return `${randomDate}-${randomMonth}-${randomYear}`
}

/*
 * THIS IS A TEST FUNCTION
 * Marks user present in the Gym's list
 * @param   :   Accepts a dictionary with fields -
 *                  gymName,
 *                  userName,
 *                  userID,
 *                  City 
 * 
 * @return  :   Bool; true if successful, false otherwise
 */
export const markPresent = async (info) => {
    console.log('markPresent', info);
    console.log(info.city);
    console.log(info.userName);
    console.log(info.userID);
    console.log(info.gymName);
    const randomDate = getRandomDate()
    console.log(randomDate);
    console.log(getTime());
    try {
        console.log('Stage 1 : ', db);
        /*
         * Database Flow :
         * Cities -> {City} -> Gyms -> {Gym Name} -> Calendar -> {Date} -> Attendance -> 
         * Document :
         * userName, userID, InTime
         */
        const gymBasedAttendanceRef = collection(db, cityList, info.city, gymList, info.gymName, 'Calendar', randomDate, 'Attendance');
        

        // const gymBasedAttendanceRef = collection(db, gymList);
        console.log('Stage 2', '12');

        const docRef = await addDoc(gymBasedAttendanceRef, {
            'userName'  :   info.userName,
            'userID'    :   info.userID,
            'inTime'    :   getTime(),
            'date'      :   randomDate,
        } );

        // Update the total number of attendance
        const gymRef = doc(collection(db, cityList, info.city, gymList, info.gymName, 'Calendar'), randomDate);
        const gymDoc = await getDoc(gymRef);

        if (gymDoc.exists()) {
            const gymData = gymDoc.data();
            const totalUsersAttendance = gymData.totalUsersAttendance || 0;
            console.log('totalUsersAttendance:', totalUsersAttendance);

            await updateDoc(gymRef, {
                'totalUsersAttendance': totalUsersAttendance + 1
            });
        } else {
            // Document does not exist, create it with totalUsersAttendance set to 1
            console.log('Document does not exist. Creating with totalUsersAttendance: 1');
            await setDoc(gymRef, {
                'totalUsersAttendance': 1
            });
        }

        console.log('Document Added with id : ', docRef.id);
        return true;

    } catch (error) {
        console.error('Error adding document: ', error);
        return false;
    }
}



// -------------------------------------------------------------------
// -------------------------------------------------------------------
// -------------------------------------------------------------------
// -------------------------------------------------------------------
// -------------------------------------------------------------------


/*
 * Fetches attendance data date-wise from Firebase
 */
export const fetchDateWiseData = async () => {
    console.log('Calling fetchDateWiseData');
    const dateWiseData = [];
    
    try {
        const citiesSnapshot = await getDocs(collection(db, cityList));

        if (citiesSnapshot.empty) {
            return dateWiseData;
        }

        for (const cityDoc of citiesSnapshot.docs) {
            const city = cityDoc.id;

            const gymsSnapshot = await getDocs(collection(db, cityList, city, gymList));

            if (gymsSnapshot.empty) {
                continue;
            }

            for (const gymDoc of gymsSnapshot.docs) {
                const gymName = gymDoc.id;

                const calendarSnapshot = await getDocs(collection(db, cityList, city, gymList, gymName, 'Calendar'));

                if (calendarSnapshot.empty) {
                    continue;
                }

                for (const calendarDoc of calendarSnapshot.docs) {
                    const date = calendarDoc.id;
                    const calendarData = calendarDoc.data();
                    const userCount = calendarData.totalUsersAttendance || 0;

                    let dateData = dateWiseData.find(item => item.date === date);

                    if (dateData) {
                        dateData.users += userCount;
                    } else {
                        dateWiseData.push({
                            date: date,
                            users: userCount
                        });
                    }
                }
            }
        }

        return dateWiseData;
    } catch (error) {
        console.error('Error fetching date-wise data: ', error);
        return dateWiseData;
    }
};

/*
 * Fetches attendance data city-wise from Firebase
 */
export const fetchCityWiseData = async () => {
    console.log('Calling fetchCityWiseData');
    const cityWiseData = [];
    
    try {
        const citiesSnapshot = await getDocs(collection(db, cityList));

        if (citiesSnapshot.empty) {
            return cityWiseData;
        }

        for (const cityDoc of citiesSnapshot.docs) {
            const city = cityDoc.id;
            let userCount = 0;

            const gymsSnapshot = await getDocs(collection(db, cityList, city, gymList));

            if (gymsSnapshot.empty) {
                continue;
            }

            for (const gymDoc of gymsSnapshot.docs) {
                const gymName = gymDoc.id;

                const calendarSnapshot = await getDocs(collection(db, cityList, city, gymList, gymName, 'Calendar'));

                if (calendarSnapshot.empty) {
                    continue;
                }

                for (const calendarDoc of calendarSnapshot.docs) {
                    const calendarData = calendarDoc.data();
                    userCount += calendarData.totalUsersAttendance || 0;
                }
            }

            cityWiseData.push({
                city: city,
                users: userCount
            });
        }

        return cityWiseData;
    } catch (error) {
        console.error('Error fetching city-wise data: ', error);
        return cityWiseData;
    }
};