import { cloud_url } from "../assets/links";
import { getUserCountryCode } from "./userLocationHelpers";

/**
 * @return  :   current time
 */
export const getTime = () => {
    const now = new Date();

    const isoString = now.toISOString();
    return isoString.slice(0, -1) + 'Z'; // Remove the last character (Z) and append it again to ensure consistent format;
}

/**
 * Returns today's date
 * @format  : YYYY-MM-DD
 * @return  : Today's Date
 */
export const getDate2 = () => {
    const now = new Date();

    // Get Today's Date 
    return now.toISOString().split('T')[0];  // Outputs: '2024-05-22'
}

/**
 * Returns a Date object for today's date in DD-MM-YYYY format with the current time
 * @return {Date} - Date object
 */
export const getDate3 = () => {
    const now = new Date();

    // Extract the current date components using toLocaleString
    const day = String(now.toLocaleString('en-GB', { day: '2-digit' }));
    const month = String(now.toLocaleString('en-GB', { month: '2-digit' }));
    const year = now.toLocaleString('en-GB', { year: 'numeric' });

    // Extract current time components using native methods
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const milliseconds = now.getMilliseconds();

    // Create a Date object with the current date and time
    const timestamp = new Date(year, parseInt(month) - 1, parseInt(day), hours, minutes, seconds, milliseconds);

    return timestamp; // Returns Date object with the current date and time
};

/**
 * Returns today's date
 * @format  : DD-MM-YYYY
 * @return  : Today's Date
 */
export const getDate = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = now.getFullYear();

    return `${day}-${month}-${year}`;  // Outputs: '22-05-2024'
}

/**
 * Returns today's date
 * @format  : DD-MM-YYYY
 * @return  : Today's Date
 */
export const getDateYYYYMMDD = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = now.getFullYear();

    return `${year}-${month}-${day}`;  // Outputs: '22-05-2024'
}



/**
 * Gets Year Month and Date individually
 * @param   : void
 * @return  : Year, Month and Date
 */
export const getCalendar = () => {
    const now = new Date();
    
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const date = String(now.getDate()).padStart(2, '0');

    // Combine year, month, and date
    return {year, month, date};
}

/**
 * Returns the current Year
 * @param {void}
 * @returns {String} - Current Year
 */
export const getCurrentYear = () => {
    const currentYear = new Date().getFullYear();
    return currentYear.toString();
}

/**
 * Gets the current month in a string format (e.g., "August").
 * @returns {string} The current month.
 */
export const getCurrentMonth = () => {
    const months = [
        "January", "February", "March", "April", "May", "June", 
        "July", "August", "September", "October", "November", "December"
    ];
    const currentMonthIndex = new Date().getMonth();  // getMonth() returns 0-11
    return months[currentMonthIndex];
};

/**
 * Refines the Data according to the database
 */
export const refineData = (myData) => {
    const refinedData = { ...myData }; // Create a shallow copy of myData

    if (myData.city) {
        refinedData.city = myData.city.toLowerCase().replace(/\s+/g, '');
    }
    if (myData.gymName) {
        refinedData.gymName = myData.gymName.toLowerCase().replace(/\s+/g, '');
    }
    return refinedData;
};

/**
 * Capitalises first letter of every word
 * @param {String} word
 * @returns {String} Capitalised Word
 */
export const capitalizeFirstLetter = (word) => {
    return word.replace(/\b\w/g, char => char.toUpperCase());
}

/**
 * Formats everyword by deleting spaces and converting to lowercase letters
 * @param {String} word
 * @returns {String} formatted word
 */
export const formatWordToLowerCase = (word) => {
    return word.toLowerCase().replace(/\s+/g, '');
}

/**
 * Converts a date string from YYYY-MM-DD format to DD-MM-YYYY format.
 * This is done for the FetchAttendance Feature
 * @param {string} dateStr - Date string in YYYY-MM-DD format.
 * @returns {string} - Date string in DD-MM-YYYY format.
 */
export const convertDateToDDMMYYYY = (dateStr) => {
    try {
        const [year, month, day] = dateStr.split("-");
        return `${day}-${month}-${year}`;
    } catch (error) {
        console.error('Error : ' , error);
    }
};

export const getDDMMYYYYFromTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    
    // Extract the day, month, and year
    const day = String(date.getDate()).padStart(2, '0'); // Pad single-digit days with a leading zero
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so we add 1
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
}

/**
 * Truncates Text
 * @param {String} text 
 * @param {Number} maxLength 
 * @returns {String}            -   Truncated Text
 */
export const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + '...';
};

export const getNextWeekwithWeekdays = (date) => {
    const getNextDay = (date, daysToAdd) => {
        const [day, month, year] = date.split('-').map(Number);
        const currentDate = new Date(year, month - 1, day);
    
        currentDate.setDate(currentDate.getDate() + daysToAdd);
    
        const nextDay = String(currentDate.getDate()).padStart(2, '0');
        const nextMonth = String(currentDate.getMonth() + 1).padStart(2, '0');
        const nextYear = currentDate.getFullYear();
        const weekDay = currentDate.toLocaleDateString('en-US', { weekday: 'long' });
    
        return `${nextDay}-${nextMonth}-${nextYear} (${weekDay})`;
    };
    
    const next7Days = [
        {
            date: date,
            weekDay: new Date(date.split('-').reverse().join('-')).toLocaleDateString('en-US', { weekday: 'long' }),
        }
    ];
    
    for (let i = 1; i < 7; i++) {
        const nextDate = getNextDay(date, i);
        next7Days.push({
            date: nextDate.split(' ')[0],
            weekDay: nextDate.split(' ')[1].replace(/[\(\)]/g, ''),
        });
    }
    
    return next7Days;
}

// Helper function to parse date string into Date object
export const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('-');
    return new Date(`${year}-${month}-${day}`);
};

/**
 * Generates a random string
 *
 * @param {number} [length=20] - The length of the generated string. Default is 20 characters.
 * @returns {string} - A randomly generated string.
 */
export const generateRandomStringId = (length = 12) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
};

export const getCountryPhoneCode = async () => {
    const userCountryCode = await getUserCountryCode();
    console.log('Code : ', userCountryCode);
    switch (userCountryCode) {
        case 'IN' :
            return '+91';

        case 'UK' :
            return '+44';

        default :
            return '+1';
    }
}

// Function to handle sending personalized messages via SMS or Email
export const handleSendPersonalizedMessage = async (sendMethod, recipient, routingFrom) => {
    console.log(0 )
    try {
        console.log(1,sendMethod, recipient, routingFrom )
        // Retrieve the message data based on the routing source (e.g., 'gymCred')
        const messageData = onboardingMessages[0][routingFrom];
        let subject = '';  // Placeholder for the email subject
        let message = '';  // Placeholder for the message content

        // Determine the content based on the message type (SMS or Email)
        if (sendMethod === 'sms') {
            message = messageData.sms;  // Set the SMS message content
        } else if (sendMethod === 'email') {
            subject = messageData.email.subject;  // Set the email subject
            message = messageData.email.body;  // Set the email message body
        }

        // Send the message by making a POST request to the server
        const response = await fetch(cloud_url/`send_bulk_${sendMethod}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            // Pass the recipient details and message content in the request body
            body: JSON.stringify({ to: [recipient], subject: subject, message: message }),
        });

        // Check for successful message sending
        if (response.ok) {
            console.log(`${sendMethod.toUpperCase()} sent successfully to ${recipient}`);
        } else {
            // Log any errors if the message fails to send
            const errorMessage = await response.text();
            console.error(`Failed to send ${sendMethod}. Status: ${response.status}, Error: ${errorMessage}`);
        }
    } catch (error) {
        // Catch and log any errors during message sending
        console.error(`Failed to send ${sendMethod} message:`, error);
        alert(`Failed to send ${sendMethod} message`);
    }
};

// Array of onboarding messages, categorized by message type (e.g., 'gymCred')
const onboardingMessages = [
    {
        gymCred: {
            // SMS message for welcoming gym partners
            sms: "🎉 Congratulations on partnering with Train Rex! We're thrilled to join forces and grow together. Our team is committed to helping your gym reach new heights, and we look forward to a great journey with you. Let’s make fitness better, together! 💪 - The Train Rex Team",
            
            // Email message for welcoming gym partners (Can add their names tbh, would seem more interpersonal)
            email: {
                subject: "Welcome to the Train Rex Family!",  // Email subject line
                body: `Hello,

We’re thrilled to welcome you to the Train Rex community! Congratulations on taking this step toward a new chapter of success and growth for your gym.

At Train Rex, we’re committed to working alongside you to build a thriving business and offer unparalleled experiences to fitness enthusiasts. Together, we’re going to make fitness more accessible and engaging. Our team is here to support you every step of the way.

Let’s get started and make a difference in the fitness world—one workout at a time!

Warm regards,  
The Train Rex Team`
            }
        }
    }
];

