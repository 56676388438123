import { addDoc, collection, getDocs, query, where } from "@firebase/firestore";
import { db } from "../../../config/firebase";
import { cityList, expenseList, financeList, gymList, staffList } from "../../../components/Databases";
import { getCurrentYear } from "../../basicHelper";
import { updateTotalFinance } from "./AddRevenueHelper";

/**
 * Checks if the staff exists in the database or not
 * @param {String} email - email of the staff member
 * @returns {Object} - details of the staff member, if the staff exists; false otherwise
 */
export const checkIfStaffExists2 = (email) => {
    console.log('checkIfStaffExists');
    if (email == "yashshubh2000@gmail.com")
        return {
            staffName : "Yash",
            age : 23,
            gender : "Male",
            position : "Owner",
            staffExists : true
        }
    else 
        return {staffExists : false};
}

/**
 * Checks if the staff exists in the database or not
 * @param {String} email - email of the staff member
 * @param {Object} gymData - has gym details
 * @returns {Object} - details of the staff member, if the staff exists; false otherwise
 */
export const checkIfStaffExists = async (email, gymData) => {
    try {
        let staffName = "";
        let position  = "";
        let staffID = "";

        const staffRef = collection(db, cityList, gymData.city, gymList, gymData.gymName, staffList)
        const staffQuery = query(staffRef, where('staffEmail', '==', email));
        const staffSnap = await getDocs(staffQuery);

        if(!staffSnap.empty) {
            const staffDoc = staffSnap.docs[0];
            const staffData = staffDoc.data();
            staffID = staffDoc.staffID || "";
            staffName = staffData.staffName;
            position = staffData.position;
        };

        return {
            staffExists  :   !staffSnap.empty,
            staffID     :   staffID,
            staffName   :   staffName,
            email       :   email,
            position    :   position
        };

    } catch (error) {
        return {staffExists : false};
    }
}

/**
 * Saves Expense in Database
 * @param {String} expenseType - Stores the type of expense
 * @param {Object} myData - Data containing all the inpur fields
 * @returns {Boolean} - True if save successful; false otherwise
 */
export const saveExpense = async (expenseType, myData) => {
    // console.log('saveExpense');

    await updateTotalFinance('Expense', myData);

    let myBool = false;
    try {
        const expenseRef = collection(db, cityList, myData.city, gymList, myData.gymName, financeList, getCurrentYear(), expenseList);
        // Handling different types of cases
        switch (expenseType) {
            // Salary has a unique data structure
            case "Salary" :
                myBool = await addSalary(myData, expenseRef);
                break;

            // Misc and Maintenance have similar data structures
            default :
                myBool = await addOtherExpense(myData, expenseRef, expenseType);
                break;
        }
        // If save succesfful, display the correct message
        if (myBool) {
            alert('Expenses added successfully')
            return true;
        }
        // Else display Failure message
        else {
            alert('Failed to add expense!');
            return false;
        }

    } catch (error) {
        console.log('Error : ', error);
        return false;
    }
}

/**
 * Saves Salary in Database
 * @param {Object} myData - Data containing all the inpur fields
 * @param {Reference Object} expenseRef - Firebase Reference Object
 * @returns {Boolean} - True if save successful; false otherwise
 */
const addSalary = async (myData, expenseRef) => {
    try {
        await addDoc (expenseRef, {
            expenseType :   "Salary",
            orderType   :   "Salary",
            amount      :   myData.amount,
            staffEmail  :   myData.email,
            staffName   :   myData.staffName,
            salaryMonth :   myData.salaryMonth,
            position    :   myData.position,
            timestamp   :   new Date().toISOString(),
            displayName :   myData.staffName,
            financeType :   "Expense",
        })
        return true
    } catch (error) {
        console.log('Error : ', error);
        return false;
    }

}

/**
 * Saves other expenses in Database
 * @param {Object} myData - Data containing all the inpur fields
 * @param {Reference Object} expenseRef - Firebase Reference Object
 * @param {String} expenseType - Other Reference Type
 * @returns {Boolean} - True if save successful; false otherwise
 */
const addOtherExpense = async (myData, expenseRef, expenseType) => {
    try {
        await addDoc (expenseRef, {
            expenseType :   expenseType,
            orderType   :   expenseType,
            amount      :   myData.amount,
            staffEmail  :   myData.staffEmail,
            staffName   :   myData.staffName,
            position    :   myData.position,
            timestamp   :   new Date().toISOString(),
            displayName :   myData.details,
            financeType :   "Expense",
        })
        return true;

    } catch (error) {
        console.log('Error : ', error);
        return false;
    }
}