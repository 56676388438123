import React, { useEffect, useState } from "react";
import { themeColors } from "../../../theme";
import RingGraph from "../../../components/RingGraph";
import { getGenderData } from "./FetchMembersData";

/**
 * Renders a Graph according to the Gender Data
 * @param {Object} gymData - Contains information about the gym
 * @param {String} legendLayout = Contains the type of layout needed 
 * @returns 
 */
export const GenderGraph = ({ gymData, legendLayout = 'horizontal' }) => {
    // Count the occurrences of each gender
    const [genderData, setGenderData] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            const data = await getGenderData(gymData);
            setGenderData(data);
        };
        fetchData();
    }, [gymData]);

    const totalMembers = genderData.reduce((acc, curr) => acc + curr.value, 0);
  
    // Define colors for the chart
    const COLORS = ['#768085', '#9EBC80', '#2f2d2d'];

    const strokeColor = themeColors.lightAccent;
    const strokeWidth = 4;
    const centerLabel = false;

    // Responsive dimensions for the chart
    const chartWidth = '100%';
    const chartHeight = '100%';
    const innerRadius = '0%';
    const outerRadius = '80%';

    return (
        <>

        {/* this is the first graph on the page, it shows % of male, female and other members */}

        {/* <PieChartGraph data={data} colors={COLORS} dataKey="value" nameKey="name" legendLayout={legendLayout} />; */}
        {/* previous styles : style={{ width: '100%', maxWidth: '400px'}} */}
        <div 
            className="flex flex-col rounded-3xl border-secondary border-2 py-2 justify-between"
            
        >
            <p className="text-lg text-secondary font-bold text-left px-4">Total Members : {totalMembers}</p>
            {/* previous styles style={{ width: '100%', height: '300px' }} */}
            <div className="w-100 h-[300px] lg:w-[17vw] lg:h-[36vh] ">
                <RingGraph 
                    data={genderData}
                    colors={COLORS}
                    innerRadius={innerRadius} 
                    outerRadius={outerRadius} 
                    strokeColor={strokeColor} 
                    strokeWidth={strokeWidth}
                    legendLayout={legendLayout}
                    wantCenterLabel={centerLabel}
                    width={chartWidth} 
                    height={chartHeight}
                />
                </div>
        </div>
    </>
    )
};