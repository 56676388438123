import React, { useState, useEffect } from 'react';
import { getUserAttendanceData, sortData, formatTime } from '../../../helperFunctions/DashboardScenesHelpers/UserAttendanceDataHelper';
import { TextField, IconButton } from '@mui/material';
import { CalendarToday, ArrowForward, ArrowBack, Search } from '@mui/icons-material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import UserAttendanceModal from '../../../helperFunctions/DashboardScenesHelpers/userAttendanceModal';

/**
 * Component to display user attendance data.
 * It includes a date selector, search functionality, and sortable columns.
 * @param {object} gymData - contains all the gym information; needed for manually marking user Present
 */
const UserAttendance = (gymData) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [attendanceData, setAttendanceData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [sortConfig, setSortConfig] = useState({ key: 'inTime', direction: 'descending' });
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchData(selectedDate);
  }, [selectedDate]);

  /**
   * Fetches attendance data for the specified date.
   * @param {Date} date - The selected date.
   */
  const fetchData = async (date) => {
    const data = await getUserAttendanceData(date);
    setAttendanceData(sortData(data, sortConfig.key, sortConfig.direction));
  };

  /**
   * Handles the change of selected date.
   * @param {Date} date - The new selected date.
   */
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  /**
   * Handles the search query input change.
   * @param {Event} e - The input change event.
   */
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  /**
   * Handles sorting of table columns.
   * @param {string} key - The column key to sort by.
   */
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    setAttendanceData(sortData([...attendanceData], key, direction));
  };

  /**
   * Handles the opening of the modal.
   */
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  /**
   * Handles the closing of the modal.
   */
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const filteredData = attendanceData.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const itemsPerPage = 10;
  const startIndex = currentPage * itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, startIndex + itemsPerPage);

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">User Attendance</h1>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            customInput={
              <TextField
                variant="outlined"
                label="Select Date"
                InputProps={{
                  endAdornment: <CalendarToday />,
                }}
                className="bg-white border border-gray-300 rounded-md"
              />
            }
          />
          <IconButton onClick={() => handleDateChange(new Date(selectedDate.setDate(selectedDate.getDate() - 1)))}>
            <ArrowBack />
          </IconButton>
          <IconButton onClick={() => handleDateChange(new Date(selectedDate.setDate(selectedDate.getDate() + 1)))}>
            <ArrowForward />
          </IconButton>
        </div>
        <div className="flex items-center">
          <TextField
            variant="outlined"
            label="Search"
            InputProps={{
              endAdornment: <Search />,
            }}
            value={searchQuery}
            onChange={handleSearch}
            className="px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
          />
        </div>
      </div>
      <table className="min-w-full bg-white">
        <thead className="bg-tertiary">
          <tr>
            {['name', 'workout', 'inTime', 'outTime'].map((column) => (
              <th key={column} className="py-2 px-4 text-left">
                <button onClick={() => handleSort(column)}>
                  {column.charAt(0).toUpperCase() + column.slice(1)}{' '}
                  {sortConfig.key === column && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                </button>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((item, index) => (
            <tr key={index} className={index % 2 === 0 ? 'bg-secondary' : 'bg-tertiary'}>
              <td className="py-2 px-4">{item.name}</td>
              <td className="py-2 px-4">{item.workout}</td>
              <td className="py-2 px-4">{formatTime(item.inTime)}</td>
              <td className="py-2 px-4">{formatTime(item.outTime)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-between mt-4">
        <IconButton
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 0}
        >
          <ArrowBack />
        </IconButton>
        <IconButton
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={startIndex + itemsPerPage >= filteredData.length}
        >
          <ArrowForward />
        </IconButton>
      </div>
      <div>
        <button 
            onClick={handleOpenModal}
            className="px-4 py-2 bg-secondary text-black rounded-md hover:bg-primary hover:text-tertiary"
          >
            Mark Attendance Manually
        </button>
      </div>
      <UserAttendanceModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        gymData={gymData}
      />
    </div>
  );
};

export default UserAttendance;
