import { collection, doc, FieldPath, getDoc, getDocs, query, where } from "@firebase/firestore";
import { db } from "../../../config/firebase";
import { cityList, gymList, userList } from "../../../components/Databases";

/**
 * Returns the gender data array, excluding the member with doc ID 'testUser'.
 * @param {Object} gymData - Contains the gym details
 * @returns {Array} Array of gender distribution (Male, Female, Others)
 */
export const getGenderData = async (gymData) => {
    let male = 0;
    let female = 0;
    let others = 0;

    const genderRef = collection(db, cityList, gymData.city, gymList, gymData.gymName, userList);
    const snapshot = await getDocs(genderRef);
    
    snapshot.forEach(doc => {
        // Skip the member with doc ID 'testUser'
        if (doc.id === 'testUser') {
            return;
        }

        const { gender, status } = doc.data();

        if (!status)
            return;

        if (gender === 'Male') {
            male += 1;
        } else if (gender === 'Female') {
            female += 1;
        } else {
            others += 1;
        }
    });

    return [
        { name: 'Male', value: male },
        { name: 'Female', value: female },
        { name: 'Others', value: others }
    ];
};

/**
 * Returns the age data array, excluding the member with doc ID 'testUser'.
 * @param {Object} gymData - Contains the gym details
 * @returns {Array} Array of age distribution in specified ranges
 */
export const getAgeData = async (gymData) => {
    let ageGroup1 = 0; // 0-18
    let ageGroup2 = 0; // 18-28
    let ageGroup3 = 0; // 28-45
    let ageGroup4 = 0; // 45-60
    let ageGroup5 = 0; // 60+

    const ageRef = collection(db, cityList, gymData.city, gymList, gymData.gymName, userList);
    const snapshot = await getDocs(ageRef);

    snapshot.forEach(doc => {
        // Skip the member with doc ID 'testUser'
        if (doc.id === 'testUser') {
            return;
        }

        const { age, status } = doc.data();

        if (!status)
            return;

        if (age >= 0 && age <= 18) {
            ageGroup1 += 1;
        } else if (age <= 28) {
            ageGroup2 += 1;
        } else if (age <= 45) {
            ageGroup3 += 1;
        } else if (age <= 60) {
            ageGroup4 += 1;
        } else if (age > 60) {
            ageGroup5 += 1;
        }

    });

    return [
        { name: '0-18', value: ageGroup1 },
        { name: '19-28', value: ageGroup2 },
        { name: '29-45', value: ageGroup3 },
        { name: '46-60', value: ageGroup4 },
        { name: '60+', value: ageGroup5 }
    ];
};

/**
 * Gets the list of gym's members
 * @param {Object} gymData  - An object containing:
 *                          - city (String): The city where the gym is located
 *                          - gymName (String): The name of the gym
 * @returns {Array} - A list of user objects where 'userID' is not '000'
 */
export const getMemberData = async(gymData) => {
    try {
        // Create a reference to the gym's user collection
        const gymRef = collection(db, cityList, gymData.city, gymList, gymData.gymName, userList);
        
        // Execute the query and get the snapshot of documents
        const querySnapshot = await getDocs(gymRef);
        
        // Initialize an array to hold the user data
        const users = [];
        
        // Iterate through the query results and push each user's data to the users array
        querySnapshot.forEach((doc) => {
            if (doc.id != 'testUser') {
                users.push(doc.data());
            }
        });
        
        // Return the list of users
        return users;
  
    } catch (error) {
        console.error('Error getting gym members:', error);
        return [];
    }
};

/**
 * Returns an array of date and totalUsersAttendance on that date
 * This is from the 1st of the current month to the current date
 * @param {Object} gymData - Contains gym information
 * @returns {Array} attendanceList - Array of objects containing date and totalUsersAttendance
 */
export const getTotalMonthsAttendance = async (gymData) => {
    const today = new Date(); // Get today's date
    const month = today.getMonth() + 1; // getMonth() returns 0 for January, so we add 1
    const year = today.getFullYear();
    const currentMonth = month < 10 ? `0${month}-${year}` : `${month}-${year}`; // Ensure month is in "MM-YYYY" format

    const attendanceList = [];
    
    for (let day = 1; day <= today.getDate(); day++) {
        const formattedDay = day < 10 ? `0${day}` : `${day}`; // Ensure day is in "dd" format
        const dateKey = `${formattedDay}-${currentMonth}`;
        
        // Access the document for each specific date
        const dateDocRef = doc(db, cityList, gymData.city, gymList, gymData.gymName, 'Calendar', dateKey);
        const docSnapshot = await getDoc(dateDocRef);
        
        if (docSnapshot.exists()) {
            const totalUsers = docSnapshot.data().totalUsersAttendance || 0;
            attendanceList.push({ date: dateKey, totalUsers });
        } else {
            attendanceList.push({ date: dateKey, totalUsers: 0 }); // If no document exists for that date, set attendance to 0
        }
    }

    return attendanceList;
};

/**
 * THIS IS A TEMP FUNCTION
 * SORT THIS OUT BY ADDING A YYYY-MM-DD DATE FORMAT IN THE DATE AS WELL
 * 
 * 
 * Returns an array of date and totalUsersAttendance on that date.
 * This is from the 1st of the current month to the current date.
 * @param {Object} gymData - Contains gym information.
 * @returns {Array} attendanceList - Array of objects containing date and totalUsersAttendance.
 */
export const getTotalMonthsAttendance2 = async (gymData) => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const currentMonth = month < 10 ? `0${month}-${year}` : `${month}-${year}`;

    const attendanceList = [];

    // Query to get all attendance documents for the current month
    const attendanceCollection = collection(db, cityList, gymData.city, gymList, gymData.gymName, 'Calendar');
    const attendanceQuery = query(
        attendanceCollection,
        where("__name__", ">=", `01-${currentMonth}`),
        where("__name__", "<=", `${today.getDate()}-${currentMonth}`)
    );

    const querySnapshot = await getDocs(attendanceQuery);

    querySnapshot.forEach(docSnapshot => {
        const date = docSnapshot.id; // Assuming the document ID is the date in "dd-MM-YYYY" format
        const totalUsers = docSnapshot.data().totalUsersAttendance || 0;
        attendanceList.push({ date, totalUsers });
    });
    return attendanceList;
};