// PTHelper.js

import { addDoc, collection, doc, getDocs, query, where } from "@firebase/firestore";
import { db } from "../../../config/firebase";
import { cityList, gymList, PTSessions, staffList, userList } from "../../../components/Databases";
import { getDate, getTime } from "../../basicHelper";

/**
 * Fetches the list of active users from the gym's database.
 * This function retrieves users who have a personal trainer assigned.
 * 
 * @param {Object} gymData      -   Information about the gym (contains city and gymName)
 * @returns {Promise<Array>}    -   A promise that resolves to the list of active users with personal trainers.
 */
export const fetchActiveUsers = async (gymData) => {
    const usersWithPT = []; // Initialize as an array to store users with personal trainers
    const userRef     = collection(db, cityList, gymData.city, gymList, gymData.gymName, userList);
    const userQuery   = query(userRef, where('personalTrainer', '!=', false));

    try {
        const userSnapshot = await getDocs(userQuery);

        // Return an empty list if no users have personal trainers
        if (userSnapshot.empty) {
            return usersWithPT;
        }

        // Otherwise, iterate through each document and add its data to the array
        userSnapshot.forEach(userDoc => {
            usersWithPT.push({
                userDocID: userDoc.id,   // Add the document ID
                ...userDoc.data()    // Spread the rest of the document data
            });
        });

        return usersWithPT; // Return the array of users
    } catch (error) {
        // console.error('Error fetching users with personal trainers:', error);
        return [];
    }
};

/**
 * Fetches the list of trainers from the staff list.
 * This function retrieves all staff and filters those whose position contains 'trainer'.
 * 
 * @param {Object} gymData - Information about the gym (contains city and gymName).
 * @returns {Promise<Array>} - A promise that resolves to the list of trainers.
 */
export const fetchTrainers = async (gymData) => {

    const trainers      = [];
    const staffRef      = collection(db, cityList, gymData.city, gymList, gymData.gymName, staffList);

    try {
        const staffSnapshot = await getDocs(staffRef);

        // Check if staffSnapshot is empty
        if (staffSnapshot.empty) {
            return trainers; // Return an empty array if no staff is found
        }

        // Iterate through each staff document and filter positions containing 'trainer' (case-insensitive)
        staffSnapshot.forEach(staffMember => {
            const staffData = staffMember.data();
            if (staffData.position.toLowerCase().includes('trainer')) {
                trainers.push({
                    ...staffData,
                    staffDocID   :   staffMember.id,
                });
            }
        });

        return trainers; // Return the array of trainers
    } catch (error) {
        console.error('Error fetching trainers:', error);
        return []; // Return empty array if there's an error
    }
};

/**
 * Submits the PT Session form
 * Adds the session data to both the User and Gym Databases
 * 
 * @param   {Object} gymData    - Contains information about the gym (city and gymName)
 * @param   {Object} formDeets  - Contains form details (userDocID, staffDocID, userName, staffName, workout)
 * @returns {Boolean}           - True, store successful; false, otherwise
 */
export const handlePTSessionSubmit = async (gymData, formDeets) => { 
    try {
        // Storing receipt in user's database
        const gymRef        =   doc(db, cityList, gymData.city, gymList, gymData.gymName);
        if (
            await storePTSessionForClient(gymRef, formDeets) &&
            await storePTSessionForStaff(gymRef, formDeets)
        ) {
            alert('Session Added!');
            return true;
        }
    } catch (error) {
        // console.log('Error in handlingPTSubmit : ', error);
        alert('Error Adding Session. \nPlease try again later!');
    }
    return false;
}

/**
 * Add to User's Database
 * @param {Reference}   gymRef      -   Reference of the gym
 * @param {Object}      formDeets   -   Entries of the form
 * @param {Bool}                    -   true; if succesfful, false; otherwise
 */
const storePTSessionForClient = async(gymRef, formDeets) => {
    try {
        const userRef   =   collection(gymRef, userList, formDeets.userDocID, PTSessions);
        await addDoc(userRef, {
            // userEntries
            userName            :   formDeets.userName,
            userEmail           :   formDeets.userEmail || '',
            userPhoneNumber     :   formDeets.userPhoneNumber,
            // Trainer Entries
            staffName           :   formDeets.staffName,
            staffEmail          :   formDeets.staffEmail || '',
            staffPhoneNumber    :   formDeets.staffPhoneNumber,
            // General Entries
            timestamp           :   getTime(),
            date                :   getDate(),
            workout             :   formDeets.workouts || '',
        })
        return true;

    } catch (error) {
        return false;
    }
}

/**
 * Add to Staff's Database
 * @param {Reference}   gymRef      -   Reference of the gym
 * @param {Object}      formDeets   -   Entries of the form
 * @param {Bool}                    -   true; if succesfful, false; otherwise
 */
const storePTSessionForStaff = async(gymRef, formDeets) => {
    try {
        const userRef   =   collection(gymRef, staffList, formDeets.staffDocID, PTSessions);
        await addDoc(userRef, {
            // userEntries
            userName            :   formDeets.userName,
            userEmail           :   formDeets.userEmail || '',
            userPhoneNumber     :   formDeets.userPhoneNumber,
            // Trainer Entries
            staffName           :   formDeets.staffName,
            staffEmail          :   formDeets.staffEmail || '',
            staffPhoneNumber    :   formDeets.staffPhoneNumber,
            // General Entries
            timestamp           :   getTime(),
            date                :   getDate(),
            workout             :   formDeets.workouts || '',
        })
        return true;

    } catch (error) {
        return false;
    }
}

/**
 * Fetches the workout list
 * @returns {List} - all workout types
 */
export const fetchWorkoutList = () => {
    return [
        { value: 'abs',      label: 'Abs'      },
        { value: 'back',     label: 'Back'     },
        { value: 'bicep',    label: 'Bicep'    },
        { value: 'cardio',   label: 'Cardio'   },
        { value: 'chest',    label: 'Chest'    },
        { value: 'hiit',     label: 'HIIT'     },
        { value: 'leg',      label: 'Leg'      },
        { value: 'shoulder', label: 'Shoulder' },
        { value: 'tricep',   label: 'Tricep'   },
        { value: 'other',    label: 'Other'    }
    ];
}