import { collection, getDocs } from "@firebase/firestore";
import { db } from "../../../config/firebase";
import { cityList, financeList, gymList, receiptList, revenueList, expenseList } from "../../../components/Databases";
import { getCurrentYear } from "../../basicHelper";

/**
 * Gets the revenue data for Revenue Graph and sums the amount by order type.
 * @param {Object} gymData - Contains Gym Data
 * @returns {Array} - Summed revenues categorized by order types
 */
export const getRevenueData = async (gymData) => {
    try {
        // Build reference to the Firestore collection
        const revenueRef = collection(
            db,
            cityList,
            gymData.city,
            gymList,
            gymData.gymName,
            financeList,
            getCurrentYear(),
            revenueList
        );

        // Fetch the documents from the Firestore collection
        const snapshot = await getDocs(revenueRef);
        const revenueData = snapshot.docs.map(doc => doc.data());

        // Initialize accumulators for different categories
        let gymMembershipTotal = 0;
        let classesTotal = 0;
        let othersTotal = 0;

        // Sum the amount for each category
        revenueData.forEach(revenue => {
            const { amount, orderType } = revenue;

            let amountToAdd = parseInt(amount,10) || 0;

            if (orderType === 'Gym Membership') {
                gymMembershipTotal += amountToAdd;
            } else if (orderType === 'Classes') {
                classesTotal += amountToAdd;
            } else {
                othersTotal += amountToAdd;
            }
        });

        // Return the result in the desired format
        return [
            { name: 'Gym Membership', value: gymMembershipTotal / 100 },
            { name: 'Classes', value: classesTotal / 100 },
            { name: 'Others', value: othersTotal / 100 }
        ];

    } catch (error) {
        console.log('Error : ', error);
        return [
            { name: 'Gym Membership', value: 0 },
            { name: 'Classes', value: 0 },
            { name: 'Others', value: 0 }
        ];
    }
};

/**
 * Gets the expense data for Expense Graph and sums the amount by expense type.
 * @param {Object} gymData - Contains Gym Data
 * @returns {Array} - Summed expenses categorized by expense types
 */
export const getExpenseData = async (gymData) => {
    try {
        // Build reference to the Firestore collection
        const expenseRef = collection(
            db,
            cityList,
            gymData.city,
            gymList,
            gymData.gymName,
            financeList,
            getCurrentYear(),
            expenseList
        );

        // Fetch the documents from the Firestore collection
        const snapshot = await getDocs(expenseRef);
        const expenseData = snapshot.docs.map(doc => doc.data());

        // Initialize accumulators for different categories
        let salaryTotal = 0;
        let maintenanceTotal = 0;
        let miscTotal = 0;

        // Sum the amount for each category
        expenseData.forEach(expense => {
            const { amount, expenseType } = expense;

            if (expenseType === "Salary") {
                salaryTotal += amount;
            } else if (expenseType === "Maintenance") {
                maintenanceTotal += amount;
            } else {
                miscTotal += amount;
            }
        });

        // Return the result in the desired format
        return [
            { name: 'Salary', value: salaryTotal / 100 },
            { name: 'Maintenance', value: maintenanceTotal / 100 },
            { name: 'Miscellaneous', value: miscTotal / 100 }
        ];

    } catch (error) {
        console.log('Error : ', error);
        return [
            { name: 'Salary', value: 0 },
            { name: 'Maintenance', value: 0 },
            { name: 'Miscellaneous', value: 0 }
        ];
    }
};

/**
 * Fetches Revenue Receipts from the database
 * @param {Object} gymData  - An array containing
 *                          • city; city of the gym
 *                          • gymName; name of the gym
 * @returns {Array}         - An array containing all the memership receits
 */
export const fetchRevenueReceipts = async (basicGymData) => {
    try {
        // Reference to the user's payment receipts collection in the database
        // const gymRef = collection(db, cityList, basicGymData.city, gymList, basicGymData.gymName, receiptList);
        const gymRef = collection(db, cityList, basicGymData.city, gymList, basicGymData.gymName, financeList, getCurrentYear(), revenueList);
    
        // Execute the query and get the snapshot of documents
        const gymSnapshot = await getDocs(gymRef);
    
        // Initialize an array to hold all receipt documents
        let receiptDocs = [];
    
        // If payment receipts exist, add them to the array
        if (!gymSnapshot.empty) {
          gymSnapshot.forEach((doc) => {
            const docData = doc.data();
            if (docData.userName)
                receiptDocs.push(doc.data());
          });
        }
    
        // Return the array of receipt documents
        return receiptDocs;
      } catch (error) {
        console.error("Error getting payment history:", error);
        return [];
      }
};

/**
 * Fetches Expense Receipts from the database
 * @param {Object} basicGymData  - An object containing:
 *                                • city; city of the gym
 *                                • gymName; name of the gym
 * @returns {Array}              - An array containing all the expense receipts
 */
export const fetchExpenseReceipts = async (basicGymData) => {
    try {
        // Reference to the gym's expense receipts collection in the database
        const gymRef = collection(db, cityList, basicGymData.city, gymList, basicGymData.gymName, financeList, getCurrentYear(), expenseList);
        
        // Execute the query and get the snapshot of documents
        const gymSnapshot = await getDocs(gymRef);
        
        // Initialize an array to hold all expense documents
        let expenseDocs = [];
        
        // If expense receipts exist, add them to the array
        if (!gymSnapshot.empty) {
            gymSnapshot.forEach((doc) => {
            expenseDocs.push(doc.data());
            });
        }
        
        // Return the array of expense documents
        return expenseDocs;
    } catch (error) {
        console.error("Error getting expense history:", error);
        return [];
    }
};
  
/**
 * Mock function to simulate fetching Membership Distribution data from a database.
 * @param {Object} gymData - Contains Gym's details
 * @returns {Promise<Array>} - A promise that resolves to an array of membership distribution data objects.
 */
export const getMembershipDistribution = async (gymData) => {
    if (true)
        return []
    else 
        return [
            { name: 'Day Pass', value: 36 },
            { name: '1 Month', value: 12 },
            { name: '3 Months', value: 22 },
            { name: '6 Months', value: 48 },
            { name: '12 Months', value: 120 },
        ];
};

/**
 * gets Conversion Data from marketplace
 * @param {Object} gymData - Contains Gym's details
 * @returns {Array}
 */
export const getConvertedData = (gymData) => {
    return [];
    return [
        { name: 'Emails', leads: 40, converted: 35, color: '#cfd4c3' },
        { name: 'Marketplace', leads: 35, converted: 30, color: '#f0f8e2' },
        { name: 'LinkedIn', leads: 30, converted: 20, color: '#8ea77e' },
        { name: 'Instagram', leads: 20, converted: 15, color: '#7b8f6e' },
        { name: 'Facebook', leads: 40, converted: 30, color: '#4b573a' },
        { name: 'Others', leads: 35, converted: 25, color: '#313c2d' },
    ];
  }