/**
 * Phone Number Constraint
 * @param {String} phoneNumber 
 * @param {String} userCountry  -   Country Code (Default is India)
 * @returns {Bool}
 */
export const phoneNumberChecker = (phoneNumber, userCountry = 'IN') => {
    if (!phoneNumber) {
        // console.log("Phone number is undefined or empty");
        return false;
    }

    const countryLength = {
        'IN': 13,
        'GB': 13,
        'US': 12,
    };

    if (phoneNumber.length !== countryLength[userCountry]) {
        return false;
    }

    return true;
};

/**
 * User Name Constraint
 * @param {String} userName
 * @returns {Bool}
 */
export const userNameChecker = (userName) => {
    if (userName.trim() === '') {
        return false;
    }
    return true;
};

/**
 * Gender Constraint
 * @param {String} gender
 * @returns {Bool}
 */
export const genderChecker = (gender) => {
    if (gender === '') {
        return false;
    }
    return true;
};

/**
 * Amount Constraint
 * @param {Number} amount
 * @returns {Bool}
 */
export const amountChecker = (amount) => {
    if (amount < 0) {
        return false;
    }
    return true;
};

/**
 * Age Constraint
 * @param {Number} age
 * @returns {Bool}
 */
export const ageChecker = (age) => {
    if (age >= 0 && age <= 100) {
        return true;
    }
    return false;
};

/**
 * Class Name Constraint
 * @param {String} className 
 * @returns {Bool} - true if valid, false if invalid
 */
export const classNameChecker = (className) => {
    // Check if class name is not empty, doesn't contain only spaces, and has a reasonable length
    return className.trim() !== '' && className.length > 1 && className.length <= 100;
};

/**
 * Checks for Staff's Position
 * Shouldn't be Default
 * @param {String} position 
 * @returns {Bool}
 */
export const positionChecker = (position) => {
    if (position == 'Default' || position.toLowerCase() =='select a role')
        return false;
    return true;
}

/**
 * Date Constraint
 * @param {String} date - in format 'YYYY-MM-DD'
 * @returns {Bool} - true if valid, false if invalid
 */
export const dateChecker = (date) => {
    const today = new Date().setHours(0, 0, 0, 0);
    const selectedDate = new Date(date).setHours(0, 0, 0, 0);
    
    // Check if the date is a valid date and is not in the past
    return !isNaN(selectedDate) && selectedDate >= today;
};

/**
 * Time Constraint
 * @param {String} time - in format 'HH:MM' (24-hour format)
 * @returns {Bool} - true if valid, false if invalid
 */
export const timeChecker = (time) => {
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;

    // Check if time matches the 24-hour format HH:MM
    return timeRegex.test(time);
};


/**
 * Email Constraint
 * @param {String} email 
 * @returns {Bool} - true if valid, false if invalid
 */
export const emailChecker = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the email matches the standard email format
    return emailRegex.test(email);
};

/**
 * Months Constraint
 * @param {Number} months 
 * @returns {Bool} - true if valid, false if invalid
 */
export const monthsChecker = (months) => {
    if (typeof months !== 'number' || months < 0) {
        return false;
    }
    return true;
};

/**
 * City Checker
 * @param {string} city - The name of the city to check.
 * @returns {Promise<boolean>} - True if valid, false if invalid.
 */
export const cityChecker = async (city) => {
    // Check if the input is a string
    if (typeof city !== 'string' || city.trim() === '') {
        return false; // Invalid input
    }

    try {
        // Make a request to the Nominatim API to search for the city
        const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(city)}&format=json&addressdetails=1`);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();

        // Check if any results were returned
        if (data.length > 0) {
            // You can also add additional checks for the response if needed
            return true; // City is valid
        } else {
            return false; // City not found
        }
    } catch (error) {
        // console.error("Error during city validation:", error);
        return false; // Consider city invalid on error
    }
};
